<template>
  <div class="choosing-schedule-box">
    <app-choosing-schedule
      :ancor="{ link: '/request', title: 'Список заявок' }"
    ></app-choosing-schedule>
  </div>
</template>
<script>
import AppChoosingSchedule from '../AppChoosingSchedule.vue'
export default {
  components: { AppChoosingSchedule },
  setup() {},
}
</script>
