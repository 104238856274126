<template>
  <div class="popUp">
    <a @click="onClose" class="close"
      ><img width="25" src="../../assets/img/close.svg"
    /></a>
    <ul v-if="list">
      <li @click="onClose" v-for="item in list" :key="item">
        <router-link v-if="item.rout" :to="`/courses/${item.rout}`">
          {{ item.mini ? item.mini : item.title }}</router-link
        >
        <router-link v-if="item.rout2" :to="item.rout2">
          {{ item.mini ? item.mini : item.title }}</router-link
        >
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  emits: ['CloseForm'],
  props: ['list'],
  setup(_, { emit }) {
    function onClose() {
      emit('CloseForm')
    }
    return { onClose }
  },
}
</script>
