<template>
  <div class="course">
    <p class="course__title">
      Это отличная альтернатива обычному детскому саду, и прекрасная возможность
      развития ребёнка в малочисленной группе каждый день с понедельника по
      пятницу.
    </p>

    <p class="course__title">
      Вы сможете спокойно оставить своего ребёнка на 4 часа и быть уверенными,
      что он будет находиться не просто под присмотром опытных педагогов, а
      всесторонне развиваться.
    </p>

    <ul>
      <li>
        <p class="course__subtitle" data-v-6e47603e="">
          <span data-v-6e47603e=""
            ><img
              src="../../assets/img/check.svg"
              data-v-6e47603e=""
              width="12" /></span
          >Творческие занятия.
        </p>
        <p class="course__text" data-v-6e47603e="">
          Помогают развивать способности ребёнка, его воображение, мышление,
          мелкую моторику (рисование, лепка, создание аппликаций).
        </p>
      </li>
      <li>
        <p class="course__subtitle" data-v-6e47603e="">
          <span data-v-6e47603e=""
            ><img
              src="../../assets/img/check.svg"
              data-v-6e47603e=""
              width="12" /></span
          >Развивающие занятия.
        </p>
        <p class="course__text" data-v-6e47603e="">
          Включают в себя задания: на внимание, память, мышление, по чтению,
          грамоте, математике. Предусмотрены занятия по изучению английского
          языка.
        </p>
      </li>
      <li>
        <p class="course__subtitle" data-v-6e47603e="">
          <span data-v-6e47603e=""
            ><img
              src="../../assets/img/check.svg"
              data-v-6e47603e=""
              width="12" /></span
          >Сюжетные игры.
        </p>
        <p class="course__text" data-v-6e47603e="">
          Игры связанные с повседневными ситуациями, обучают нормам поведения и
          правилам этикета. Такие занятия развивают уверенность в себе.
        </p>
      </li>
      <li>
        <p class="course__subtitle" data-v-6e47603e="">
          <span data-v-6e47603e=""
            ><img
              src="../../assets/img/check.svg"
              data-v-6e47603e=""
              width="12" /></span
          >Музыкальные занятия.
        </p>
        <p class="course__text" data-v-6e47603e="">
          Развивают слух и чувство ритма.
        </p>
      </li>
      <li>
        <p class="course__subtitle" data-v-6e47603e="">
          <span data-v-6e47603e=""
            ><img
              src="../../assets/img/check.svg"
              data-v-6e47603e=""
              width="12" /></span
          >Общение со сверстниками
        </p>
        <p class="course__text" data-v-6e47603e="">
          и детьми другого возраста повышает коммуникативные навыки, которые
          необходимы каждому человеку.
        </p>
      </li>
      <li>
        <p class="course__subtitle" data-v-6e47603e="">
          <span data-v-6e47603e=""
            ><img
              src="../../assets/img/check.svg"
              data-v-6e47603e=""
              width="12" /></span
          >Чтение детских книг
        </p>
        <p class="course__text" data-v-6e47603e="">
          и их обсуждение учат детей мыслить, рассуждать, быть внимательными.
        </p>
      </li>
      <li>
        <p class="course__subtitle" data-v-6e47603e="">
          <span data-v-6e47603e=""
            ><img
              src="../../assets/img/check.svg"
              data-v-6e47603e=""
              width="12" /></span
          >Чтение детских книг
        </p>
        <p class="course__text" data-v-6e47603e="">
          и их обсуждение учат детей мыслить, рассуждать, быть внимательными.
        </p>
      </li>
    </ul>
    <br /><br />
    <p class="course__title" style="font-weight: 600; font-size: 20px">
      Что получит ребёнок в нашем мини-саду?
    </p>
    <ul class="course__list">
      <li>Интересные творческие и развивающие занятия.</li>
      <li>Занятия английским языком с раннего возраста.</li>
      <li>Развитие коммуникативных навыков.</li>
      <li>Уверенность в себе.</li>
      <li>Развитие речи, памяти, внимания и мышления.</li>
    </ul>
    <p class="course__title" style="font-weight: 600; font-size: 20px">
      Что получат родители в нашем мини-саду?
    </p>
    <ul class="course__list">
      <li>Возможность уделить время себе и своим делам.</li>
      <li>Спокойствие и уверенность за развитие ребёнка.</li>
      <li>Радость за первые успехи и новые достижения.</li>
    </ul>
    <p class="course__title" style="font-weight: 600; font-size: 20px">
      Преимущества мини-сада:
    </p>
    <ul class="course__list">
      <li>малочисленная группа (до 10 человек);</li>
      <li>
        дети находятся в мини-саду не весь день, а 4 часа (это важно для детей,
        чувствительных к долгому расставанию с мамой, кому нужен индивидуальный
        подход);
      </li>
      <li>
        ребёнок не просто находится под присмотром, а всесторонне развивается
        благодаря разнообразным занятиям: зарядка, творчество, подготовка к
        школе, чтение книг, английский язык, окружающий мир, музыка;
      </li>
      <li>
        ребёнок получит необходимые социальные навыки, научится общаться не
        только со сверстниками, но и с детьмидругого возраста.
      </li>
      <li>
        Чтобы каждый день был интересным, познавательным, весёлым и спортивным -
        Добро пожаловать в наш мини-сад!!!
      </li>
    </ul>
  </div>
</template>