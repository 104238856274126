<template>
  <div class="modal" :class="items">
    <component
      @closeSchedule="close"
      @deleteRequest="deleteRequest2"
      @closeModal="close"
      :title="year"
      :is="'app-' + items"
    ></component>
  </div>
  <teleport to="body"><div @click="close" class="overflow"></div></teleport>
</template>
<script>
import AppConfirmation from './AppConfirmation.vue'
import AppDeleteRequest from './AppDeleteRequest.vue'
import { computed } from 'vue'
export default {
  emits: ['close', 'deleteRequest2'],
  components: { AppConfirmation, AppDeleteRequest },
  props: ['component', 'year'],
  setup(props, { emit }) {
    const items = computed(() => {
      if (props.component !== undefined) {
        return props.component
      } else {
        return 'confirmation'
      }
    })

    function deleteRequest2() {
      emit('deleteRequest2')
    }

    function close() {
      emit('close')
    }
    return { items, close, deleteRequest2 }
  },
}
</script>
