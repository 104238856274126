<template>
  <div class="politika-box">
    <p align="center" class="h1title">
      <span xml:lang="ru-RU" lang="ru-RU"
        ><strong
          >ПОЛИТИКА ЗАЩИТЫ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ САЙТА</strong
        ></span
      >
    </p>
    <p align="justify">
      Настоящая Политика конфиденциальности персональных данных (далее –
      Политика конфиденциальности) действует в отношении всей информации,
      которую
      <strong
        >Индивидуальный предприниматель "Кочерова Ирина Владимировна"
      </strong>
      может получить о клиенте или партнере во время использования сайта
      <a href="https://rino-tlt.ru/">rino-tlt.ru </a> (далее – Сайт).
    </p>
    <p xml:lang="ru-RU" lang="ru-RU" align="left">&nbsp;</p>
    <ol>
      <li><h2 align="justify">ОСНОВНЫЕ ПОНЯТИЯ</h2></li>
    </ol>
    <p>
      В настоящей Политике конфиденциальности используются следующие термины и
      понятия:
    </p>
    <p xml:lang="ru-RU" lang="ru-RU" align="left">
      <strong>«АДМИНИСТРАЦИЯ </strong>
      <a href="https://rino-tlt.ru/">rino-tlt.ru</a> (далее – Администрация
      Cайта)<strong>»</strong> – уполномоченные сотрудники на управление
      технологической платформы, действующие от имени Сайта, которые организуют
      и (или) осуществляют обработку персональных данных, а также определяют
      цели обработки персональных данных, состав персональных данных, подлежащих
      обработке, действия (операции), совершаемые с персональными данными.
    </p>
    <p xml:lang="ru-RU" lang="ru-RU" align="left"><br /></p>
    <p>
      <strong>«ПЕРСОНАЛЬНЫЕ ДАННЫЕ»</strong>
      – любая информация, относящаяся к определенному или определяемому на
      основании такой информации физическому/юридическому лицу, необходимая
      Обществу в связи с исполнением им договорных обязательств перед
      Пользователем/Клиентом.
    </p>
    <p>
      Кроме того, к персональным данным относятся данные, которые автоматически
      передаются Сервисам в процессе их использования с помощью установленного
      на устройстве Пользователя программного обеспечения, в том числе IP-адрес,
      информация из cookie, информация о браузере пользователя (или иной
      программе, с помощью которой осуществляется доступ к Сервисам), время
      доступа, адрес запрашиваемой страницы.
    </p>
    <p xml:lang="ru-RU" lang="ru-RU" align="left"><br /></p>
    <p>
      <span xml:lang="ru-RU" lang="ru-RU"
        ><strong>«ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ» </strong></span
      ><span xml:lang="ru-RU" lang="ru-RU"
        >– сбор, систематизация, накопление, хранение, уточнение (обновление,
        изменение), использование, распространение (в том числе передача),
        обезличивание, блокирование, уничтожение персональных данных
        Клиентов.</span
      >
    </p>
    <p xml:lang="ru-RU" lang="ru-RU" align="left"><br /></p>
    <p>
      <span xml:lang="ru-RU" lang="ru-RU"
        ><strong>«КОНФИДЕНЦИАЛЬНОСТЬ ПЕРСОНАЛЬНЫХ ДАННЫХ» </strong></span
      ><span xml:lang="ru-RU" lang="ru-RU"
        >– обязательное для соблюдения работниками Общества, получившими доступ
        к персональным данным, требование не допускать распространения
        персональных данных без согласия Клиента или иного законного
        основания.</span
      >
    </p>
    <p xml:lang="ru-RU" lang="ru-RU" align="left"><br /></p>
    <ol start="2">
      <li>
        <h2 align="justify">ОБЩИЕ ПОЛОЖЕНИЯ</h2>
        <ol>
          <li>
            <p>
              <span xml:lang="ru-RU" lang="ru-RU"
                >Использование Пользователем/Клиентом Сайта означает согласие с
                настоящей Политикой конфиденциальности и условиями обработки
                персональных данных Пользователя/Клиента.</span
              >
            </p>
          </li>
          <li>
            <p>
              <span xml:lang="ru-RU" lang="ru-RU"
                >В случае несогласия с условиями Политики конфиденциальности
                Пользователь/Клиент должен прекратить использование Сайта.
              </span>
            </p>
          </li>
          <li>
            <p>
              Администрация Сайта проверяет достоверность персональных данных,
              предоставляемых Пользователем Сайта.
            </p>
          </li>
          <li>
            <p>
              Пользователь/Клиент при пользовании Сайтом подтверждает, что:
            </p>
            <ul>
              <li>
                <p>
                  – обладает всеми необходимыми правами, позволяющими ему
                  осуществлять регистрацию (создание учетной записи) и
                  использовать услуги Сайта;
                </p>
              </li>
              <li>
                <p>
                  – указывает достоверную информацию о себе в объемах,
                  необходимых для пользования услугами Сайта. Обязательные для
                  заполнения поля для дальнейшего предоставления услуг Сайта
                  помечены специальным образом, вся иная информация
                  предоставляется Пользователем/Клиентом по его собственному
                  усмотрению;
                </p>
              </li>
              <li>
                <p>
                  – осознает, что информация на Сайте, размещаемая
                  Пользователем/Клиентом о себе, может становиться доступной для
                  третьих лиц, не оговоренных в настоящей Политике, и может быть
                  скопирована и распространена ими;
                </p>
              </li>
              <li>
                <p>
                  – ознакомлен с настоящей Политикой, выражает свое согласие с
                  ней и принимает на себя указанные в ней права и обязанности.
                  Ознакомление с условиями настоящей Политики и проставление
                  галочки под ссылкой на данную Политику является письменным
                  согласием Пользователя/Клиента на сбор, хранение, обработку и
                  передачу третьим лицам персональных данных, предоставляемых
                  Пользователем/Клиентом.
                </p>
              </li>
            </ul>
          </li>
        </ol>
      </li>
    </ol>
    <p xml:lang="ru-RU" lang="ru-RU" align="left">&nbsp;</p>
    <ol start="3">
      <li>
        <h2 align="justify">ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</h2>
        <ol>
          <li>
            <p>
              Настоящая Политика конфиденциальности устанавливает обязательства
              Администрации Cайта по неразглашению и обеспечению режима защиты
              конфиденциальности персональных данных, которые предоставляет по
              запросу Администрации Cайта при заполнении формы обратной связи на
              Cайте.
            </p>
          </li>
        </ol>
      </li>
    </ol>
    <ol>
      <ol start="2">
        <li>
          <p>
            Персональные данные, разрешённые к обработке в рамках настоящей
            Политики конфиденциальности, предоставляются Пользователем/Клиентом
            путём заполнения регистрационной формы на Сайте. Администрация
            осуществляет сбор статистики об IP -адресах своих пользователей.
            Данная информация используется с целью выявления и решения
            технических проблем, для контроля законности проводимых рекламных
            компаний и финансовых платежей.
          </p>
        </li>
      </ol>
    </ol>
    <p xml:lang="ru-RU" lang="ru-RU" align="left">&nbsp;</p>
    <ol start="4">
      <li>
        <h2>ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</h2>
        <ol>
          <li>
            <p>
              Персональные данные Пользователя/Клиента Администрация Сайта может
              использовать в целях:
            </p>
          </li>
        </ol>
      </li>
    </ol>
    <ul>
      <li>
        <p>
          – создания учетной записи и дальнейшей аутентификации и предоставления
          Пользователю/Клиенту доступа к личному кабинету;
        </p>
      </li>
      <li>
        <p>
          – установления с Пользователем/Клиентом обратной связи, включая
          направление уведомлений, запросов, рассылок, СМС-рассылок, касающихся
          использования Сайта и предложений
          <strong
            >Индивидуального предпринимателя "Кочерова Ирина
            Владимировна"</strong
          >;
        </p>
      </li>
      <li>
        <p>
          – определения места нахождения Пользователя/Клиента для обеспечения
          безопасности, предотвращения мошенничества;
        </p>
      </li>
      <li>
        <p>
          – подтверждения достоверности и полноты персональных данных,
          предоставленных Пользователем/Клиентом;
        </p>
      </li>
      <li>
        <p>
          – предоставления Пользователю/Клиенту эффективной клиентской и
          технической поддержки при возникновении проблем, связанных с
          использованием Сайта;
        </p>
      </li>
      <li>
        <p>
          – предоставления Пользователю/Клиенту с его согласия обновлений
          Сайта/системы, специальных предложений, новостной рассылки и иных
          сведений от имени Сайта.
        </p>
      </li>
    </ul>
    <p xml:lang="ru-RU" lang="ru-RU" align="left">&nbsp;</p>
    <ol start="5">
      <li>
        <h2>СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
        <ol>
          <li>
            <p>
              Обработка персональных данных Пользователя/Клиента осуществляется
              без ограничения срока, любым законным способом, в том числе в
              информационных системах персональных данных с использованием
              средств автоматизации или без использования таких средств.
            </p>
          </li>
          <li>
            <p>
              При утрате или разглашении персональных данных Администрация Сайта
              информирует Пользователя/Клиента об утрате или разглашении
              персональных данных.
            </p>
          </li>
          <li>
            <p>
              Администрация Сайта принимает необходимые организационные и
              технические меры для защиты персональной информации
              Пользователя/Клиента от неправомерного или случайного доступа,
              уничтожения, изменения, блокирования, копирования,
              распространения, а также от иных неправомерных действий третьих
              лиц.
            </p>
          </li>
          <li>
            <p>
              Администрация Сайта совместно с Пользователем/Клиентом принимает
              все необходимые меры по предотвращению убытков или иных
              отрицательных последствий, вызванных утратой или разглашением
              персональных данных Пользователя/Клиента.
            </p>
          </li>
          <li>
            <p>
              В случае несогласия Пользователя/Клиента в получении информации от
              <strong
                >Индивидуального предпринимателя "Кочерова Ирина Владимировна"
              </strong>
              и/или аффилированных лиц Пользователь/Клиент может отписаться от
              рассылки:
            </p>
            <ul>
              <li>
                <p align="left">
                  – кликнув по ссылке "Отписаться внизу письма";
                </p>
              </li>
              <li>
                <p align="left">
                  – в личном кабинете на Сайте путём снятия ранее выбранных
                  опций;
                </p>
              </li>
              <li>
                <p align="left">
                  – путем направления уведомления на электронную почту
                  contact@rino-tlt.ru или при телефонном обращении в
                  контакт-центр 8 (902) 370-91-73.
                </p>
              </li>
            </ul>
          </li>
        </ol>
      </li>
    </ol>
    <p xml:lang="ru-RU" lang="ru-RU" align="left">
      При поступлении уведомлений на электронную почту
      <a href="mailto:contact@rino-tlt.ru">contact@rino-tlt.ru</a> или при
      телефонном обращении в контакт-центр в специальном программном обеспечении
      для учета действий по соответствующему Пользователю/Клиенту создается
      обращение по итогам полученной от Пользователя/Клиента информации.
      Обращение обрабатывается максимум в течение 24 часов. В результате
      информация о Пользователе/Клиенте не включается в сегмент рассылок.
    </p>
    <p xml:lang="ru-RU" lang="ru-RU" align="left"><br /></p>
    <ol start="6">
      <li>
        <h2>ОБЯЗАТЕЛЬСТВА СТОРОН</h2>
        <ol>
          <li><p>Пользователь/Клиент обязан:</p></li>
        </ol>
      </li>
    </ol>
    <ol start="6">
      <ol>
        <ol>
          <li>
            <p>
              – предоставить актуальную информацию о персональных данных,
              необходимую для пользования Сайтом;
            </p>
          </li>
          <li>
            <p>
              – обновить, дополнить предоставленную
              <strong
                >Индивидуальному предпринимателю "Кочерова Ирина Владимировна"
              </strong>
              информацию о персональных данных в случае изменения данной
              информации.
            </p>
          </li>
        </ol>
      </ol>
    </ol>
    <ol start="6">
      <ol start="2">
        <li>
          <p>Администрация Сайта обязана:</p>
          <ol>
            <li>
              <p>
                – использовать полученную информацию исключительно для целей,
                указанных в п. 4 настоящей Политики конфиденциальности;
              </p>
            </li>
            <li>
              <p>
                – обеспечить хранение конфиденциальной информации в тайне, не
                разглашать без предварительного письменного разрешения
                Пользователя/Клиента, а также не осуществлять продажу, обмен,
                опубликование, либо разглашение иными возможными способами
                переданных персональных данных Пользователя/Клиента, за
                исключением требований в соответствии с законодательством
                Российской Федерации;
              </p>
            </li>
            <li>
              <p>
                – принимать меры предосторожности для защиты конфиденциальности
                персональных данных Пользователя/Клиента согласно порядку,
                обычно используемому для защиты такого рода информации в
                существующем деловом обороте;
              </p>
            </li>
            <li>
              <p>
                – Пользователь/Клиент может в любой момент изменить (обновить,
                дополнить) предоставленную им персональную информацию или её
                часть, а также параметры её конфиденциальности, воспользовавшись
                функцией редактирования персональных данных в разделе, либо в
                персональном разделе соответствующего Сервиса.
                Пользователь/Клиент обязан заботится о своевременности внесения
                изменений в ранее предоставленную информацию, её актуализации, в
                противном случае
                <strong
                  >Индивидуальный предприниматель "Кочерова Ирина Владимировна"
                </strong>
                не несет ответственности за неполучение уведомлений,
                товаров/услуг и т.п.
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </ol>
    <p xml:lang="ru-RU" lang="ru-RU" align="left">&nbsp;</p>
    <ol start="7">
      <li>
        <h2>ОТВЕТСТВЕННОСТЬ СТОРОН</h2>
        <ol>
          <li>
            <p>
              Администрация Сайта, не исполнившая свои обязательства, несёт
              ответственность в соответствии с законодательством Российской
              Федерации, за исключением случаев, предусмотренных пп. 5.2, 5.3 и
              настоящей Политики Конфиденциальности.
            </p>
          </li>
        </ol>
      </li>
    </ol>
    <ol start="7">
      <ol start="2">
        <li>
          <p>
            В случае утраты или разглашения Конфиденциальной информации
            Администрация Cайта не несёт ответственность, если данная
            конфиденциальная информация:
          </p>
          <ol>
            <li>
              <p>
                – cтала публичным достоянием до её утраты или разглашения;
              </p>
            </li>
            <li>
              <p>
                – была получена от третьей стороны до момента её получения
                Администрацией Сайта;
              </p>
            </li>
            <li>
              <p>
                – была разглашена с согласия Пользователя/Клиента или самим
                Пользователем/Клиентом.
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </ol>
    <p xml:lang="ru-RU" lang="ru-RU" align="left"><br /></p>
    <ol start="8">
      <li>
        <h2>РАЗРЕШЕНИЕ СПОРОВ</h2>
        <ol>
          <li>
            <p>
              До обращения в суд с иском по спорам, возникающим из отношений
              между Пользователем/Клиентом Сайта и Администрацией Сайта,
              обязательным является предъявление претензии (письменного
              предложения о добровольном урегулировании спора).
            </p>
          </li>
          <li>
            <p>
              Получатель претензии в течение 30 календарных дней со дня
              получения претензии письменно уведомляет заявителя претензии о
              результатах рассмотрения претензии.
            </p>
          </li>
          <li>
            <p>
              К настоящей Политике конфиденциальности и отношениям между
              Пользователем/Клиентом и Администрацией Сайта применяется
              действующее законодательство Российской Федерации.
            </p>
          </li>
        </ol>
      </li>
    </ol>
    <p xml:lang="ru-RU" lang="ru-RU" align="left">&nbsp;</p>
    <ol start="9">
      <li>
        <h2>ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h2>
        <ol>
          <li>
            <p>
              Администрация Сайта вправе вносить изменения в настоящую Политику
              конфиденциальности без согласия Пользователя/Клиента.
            </p>
          </li>
          <li>
            <p>
              Новая Политика конфиденциальности вступает в силу с момента ее
              размещения на Сайте, если иное не предусмотрено новой редакцией
              Политики конфиденциальности.
            </p>
          </li>
          <li>
            <p>
              Все предложения или вопросы по настоящей Политике
              конфиденциальности следует сообщать Администрации Сайта по
              телефону <strong>8 (902) 370-91-73</strong> или на электронный
              адрес: contact@rino-tlt.ru
            </p>
          </li>
          <li>
            <p>
              Действующая Политика конфиденциальности размещена на странице по
              адресу <a href="https://rino-tlt.ru/">rino-tlt.ru.</a>
            </p>
          </li>
        </ol>
      </li>
    </ol>
    <p xml:lang="ru-RU" lang="ru-RU" align="left">
      <br /><span xml:lang="ru-RU" lang="ru-RU"
        ><strong
          >Индивидуальный предприниматель "Кочерова Ирина Владимировна":</strong
        ></span
      >
    </p>
    <p align="left">
      <span xml:lang="ru-RU" lang="ru-RU"
        >Юридический адрес: 445056 Самарская обл., г.Тольятти, ул. 40 лет
        Победы, 61Б, кв. 4
      </span>
    </p>
    <p align="left">
      <span xml:lang="ru-RU" lang="ru-RU">ИНН: 632101821500 </span>
    </p>
    <p align="left">
      <span xml:lang="ru-RU" lang="ru-RU">ОГРНИП: 308632024900074</span>
    </p>
    <p align="left">
      <span xml:lang="ru-RU" lang="ru-RU">Р/С: 40802810500002502847</span>
    </p>
    <p align="left">
      <span xml:lang="ru-RU" lang="ru-RU">Банк АО «Тинькофф Банк» </span>
    </p>
  </div>
</template>
