<template>
  <div class="select-custom" :class="styleSelect ? styleSelect : null">
    <div
      @click.stop="open"
      class="select-custom-title"
      :class="toogle ? 'active' : null"
    >
      {{ title }}
      <span
        ><svg
          width="15"
          height="15"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 30L25 18L37 30"
            stroke="#6e6b7b"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path></svg
      ></span>
    </div>
    <vue-custom-scrollbar
      v-if="toogle"
      class="select-custom-list"
      :settings="settings"
      @click.stop
    >
      <p @click.stop="close(item.title)" v-for="item in data" :key="item">
        {{ item.title }}
      </p>
    </vue-custom-scrollbar>
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import vueCustomScrollbar from '../../../node_modules/vue-custom-scrollbar/src/vue-scrollbar'
export default {
  components: { vueCustomScrollbar },
  emits: ['update:modelValue'],
  props: ['data', 'modelValue', 'titleSelect', 'styleSelect'],
  setup(props, { emit }) {
    const settings = {
      suppressScrollY: false,
      suppressScrollX: true,
      wheelPropagation: false,
      maxScrollbarLength: 150,
      minScrollbarLength: 50,
    }
    const item = ref()
    const toogle = ref(false)
    const title = ref(props.titleSelect)

    watch(title, val => {
      emit('update:modelValue', val)
    })

    document.onclick = function() {
      toogle.value = false
    }

    function open() {
      toogle.value = !toogle.value
    }

    function close(items) {
      toogle.value = false
      title.value = items
    }

    return { item, toogle, open, close, title, settings }
  },
}
</script>
<style>
.select-custom-title {
  white-space: nowrap;
  width: 100%;
  box-sizing: border-box;
  padding: 0.438rem 1rem 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  font-size: 14px;
  color: #6e6b7b;
  outline: none;
  line-height: 20px;
  cursor: pointer;
  position: relative;
}

.select-custom {
  position: relative;
}
.select-custom.top .select-custom-list {
  bottom: 40px;
}

.select-custom.bottom .select-custom-list {
  top: 40px;
}

.select-custom .select-custom-list {
  left: 0px;
  position: absolute;
  padding: 7px 13px;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  width: calc(100% - 28px);
  background: #fff;
  min-height: 20px;
  max-height: 300px;
  z-index: 100;
}

.select-custom .select-custom-list p {
  font-size: 14px;
  line-height: 18px;
  color: #6e6b7b;
  padding-top: 7px;
  padding-bottom: 7px;
  cursor: pointer;
}

.select-custom .select-custom-title span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 20px;
  background: #fff;
  position: absolute;
  top: 7px;
  border-radius: 0.357rem;
  margin-top: -7px;
  right: 0;
}

.select-custom .select-custom-title span svg {
  transform: rotate(180deg);
}

.select-custom .select-custom-title.active span svg {
  transform: rotate(360deg);
}
</style>
