<template>
  <div class="admin__box">
    <h1>Сформированное расписание</h1>
    <div class="admin__box-table request">
      <app-request-schedule-filter
        v-model="filters"
        @routerscheduler="schelderrequest()"
      ></app-request-schedule-filter>
      <ul class="admin__box-th grid th-9 r-s-filter">
        <li class="admin__box-td-9 td9-1">Учебный год</li>
        <li class="admin__box-td-9 td9-2">Каб.</li>
        <li class="admin__box-td-9 td9-3">Время</li>
        <li class="admin__box-td-9 td9-4">Название</li>
        <li class="admin__box-td-9 td9-5">ФИО ребенка</li>
        <li class="admin__box-td-9 td9-6">ФИО представителя</li>
        <li class="admin__box-td-9 td9-7">Контактный телефон</li>
        <li class="admin__box-td-9 td9-8">Педагог</li>
        <li class="admin__box-td-9 td9-9">Адрес</li>
      </ul>
      <template v-if="data1.length !== 0">
        <p class="admin__box-table-title style12">Понедельник</p>
        <vue-custom-scrollbar class="scroll-area" :settings="settings">
          <app-request-schedule-td
            :rid="item.rid"
            :sid="item.sid"
            v-for="item in data1"
            :key="item"
          ></app-request-schedule-td>
        </vue-custom-scrollbar>
      </template>

      <template v-if="data2.length !== 0">
        <p class="admin__box-table-title style12">Вторник</p>
        <vue-custom-scrollbar class="scroll-area" :settings="settings">
          <app-request-schedule-td
            :rid="item.rid"
            :sid="item.sid"
            v-for="item in data2"
            :key="item"
          ></app-request-schedule-td>
        </vue-custom-scrollbar>
      </template>

      <template v-if="data3.length !== 0">
        <p class="admin__box-table-title style12">Среда</p>
        <vue-custom-scrollbar class="scroll-area" :settings="settings">
          <app-request-schedule-td
            :rid="item.rid"
            :sid="item.sid"
            v-for="item in data3"
            :key="item"
          ></app-request-schedule-td>
        </vue-custom-scrollbar>
      </template>

      <template v-if="data4.length !== 0">
        <p class="admin__box-table-title style12">Четверг</p>
        <vue-custom-scrollbar class="scroll-area" :settings="settings">
          <app-request-schedule-td
            :rid="item.rid"
            :sid="item.sid"
            v-for="item in data4"
            :key="item"
          ></app-request-schedule-td>
        </vue-custom-scrollbar>
      </template>

      <template v-if="data5.length !== 0">
        <p class="admin__box-table-title style12">Пятница</p>
        <vue-custom-scrollbar class="scroll-area" :settings="settings">
          <app-request-schedule-td
            :rid="item.rid"
            :sid="item.sid"
            v-for="item in data5"
            :key="item"
          ></app-request-schedule-td>
        </vue-custom-scrollbar>
      </template>

      <template v-if="data6.length !== 0">
        <p class="admin__box-table-title style12">Суббота</p>
        <vue-custom-scrollbar class="scroll-area" :settings="settings">
          <app-request-schedule-td
            :rid="item.rid"
            :sid="item.sid"
            v-for="item in data6"
            :key="item"
          ></app-request-schedule-td>
        </vue-custom-scrollbar>
      </template>

      <template v-if="data7.length !== 0">
        <p class="admin__box-table-title style12">Воскресенье</p>
        <vue-custom-scrollbar class="scroll-area" :settings="settings">
          <app-request-schedule-td
            :rid="item.rid"
            :sid="item.sid"
            v-for="item in data7"
            :key="item"
          ></app-request-schedule-td>
        </vue-custom-scrollbar>
      </template>
    </div>
  </div>
</template>
<script>
import { typeCourses } from '../../utils/typeCourses'
import vueCustomScrollbar from '../../../node_modules/vue-custom-scrollbar/src/vue-scrollbar'
import AppRequestScheduleFilter from './requestSchedule/AppRequestScheduleFilter.vue'
import AppRequestScheduleTd from './requestSchedule/AppRequestScheduleTd.vue'
import { onMounted, computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  components: {
    AppRequestScheduleFilter,
    AppRequestScheduleTd,
    vueCustomScrollbar,
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const settings = {
      suppressScrollY: false,
      suppressScrollX: true,
      wheelPropagation: false,
      maxScrollbarLength: 150,
      minScrollbarLength: 50,
    }

    const filters = ref([])
    const yearNow = new Date().getFullYear()
    onMounted(() => {
      store.dispatch('getRequestSchedule', yearNow)
      store.dispatch('Request', yearNow)
      store.dispatch('schedule2', yearNow)
    })

    const year = computed(() => {
      if (filters.value.year) {
        return filters.value.year
      } else {
        return new Date().getFullYear()
      }
    })

    watch(year, (val) => {
      store.dispatch('getRequestSchedule', val)
      store.dispatch('Request', val)
      store.dispatch('schedule2', val)
    })

    const re = computed(() => {
      if (store.state.requestSchedule) {
        return Object.keys(store.state.requestSchedule).map((r) => {
          if (r) {
            return Object.keys(store.state.requestSchedule[r]).map(
              (s) => store.state.requestSchedule[r][s]
            )
          }
        })[0]
      } else {
        return []
      }
    })

    const data = computed(() => {
      if (year.value.toString() !== new Date().getFullYear().toString()) {
        if (store.state.requestSchedule == null) {
          return []
        } else {
          return Object.keys(store.state.requestSchedule).map(
            (r) => store.state.requestSchedule[r]
          )
        }
      } else {
        if (store.state.requestSchedule) {
          return [].concat.apply(
            [],
            Object.keys(store.state.requestSchedule).map((r) => {
              if (r) {
                return Object.keys(store.state.requestSchedule[r]).map(
                  (s) => store.state.requestSchedule[r][s]
                )
              }
            })
          )
        } else {
          return []
        }
      }
    })

    const dataS = computed(() =>
      data.value.reduce((a, b) => ((a[b.rid] = b), a), {})
    )

    const schedule = computed(() => {
      if (store.state.scheduleData2 == null) {
        return []
      } else {
        return Object.keys(store.state.scheduleData2)
          .map((r) => store.state.scheduleData2[r])[0]
          .reduce((a, b) => ((a[b.id] = b), a), {})
      }
    })
    const request = computed(() => {
      if (store.state.request == null) {
        return {}
      } else {
        return Object.keys(store.state.request)
          .map((r) => store.state.request[r])
          .reduce((a, b) => ((a[b.id] = b), a), {})
      }
    })

    const formedData = computed(() =>
      data.value
        .map((r) => {
          return {
            rid: request.value[r.rid],
            sid: schedule.value[r.sid],
          }
        })
        .filter((request) => request.rid !== undefined)
        .filter((request) => {
          if (filters.value.name) {
            return request.rid.name.includes(filters.value.name)
          }
          return request
        })
        .filter((request) => {
          if (filters.value.phone) {
            return request.rid.phone.includes(filters.value.phone)
          }
          return request
        })
        .filter((request) => {
          if (filters.value.agent) {
            return request.rid.agent.includes(filters.value.agent)
          }
          return request
        })
        .filter((request) => {
          if (filters.value.agent) {
            return request.rid.agent.includes(filters.value.agent)
          }
          return request
        })
        .filter((request) => {
          if (filters.value.type && filters.value.type !== '-1') {
            if (request.sid) {
              return typeCourses(request.sid.type) == filters.value.type
            }
          }
          return request
        })
        .filter((request) => {
          if (filters.value.cap && filters.value.cap !== '-1') {
            if (request.sid) {
              return request.sid.room == filters.value.cap
            }
          }
          return request
        })
        .filter((request) => {
          if (filters.value.adress) {
            if (request.sid) {
              if (request.sid.address) {
                return request.sid.address.includes(filters.value.adress)
              } else {
                return request.sid.title.includes(filters.value.adress)
              }
            }
          }
          return request
        })
        .filter((request) => {
          if (filters.value.teacher) {
            if (request.sid) {
              if (request.sid.teacher) {
                return request.sid.teacher.includes(filters.value.teacher)
              } else {
                return request.sid.title.includes(filters.value.teacher)
              }
            }
          }
          return request
        })
    )

    function schelderrequest() {
      store.commit('printAdd', formedData.value)
      router.push('/printrequestshedule')
    }

    const data1 = computed(() =>
      formedData.value
        .filter((r) => (r.sid ? r.sid.dow == 1 : r))
        .filter((r) => r.sid !== undefined)
    )
    const data2 = computed(() =>
      formedData.value
        .filter((r) => (r.sid ? r.sid.dow == 2 : r))
        .filter((r) => r.sid !== undefined)
    )

    const data3 = computed(() =>
      formedData.value
        .filter((r) => (r.sid ? r.sid.dow == 3 : r))
        .filter((r) => r.sid !== undefined)
    )
    const data4 = computed(() =>
      formedData.value
        .filter((r) => (r.sid ? r.sid.dow == 4 : r))
        .filter((r) => r.sid !== undefined)
    )

    const data5 = computed(() =>
      formedData.value
        .filter((r) => (r.sid ? r.sid.dow == 5 : r))
        .filter((r) => r.sid !== undefined)
    )

    const data6 = computed(() =>
      formedData.value
        .filter((r) => (r.sid ? r.sid.dow == 6 : r))
        .filter((r) => r.sid !== undefined)
    )

    const data7 = computed(() =>
      formedData.value
        .filter((r) => (r.sid ? r.sid.dow == 7 : r))
        .filter((r) => r.sid !== undefined)
    )

    return {
      schelderrequest,
      data1,
      data2,
      data3,
      data4,
      data5,
      data6,
      data7,
      settings,
      data,
      request,
      schedule,
      filters,
      yearNow,
      year,
      dataS,
      formedData,
      typeCourses,
      re,
    }
  },
}
</script>
