<template>
  <div class="admin__box">
    <div class="admin__box-table" v-if="dataSchedule.length !== 0">
      <p class="admin__box-table-title">
        {{ dowItem }}
      </p>
      <ul class="admin__box-th th-6 grid сhoosing">
        <li class="admin__box-td-6 td-1">Выбор расписания</li>
        <li class="admin__box-td-6 td-2">Время</li>
        <li class="admin__box-td-6 td-3">Кабинет</li>
        <li class="admin__box-td-6 td-3">Название курса</li>
        <li class="admin__box-td-6 td-4">Продолжительность занятий</li>
        <li class="admin__box-td-6 td-4">Адрес</li>
        <li class="admin__box-td-6 td-5">Педагог</li>
      </ul>

      <app-choosing-list
        :room="room"
        :data="item"
        v-for="item in dataSchedule"
        :key="item"
        :subid="subid"
        :id="id"
        :requestSchedule="requestSchedule"
      ></app-choosing-list>
    </div>
  </div>
</template>
<script>
import AppChoosingList from './AppChoosingList.vue'
import { computed } from 'vue'
export default {
  components: { AppChoosingList },
  props: ['title', 'data', 'id', 'subid', 'requestSchedule', 'room'],
  setup(props) {
    const dowItem = computed(() => {
      if (props.title == 1) {
        return 'Понедельник'
      }
      if (props.title == 2) {
        return 'Вторник'
      }
      if (props.title == 3) {
        return 'Среда'
      }
      if (props.title == 4) {
        return 'Четверг'
      }
      if (props.title == 5) {
        return 'Пятница'
      }
      if (props.title == 6) {
        return 'Суббота'
      }
      if (props.title == 7) {
        return 'Воскресенье'
      } else {
        return '0'
      }
    })

    const dataSchedule = computed(() => {
      if (props.data) {
        if (props.data.length !== 0) {
          return props.data.filter(r => r.dow == props.title)
        } else {
          return []
        }
      } else {
        return []
      }
    })

    return { dowItem, dataSchedule }
  },
}
</script>
