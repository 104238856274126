<template>
  <div class="admin__box">
    <h1>Список заявок</h1>
    <div class="admin__box-table request">
      <app-request-filter v-model="filters"></app-request-filter>
      <ul class="admin__box-th grid th-9">
        <li class="admin__box-td-9 td9-1">№</li>
        <li class="admin__box-td-9 td9-2">Учебный год</li>
        <li class="admin__box-td-9 td9-3">ФИО ребенка</li>
        <li class="admin__box-td-9 td9-4">Дата рождения</li>
        <li class="admin__box-td-9 td9-5">ФИО представителя</li>
        <li class="admin__box-td-9 td9-6">Контактный телефон</li>
        <li class="admin__box-td-9 td9-7">Дата заявки</li>
        <li class="admin__box-td-9 td9-8">Тип</li>
        <li class="admin__box-td-9 td9-9">Выбор расписания</li>
      </ul>
      <vue-custom-scrollbar class="scroll-area" :settings="settings">
        <app-request-td
          v-for="(item, i) in request"
          :key="item.data.id"
          :item="item.data"
          :id="item.id"
          :i="i"
          @EditTd="editRequest($event)"
          @deleterequestitem="deleteRequestItem($event)"
        >
        </app-request-td>
      </vue-custom-scrollbar>
    </div>
    <div class="btn-request">
      <button @click="openModal" type="button" class="btn btn-1">
        Добавить заявку
      </button>
    </div>
  </div>
  <app-request-modal
    @closeModal="closeModal"
    :toogs="toog2"
  ></app-request-modal>
  <!--Modal-->
  <div class="modal request" v-if="toog">
    <div class="modal__admin-box">
      <div class="modal__admin-3 modal__admin-container">
        <app-info-block
          :text="item"
          v-for="item in infotext"
          :key="item"
        ></app-info-block>
        <p class="modal__admin-title">ФИО ребенка:</p>
        <p>
          <input
            type="text"
            class="modal__admin-select"
            v-model="fioChilderen"
            placeholder="ФИО"
          />
        </p>
      </div>
      <div class="modal__admin-3 modal__admin-container">
        <p class="modal__admin-title">ФИО представителя:</p>
        <p>
          <input
            type="text"
            class="modal__admin-select"
            v-model="fioAgent"
            placeholder="ФИО представителя"
          />
        </p>
      </div>
      <div class="modal__admin-1 modal__admin-container">
        <div>
          <p class="modal__admin-title">Дата рождения:</p>
          <p>
            <input
              v-mask="['##.##.####']"
              type="text"
              class="admin-inp"
              v-model="dateChilderen"
              placeholder="01.01.2013"
            />
          </p>
        </div>
        <div>
          <p class="modal__admin-title">Контактный телефон:</p>
          <p>
            <input
              v-mask="['###########']"
              type="text"
              class="admin-inp"
              v-model="phoneAgent"
              placeholder="89170000000"
            />
          </p>
        </div>
      </div>
      <div class="modal__admin-3 modal__admin-container">
        <p class="modal__admin-title">Тип заявки:</p>
        <p>
          <span v-for="item in type2" :key="item"
            ><input type="checkbox" v-model="selectedUsers" :value="item" />
            {{ item }}</span
          >
        </p>
      </div>

      <div class="modal__admin-btn">
        <button @click="uptadeRequest" type="button" class="btn btn-1">
          Сохранить
        </button>
      </div>
    </div>
  </div>
  <div v-if="toog" @click="close" class="overflow"></div>
  <app-modal
    @close="closeModalRequest"
    @deleteRequest2="deleterequest2"
    component="delete-request"
    v-if="toggleRequest"
  ></app-modal>
</template>
<script>
import AppModal from '../../ui/AppModal.vue'
import AppRequestModal from './AppRequestModal.vue'
import AppInfoBlock from '../../ui/AppInfoBlock.vue'
import { mask } from 'vue-the-mask'
import vueCustomScrollbar from '../../../../node_modules/vue-custom-scrollbar/src/vue-scrollbar'
import { ifDate } from '../../../utils/ifdate'
import { dataFormat } from '../../../utils/dateformat'
import { yearKids } from '../../../utils/yarekids'
import { requestType } from '../../../utils/requestType'
import { parseDate } from '../../../utils/parsedata'
import AppRequestFilter from '../Request-table/AppRequestFilter.vue'
import AppRequestTd from '../Request-table/AppRequestTd.vue'
import { onMounted, computed, ref, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import { inject } from 'vue'
export default {
  components: {
    AppModal,
    AppRequestFilter,
    vueCustomScrollbar,
    AppRequestTd,
    AppInfoBlock,
    AppRequestModal,
  },
  directives: { mask },
  setup() {
    const toggleRequest = ref(false)
    const RequestItem = ref({})
    const store = useStore()
    const req = ref()
    const fioChilderen = ref()
    const fioAgent = ref()
    const dateChilderen = ref()
    const phoneAgent = ref()
    const selectedUsers = ref([])
    const status = ref(1)
    const dataReq = ref({})
    const toog = ref(false)
    const year = new Date().getFullYear()
    const filters = ref({})

    const toog2 = ref(false)

    const settings = {
      suppressScrollY: false,
      suppressScrollX: true,
      wheelPropagation: false,
      maxScrollbarLength: 150,
      minScrollbarLength: 50,
    }
    const office = computed(() =>
      [].concat.apply(
        [],
        Object.keys(store.state.office).map(
          (r) => store.state.office[r].subtitle
        )
      )
    )

    const office2 = computed(() =>
      Object.keys(store.state.office)
        .map((r) => store.state.office[r].list)
        .filter((r) => r !== null && r !== undefined)
    )

    const dataCourse = reactive(office)

    onMounted(() => {
      store.dispatch('Request', year)
      store.dispatch('getStatus', year)
      //store.dispatch('deleteRequest2', year)
    })

    const request = computed(() =>
      store.getters['requests']
        .filter((request) => {
          if (filters.value.agent) {
            return request.data.agent.includes(filters.value.agent)
          }
          return request
        })
        .filter((request) => {
          if (filters.value.name) {
            return request.data.name.includes(filters.value.name)
          }
          return request
        })
        .filter((request) => {
          if (filters.value.phone) {
            return request.data.phone.includes(filters.value.phone)
          }
          return request
        })
        .filter((request) => {
          if (filters.value.state && filters.value.state !== '-1') {
            return request.data.status == filters.value.state
          }
          return request
        })
        .filter((request) => {
          if (filters.value.type && filters.value.type !== '-1') {
            return requestType(request.data.type).includes(filters.value.type)
          }
          return request
        })
        .filter((request) => {
          if (filters.value.yareFilter) {
            return dataFormat(request.data.birthdate).includes(
              String(filters.value.yareFilter)
            )
          }
          return request
        })
        .filter((request) => {
          if (filters.value.applicationmin && filters.value.applicationmax) {
            return ifDate(
              request.data.appldate,
              filters.value.applicationmin,
              filters.value.applicationmax
            )
          }
          return request
        })
        .sort(function (a, b) {
          return a.data.appldate - b.data.appldate
        })
    )
    watch(request, (val) => {
      let req = val.filter((item) => {
        console.log('sdfsdf', item.data.name)
        return (
          !item.data.agent &&
          !item.data.appldate &&
          !item.data.birthdate &&
          !item.data.id &&
          !item.data.name &&
          !item.data.phone &&
          !item.data.phoneraw &&
          !item.data.year
        )
      })
      if (req.length !== 0) {
        for (let item of req) {
          store.dispatch('deleteRequest2', { year, id: item.id })
        }
      }

      if (val.length == 0) {
        return
      } else {
        setTimeout(() => {
          let r = document.getElementById('r' + (val.length - 1)).offsetTop
          document.querySelector('.ps-container').scrollTop = r
        }, 0)
      }
    })

    const type2 = computed(() =>
      Array.from(
        new Set(
          [].concat.apply(
            [],
            Object.keys(store.state.dataCourses).map((r) =>
              store.state.dataCourses[r].data.map((i) => i.title)
            )
          )
        )
      )
    )

    const infotext = ref([])

    function close() {
      toog.value = false
      dataReq.value = {}
      fioAgent.value = null
      fioChilderen.value = null
      dateChilderen.value = null
      selectedUsers.value = []
      phoneAgent.value = null
    }
    const keyPhone = ref()
    const cryoptojs = inject('cryptojs')

    watch(phoneAgent, (val, i) => {
      if (val) {
        dataReq.value.data.phone = val
        dataReq.value.data.phoneraw = val
        dataReq.value.key = cryoptojs.AES.encrypt(
          val,
          'Secret Passphrase'
        ).toString()
      }
      if (i) {
        dataReq.value.phone = true
      }
    })

    function deleteRequestItem(item) {
      toggleRequest.value = true
      RequestItem.value = item
    }

    function uptadeRequest() {
      if (selectedUsers.value.length > 0) {
        store.dispatch('requestAdd', dataReq.value)
        close()
      } else {
        infotext.value = [
          {
            title: '',
            style: 'warning-box',
            text: 'Добавте минимум один курс',
            text2: '',
          },
        ]
        setTimeout(() => {
          infotext.value = []
        }, 2000)
      }
    }

    function editRequest(event) {
      toog.value = true
      fioChilderen.value = event.data.name
      fioAgent.value = event.data.agent
      dateChilderen.value = dataFormat(event.data.birthdate)
      phoneAgent.value = event.data.phone
      selectedUsers.value = requestType(event.data.type)
      keyPhone.value = cryoptojs.AES.encrypt(
        event.data.phone,
        'Secret Passphrase'
      ).toString()

      dataReq.value = {
        id: event.id,
        choice: event.data.choice ? event.data.choice : false,
        key: keyPhone.value,
        agent: false,
        appldate: false,
        name: false,
        phone: false,
        type: false,
        data: {
          agent: fioAgent.value,
          appldate: event.data.appldate,
          birthdate: dateChilderen.value,
          id: event.data.id,
          name: fioChilderen.value,
          phone: phoneAgent.value,
          phoneraw: phoneAgent.value,
          status: event.data.status,
          type: selectedUsers.value,
          year: event.data.year,
        },
      }
    }

    watch(fioAgent, (val, i) => {
      if (val) {
        dataReq.value.data.agent = val
      }
      if (i) {
        dataReq.value.agent = true
      }
    })

    watch(dateChilderen, (val, i) => {
      if (val) {
        dataReq.value.data.birthdate = parseDate(val)
      }
      if (i) {
        dataReq.value.appldate = true
      }
    })

    watch(fioChilderen, (val, i) => {
      if (val) {
        dataReq.value.data.name = val
      }
      if (i) {
        dataReq.value.name = true
      }
    })

    watch(selectedUsers, (val, i) => {
      if (val.length !== 0) {
        dataReq.value.data.type = val
      }
      if (i.length !== 0) {
        dataReq.value.type = true
      }
    })

    function openModal() {
      toog2.value = true
    }

    function closeModal() {
      toog2.value = false
    }

    function closeModalRequest() {
      toggleRequest.value = false
    }

    function deleterequest2() {
      store.dispatch('deleteRequest', RequestItem.value)
      toggleRequest.value = false
    }

    return {
      openModal,
      closeModal,
      keyPhone,
      cryoptojs,
      infotext,
      dataReq,
      editRequest,
      dateChilderen,
      phoneAgent,
      selectedUsers,
      type2,
      fioAgent,
      fioChilderen,
      uptadeRequest,
      close,
      request,
      year,
      req,
      filters,
      office,
      dataFormat,
      yearKids,
      ifDate,
      dataCourse,
      office2,
      requestType,
      settings,
      toog,
      parseDate,
      status,
      toog2,
      toggleRequest,
      deleteRequestItem,
      RequestItem,
      closeModalRequest,
      deleterequest2,
    }
  },
}
</script>
