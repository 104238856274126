import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import Vuex from './store/index'
import '../src/style/style.css'
import '../src/style/admin.css'
import 'swiper/swiper.css';
import VueCryptojs from 'vue-cryptojs'
import VueEasyLightbox from 'vue-easy-lightbox'

createApp(App)
  .use(router)
  .use(Vuex)
  .use(VueCryptojs)
  .use(VueEasyLightbox)
  .mount('#app')
