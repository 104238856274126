export function dayFormat(item) {
   if(item == 1){
     return 'Понедельник'  
   } else if (item == 2){
    return 'Вторник'  
  } else if (item == 3){
    return 'Среда'  
  } else if (item == 4){
    return 'Четверг'  
  } else if (item == 5){
    return 'Пятница'  
  } else if (item == 6){
    return 'Суббота'  
  } else {
      return 'Воскресенье'
  } 

}

