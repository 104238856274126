<template>
  <div class="course">
    <p class="course__title course__title--slogan">
      Отдых и эмоции для детей!
    </p>
    <p class="course__title course__title--slogan">
      Спокойствие и уверенность для родителей!
    </p>
    <p class="course__title">
      Для детей – уникальные программы, от которых каждый <span style="font-weight: 600">ребенок будет в восторге</span>, а гаджеты уйдут на второй план, новые знакомства и много веселых мероприятий.
    </p>
    <p class="course__title">
      Для родителей - это возможность выдохнуть, быть уверенными в том, что ребенок будет под чутким присмотром самых лучших педагогов <span style="font-weight: 600">с 8-30 до 18-00</span>, в компании новых друзей и занят полезными увлечениями: экскурсии и мастер-классы, прогулки и походы на свежем воздухе.
    </p>
    <p class="course__title">
      <span style="font-weight: 600">Каждый день море активностей и эмоций:</span>
    </p>
    <ul class="course__list">
      <li>Мастер-классы</li>
      <li>Поделки</li>
      <li>Игры на свежем воздухе</li>
      <li>Квесты</li>
      <li>Викторины</li>
      <li>Настольные игры</li>
      <li>Научные эксперименты</li>
      <li>Походы</li>
    </ul>

    <div class="swiper course__swiper" ref="swiper">
      <div class="swiper-wrapper course__wrapper">
        <div v-for="item in 25" :key="`course-swiper-${item}`" class="swiper-slide course__slide">
           <img @click="setImgItem(item)" :src="require(`../../assets/img/city-сamp/photo${item}.jpg`)">
        </div>
      </div>
      <button type="button" class="carousel__prev swiper-button-prev" ><svg class="carousel__icon" viewBox="0 0 24 24" role="img"><title>arrowLeft</title><path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path></svg></button>
      <button type="button" class="carousel__next swiper-button-next"><svg class="carousel__icon" viewBox="0 0 24 24" role="img"><title>arrowRight</title><path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path></svg></button>
    </div>

    <div class="video-box">
      <video style="max-width: 300px; width: 100%" controls>
        <source src="../../assets/img/foto/video/video_2024-04-23_15-55-59.mp4" type="video/mp4" />
      </video>
    </div>

    <div class="course__popup-img" v-if="imgItem">
      <div class="course__popup-close" @click="imgItem=null"></div>
      <img :src="require(`../../assets/img/city-сamp/photo${imgItem}.jpg`)">
    </div>

    <p class="course__title">
      <span style="font-weight: 600">1 раз в неделю в каждой программе предусмотрены:</span>
    </p>
    <ul class="course__list">
      <li>ЭКСКУРСИЯ (с трансфером или пешком) или бассейн,  или выездной мастер-класс.</li>
    </ul>
    <p style="text-align: center" class="course__title">
      <span style="font-weight: 600">Скучать не придется точно!</span>
    </p>
    <div align="center" v-if="isLink">
      <a class="btn__price course__btn" target="_blank" href="/letniyclub.pdf">
      <span>
        <img width="22" src="../../assets/img/iconjendela.png">
      </span>
        Скачать презентацию ЛЕТНИЕ КАНИКУЛЫ 2024
      </a>
    </div>
    <slot></slot>
  </div>
</template>
<script>
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

export default {
  data(){
    return{
      swiper: null,
      imgItem: null,
      isLink: false,
    }
  },
  methods:{
    setImgItem(item){
      this.imgItem = item
    }
  },
  mounted() {
    let now = new Date();
    console.log( now.getMonth(), now.getFullYear() )
    console.log(now.getMonth(), now.getFullYear())
    this.isLink = now.getFullYear() === 2024 && now.getMonth() < 8;
    this.swiper = new Swiper(this.$refs.swiper, {
      slidesPerView: 4,
      spaceBetween: 16,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        0: {
          slidesPerView: 2,
          spaceBetween: 16
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 16
        }
      },
      modules: [Navigation],
    })
  }
}
</script>
<style scoped>
.video-box{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #e4e4e4;
  margin-bottom: 20px;
}
@media screen and (max-width: 880px) {
  .PageTitle.sub {
    padding-top: 70px;
  }
}
</style>