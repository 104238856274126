<template>
  <div class="politika-box">
    <p align="center" class="h1title">
      Согласие на обработку персональных данных
    </p>
    <p style="text-align: center;">
      <strong>посетителя сайта </strong><strong>rino-tlt.ru</strong
      ><strong> на обработку персональных данных</strong>
    </p>
    <p xml:lang="ru-RU" lang="ru-RU">
      Настоящим свободно, своей волей и в своем интересе даю согласие
      <strong
        >Индивидуальному предпринимателю "Кочерова Ирина Владимировна"</strong
      >, которое находится по адресу: rino-tlt.ru (далее – Сайт), на
      автоматизированную и неавтоматизированную обработку моих персональных
      данных, в том числе с использованием интернет-сервисов на сайте в
      соответствии со следующим перечнем:
    </p>
    <ul>
      <li>
        <p>
          – персональные данные – номер телефона, адрес моей электронной почты,
          фамилия, имя, отчество;
        </p>
      </li>
      <li>
        <p>
          – источник захода на Сайт;
        </p>
      </li>
      <li>
        <p>
          – данные о пользовательском устройстве (среди которых разрешение,
          версия и другие атрибуты, характеризующие пользовательское
          устройство);
        </p>
      </li>
      <li>
        <p>
          – пользовательские клики, просмотры страниц, заполнения полей, показы
          и просмотры баннеров и видео;
        </p>
      </li>
      <li>
        <p>– данные, характеризующие аудиторные сегменты;</p>
      </li>
      <li>
        <p>– параметры сессии;</p>
      </li>
      <li>
        <p>– данные о времени посещения.</p>
      </li>
    </ul>
    <p align="justify">
      для целей повышения осведомленности посетителей Сайта
      <strong
        >Индивидуального предпринимателя "Кочерова Ирина Владимировна"</strong
      >, предоставления релевантной рекламной информации и оптимизации рекламы,
      исполнения договорных обязательств, проведение рекламных кампаний и
      маркетинговых исследований, продвижение товаров и услуг, рекламируемых на
      Cайте.
    </p>
    <p align="justify">
      Также даю свое согласие на предоставление
      <strong
        >Индивидуальным предпринимателем "Кочерова Ирина Владимировна"</strong
      >
      моих персональных данных как посетителя Сайта компаниям, с которыми
      сотрудничает
      <strong
        >Индивидуальный предприниматель "Кочерова Ирина Владимировна".
      </strong>
      <strong
        >Индивидуальный предприниматель "Кочерова Ирина Владимировна"
      </strong>
      вправе осуществлять обработку моих персональных данных следующими
      способами: сбор, запись, систематизация, накопление, хранение, обновление,
      изменение, использование, передача (распространение, предоставление,
      доступ).
    </p>
    <p align="justify">
      Настоящее согласие вступает в силу с момента моего перехода на Сайт и
      действует в течение сроков, установленных действующим законодательством
      РФ.
    </p>
    <p align="justify">
      Основанием для обработки персональных данных являются: ст. 24 Конституции
      Российской Федерации; ст. 6 Федерального закона №152-ФЗ «О персональных
      данных».
    </p>
    <p align="justify">
      Персональные данные обрабатываются до окончания обработки. Также обработка
      персональных данных может быть прекращена по запросу субъекта персональных
      данных.
    </p>
    <p align="justify">
      Хранение персональных данных, зафиксированных на бумажных носителях,
      осуществляется согласно Федеральному закону №125-ФЗ «Об архивном деле в
      Российской Федерации» и иным нормативно-правовым актам в области архивного
      дела и архивного хранения. Срок или условие прекращения обработки
      персональных данных: прекращение деятельности
      <strong
        >Индивидуального предпринимателя "Кочерова Ирина Владимировна"</strong
      >как юридического лица (ликвидация или реорганизация).
    </p>
  </div>
</template>
