<template>
  <app-header-img
    title="ЦЕНТР ДЕТСКОГО РАЗВИТИЯ «РИНО»"
    subtitle="Подготовка к школе"
    subText="Английский язык"
    subText2="Мини-сад"
    subText3="Каникулы для детей"
    :btn="true"
  ></app-header-img>
  <section class="AboutRino-1">
    <h3 id="about" class="title _title">О центре</h3>
    <div class="block__1">
      <div class="video__box">
        <iframe width="100%" height="280" src="https://www.youtube.com/embed/v_-k3u0cTOQ?si=HLQlm0SCMm_SzSOz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

      </div>
      <div class="AboutRino__block">
        <div>
          <p class="AboutRino__title">
            Центр детского развития
            <span style="font-weight: 600"
              >РИНО – РАЗВИТИЕ ИНТЕЛЛЕКТА И НАВЫКОВ ОБЩЕНИЯ</span
            >
          </p>
          <div class="quote__box">
            <p class="quote__box-title">
              "Ребёнок-это не сосуд, который нужно наполнить, а факел, который
              нужно зажечь."
            </p>
            <p class="quote__box-subtitle">Монтель</p>
          </div>
          <p class="AboutRino__text bold">
            Центр детского развития «РИНО» успешно работает в Тольятти более 20
            лет.
          </p>
          <p class="AboutRino__text bold cursive">
            Миссия нашего центра – прививать детям интерес к учёбе<br />
            с раннего возраста и давать успешный старт школьным годам!
          </p>
          <p class="AboutRino__text">
            Занятия для детей в нашем Центре – это отличная возможность
            сделать<br />
            дальнейшее обучение в школе более комфортным, а адаптацию<br />
            в новом коллективе – более мягкой для ребёнка.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="AboutRino">
    <h3 class="title _title" id="about">РИНО&nbsp;—&nbsp;это…</h3>

    <ul class="AboutRino__table triger">
      <li>
        <p class="AboutRino__table-img">
          <img src="../../assets/img/img3.png" />
        </p>
        <p class="AboutRino__table-title">25 лет работы</p>
        <p class="AboutRino__table-text">
          Первый кабинет открылся в сентябре 1999 года
        </p>
      </li>
      <li>
        <p class="AboutRino__table-img">
          <img src="../../assets/img/img2.png" />
        </p>
        <p class="AboutRino__table-title">3 филиала и удобное расписание</p>
        <p class="AboutRino__table-text">
          Каждый день 7 дней в неделю в уютных и оборудованных классах
          занимаются юные ученики
        </p>
      </li>
      <li>
        <p class="AboutRino__table-img">
          <img src="../../assets/img/img5.png" />
        </p>
        <p class="AboutRino__table-title">Образовательная лицензия и возможность оплаты материнским капиталом</p>
        <p class="AboutRino__table-text">
          Благодаря лицензии родители могут получать налоговый вычет и оплачивать обучение из средств материнского капитала, тем самым рационально распределять бюджет на развитие  детей.
        </p>
      </li>
      <li>
        <p class="AboutRino__table-img">
          <img src="../../assets/img/img1.png" />
        </p>
        <p class="AboutRino__table-title">Авторские программы обучения</p>
        <p class="AboutRino__table-text">
          Несколько видов собственно разработанных <br />рабочих тетрадей
        </p>
      </li>
      <li>
        <p class="AboutRino__table-img">
          <img src="../../assets/img/img6.png" />
        </p>
        <p class="AboutRino__table-title">Гарантия результатов обучения</p>
        <p class="AboutRino__table-text">
          Результаты подтверждаются проведением промежуточных диагностик, тестирований , индивидуальных и групповых бесед с родителями каждые 3 месяца
        </p>
      </li>
      <li>
        <p class="AboutRino__table-img">
          <img src="../../assets/img/img4.png" />
        </p>
        <p class="AboutRino__table-title">Сервисная программа и программа лояльности</p>
        <p class="AboutRino__table-text">
          Мы заботится не только о развитии и обучении детей, но еще уделяем внимание мамам, чтобы они чувствовали себя спокойно и уверенно, получали поддержку и отдых.
        </p>
      </li>
    </ul>
  </section>
  <div class="carusel__block">
    <carousel :items-to-show="count" :itemsToScroll="1">
      <slide v-for="slide in aboutinfo" :key="slide">
        <img width="230" :src="require(`../../assets/img/index/${slide}`)" />
      </slide>

      <template #addons>
        <navigation />
      </template>
    </carousel>
  </div>
  <section class="card" id="courses">
    <h3 class="title _title">Наши направления:</h3>

    <ul id="technology" class="card__item">
      <template v-for="item in data" :key="item">
        <li :class="item.route === 'city-camp' ? 'card__item--mod' : ''">
          <router-link class="card__item-img" :to="`/courses/${item.route}`">
            <img :src="require(`../../assets/img/${item.icon}`)" /><span
              >Подробнее</span
            >
          </router-link>
          <p class="card__item-title">
            {{ textEvent(item.mini) }}
            <template v-if="item.route === 'city-camp'">
              <span class="card__item-text">Уникальные программы от которых каждый ребенок будет в восторге, а гаджеты уйдут на второй план, новые знакомства и много веселых мероприятий.  </span>
              <router-link class="card__item-link" :to="`/courses/${item.route}`">Подробнее</router-link>
            </template>
          </p>

        </li>
      </template>
    </ul>
  </section>

  <section class="uchitelja-box">
    <h3 class="title_title">Наша команда</h3>
    <carousel :items-to-show="count" :itemsToScroll="1">
      <slide v-for="slide in teacher" :key="slide">
        <p class="teacher__img">
          <img :src="require(`../../assets/img/uchitelja/${slide.img}.jpg`)" />
        </p>
        <p class="teacher__title">{{ slide.title }}</p>
        <p class="teacher__subtitle">{{ slide.subtitle }}</p>
      </slide>

      <template #addons>
        <navigation />
      </template>
    </carousel>
  </section>

  <section class="reviews">
    <h3 class="title_title">
      <span>Отзывы</span>
    </h3>
    <div class="reviews__block">
      <div v-for="item in reviews" :key="item" class="reviews__item">
        <div class="reviews__img">
          <span class="reviews__img-avatar"
            ><img :src="require(`../../assets/img/rew/${item.img}`)"
          /></span>
          <span class="reviews__img-text">{{ item.img_title }}</span>
        </div>
        <div class="reviews__text" :class="togs ? 'active' : null">
          {{ item.text }}
        </div>
        <template v-if="togs">
          <a @click.prevent="togs = false" v-if="item.img == '001.jpg'" href="#"
            >еще</a
          >
        </template>
      </div>
    </div>
    <div class="reviews__btn">
      <a
        target="_blank"
        href="https://yandex.ru/maps/org/rino/1138851267/reviews/"
        class="title_title-qr"
      >
        <span><img src="../../assets/img/rew/qr-code.gif" /></span>
        <div class="reviews__add">
          <span>Написать отзыв</span><span>Все отзывы</span>
        </div>
      </a>
    </div>
  </section>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import AppHeaderImg from '../ui/AppHeaderImg.vue'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
export default {
  setup() {
    const store = useStore()
    onMounted(() => {
      store.dispatch('getRoute')
    })
    const togs = ref(true)

    const windowsWidth = ref(window.innerWidth)

    function textEvent(event) {
      if (event === '"Школа внимания" для младших школьников') {
        return 'Занятия для учеников 1-4 класс'
      } else {
        return event
      }
    }

    window.addEventListener('resize', () => {
      windowsWidth.value = window.innerWidth
    })

    const count = computed(() => {
      if (windowsWidth.value > 930) {
        return 4
      }
      if (windowsWidth.value < 930 && windowsWidth.value > 820) {
        return 3
      }
      if (windowsWidth.value < 820 && windowsWidth.value > 560) {
        return 2
      } else {
        return 1
      }
    })

    const aboutinfo = [
      '001.jpg',
      '002.jpg',
      '003.jpg',
      '004.jpg',
      '005.jpg',
      '006.jpg',
      '007.jpg',
      '008.jpg',
      '009.jpg',
    ]

    const reviews = [
      {
        img: '001.jpg',
        img_title: 'Милана Гусар',
        text: 'Замечательный центр! Очень рады, что ребёнок ходил на развивающие занятия. Классные авторские пособия, по которым было очень интересно работать даже взрослым, когда делали задания дома. Отличный коллектив профессионалов! За год многому научились. Жаль, что узнали об этом центре незадолго  до поступления в школу..... Подготовка просто потрясающая!',
      },
      {
        img: '002.jpg',
        img_title: 'Гузел Максутова',
        text: 'Весной 2021г. ходили с сыном на подготовительные занятия к школе. Грамотные и умеющие найти подход к ребёнку преподаватели. Доступные учебные пособия, разработанные преподавателями центра "Рино". Советую.',
      },
      {
        img: '003.jpg',
        img_title: 'Анна Зайнуллина',
        text: 'Лучшая подготовка деток к школе! Рекомендую! Все педагоги профессионалы,хотела особенно выделить Елену Николаевну Самарскую! Спасибо Вам!',
      },
      {
        img: '004.jpg',
        img_title: 'Олег П',
        text: 'водили дочку сначала к логопеду потом в подготовительную группу к школе. персонал грамотный цены доступные. все понравилось.',
      },
    ]

    const teacher = [
      {
        title: 'Ирина Владимировна Кочерова', //16
        subtitle: 'Основатель и руководитель Центра "РИНО"',
        img: 'uchitel2',
      },
      {
        title: 'Елена Викторовна Илюхина', //9
        subtitle: 'Исполнительный директор Центра "РИНО"',
        img: 'uchitel3',
      },
      {
        title: 'Елена Сергеевна Земскова', //15
        subtitle: 'Управляющий Центром "РИНО"',
        img: 'uchitel4',
      },
      {
        title: 'Елена Владимировна Гиливанова', //15
        subtitle: 'Cтарший администратор "РИНО"',
        img: 'uchitel23',
      },
      {
        title: 'Елена Викторовна Алпеева',
        subtitle: 'Учитель английского языка',
        img: 'uchitel50',
      },
      {
        title: 'Елена Васильевна Ардаева', //18
        subtitle: 'Учитель по подготовке к школе',
        img: 'uchitel51',
      },
      {
        title: 'Баранова Алина Валентиновна',
        subtitle: 'Логопед', //5
        img: 'uchitel52',
      },
      {
        title: 'Гусак Алена Сергеевна',
        subtitle: 'Педагог по музыке', //5
        img: 'uchitel53',
      },
      {
        title: 'Иванова Мария Дмитриевна',
        subtitle: 'Учитель по подготовке к школе',
        img: 'uchitel54',
      },
      {
        title: 'Погосян Вероника Андреевна',
        subtitle: 'Учитель по подготовке к школе',
        img: 'uchitel55',
      },
      {
        title: 'Шоматова Юлия Александровна, ',
        subtitle: 'Педагог мини-сада',
        img: 'uchitel56',
      },
      {
        title: 'Татьяна Николаевна Миронова', //17
        subtitle: 'Учитель по подготовке к школе',
        img: 'uchitel12',
      },

      {
        title: 'Олеся Анотольевна Безбородова', //12
        subtitle: 'Учитель по подготовке к школе',
        img: 'uchitel11',
      },
      //{
      //  title: 'Анна Николаевна Николаева', //10
      //  subtitle: 'Учитель по подготовке к школе',
      //  img: 'uchitel1',
      //},
      //{
      //  title: 'Вероника Андреевна Павленко', //11
      //  subtitle: 'Учитель по подготовке к школе',
      //  img: 'uchitel13',
      //},
      {
        title: 'Юлия Ивановна Пирожкова', //13
        subtitle: 'Учитель по подготовке к школе',
        img: 'uchitel8',
      },
      //{
      //  title: 'Анастасия Александровна Таштамирова', //3
      //  subtitle: 'Учитель по подготовке к школе',
      //  img: 'uchitel14',
      //},
      //{
        //title: 'Елена Николаевна Самарская', //6
        //subtitle: 'Учитель по подготовке к школе',
        //img: 'uchitel9',
      //},
      {
        title: 'Маргарита Васильевна Маник', //7
        subtitle: 'Учитель по подготовке к школе',
        img: 'uchitel10',
      },
      //{
      //  title: 'Ольга Игоревна Шелепова', //1
      //  subtitle: 'Учитель английского языка, методист',
      //  img: 'uchitel19',
      //},
      //{
        //title: 'Полина Игоревна Сивякова', //19
        //subtitle: 'Учитель английского языка',
        //img: 'uchitel15',
      //},
      //{
      //  title: 'Марина Александровна Балобошина', //14
      //  subtitle: 'Учитель английского языка',
      //  img: 'uchitel17',
      //},

      {
        title: 'Татьяна Гавриловна Романюк', //2
        subtitle: 'Учитель английского языка',
        img: 'uchitel16',
      },
      //{
      //  title: 'Юлия Мнировна Маркина', //8
      //  subtitle: 'Учитель английского языка',
      //  img: 'uchitel18',
      //},
      //{
      //  title: 'Елена Владимировна Яркина', //4
      //  subtitle: 'Ведущий логопед-дефектолог',
      //  img: 'uchitel7',
      //},
      {
        title: 'Ольга Алексеевна Пасько',
        subtitle: 'Логопед',
        img: 'uchitel21',
      },
      {
        title: 'Галина Дмитриевна Шевченко',
        subtitle: 'Логопед',
        img: 'uchitel22',
      },
    ]

    const data = computed(() =>
      Object.keys(store.state.routes).map((r) => store.state.routes[r])
    )

    return {
      data,
      teacher,
      windowsWidth,
      count,
      reviews,
      togs,
      aboutinfo,
      textEvent,
    }
  },
  components: { AppHeaderImg, Carousel, Slide, Navigation },
}
</script>
