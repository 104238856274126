<template>
  <div class="course">
    <p class="course__title">
      Специально разработанные интенсивы для младших школьников помогут преодолеть первые трудности в начальной школе, поверить ребятам в свои способности, стать успешными и повысить успеваемость по основным школьным предметам: чтение, русский язык и математика.
    </p>
    <ul>
      <li>
        <p class="course__subtitle" data-v-6e47603e="">
          <span data-v-6e47603e=""
          ><img
              src="../../assets/img/check.svg"
              data-v-6e47603e=""
              width="12" /></span
          >Интенсив по чтению.
        </p>
        <p class="course__text" data-v-6e47603e="">
          Часто в первом классе дети сталкиваются с проблемами в чтении. Дети читают медленно или не понимают, что читают. Неправильно ставят ударение в словах, читают по слогам, отсутствует плавное чтение.
          Уникальность нашего курса по чтению в том, что за достаточно короткий срок (от 15 до 25 занятий) дети смогут читать тексты со скоростью 40 слов в минуту.
          Обучаясь по обычной программе, этого уровня ребенок достигает через 150 дней.
          <span class="course__text-bold mt">Наш курс поможет детям достичь результатов в кратчайшие сроки и добиться больших успехов в учебе!</span>
        </p>

      </li>
      <li>
        <p class="course__subtitle" data-v-6e47603e="">
          <span data-v-6e47603e=""
          ><img
              src="../../assets/img/check.svg"
              data-v-6e47603e=""
              width="12" /></span
          >Каллиграфия. Быстрое исправление почерка.
        </p>
        <p class="course__text" data-v-6e47603e="">
          <span>Этот курс подходит для коррекции почерка у детей 8-12 лет.</span>

          <span>Дети занимаются по авторской программе в специальных прописях, а в дальнейшем закрепляют навык в рабочих тетрадях в линию.</span>

          <span class="course__text-bold">Результаты видны уже на 2-м занятии:</span>
          <span class="course__text-li">понимание принципа правильного и красивого письма.</span>
          <span class="course__text-li">ребенок научиться соблюдать одинаковый наклон, высоту и ширину букв.</span>
          <span class="course__text-li">сможет сравнить результаты «до» и «после».</span>

        </p>
      </li>
      <li>
        <p class="course__subtitle" data-v-6e47603e="">
          <span data-v-6e47603e=""
          ><img
              src="../../assets/img/check.svg"
              data-v-6e47603e=""
              width="12" /></span
          >Курс «Пишу грамотно»
        </p>
        <p class="course__text" data-v-6e47603e="">
          <span>Особенностью курса является усиленная проработка орфограмм, тренировка навыков, доведение их до автоматизма за счет понимания и многократного повторения.</span>
          <span class="course__text-bold">Результаты курса:</span>
          <span class="course__text-li">сформируются важные орфографические навыки</span>
          <span class="course__text-li">пополнится словарный запас</span>
          <span class="course__text-li">сформируется навык орфографической зоркости</span>
          <span class="course__text-li">будут пройдены и систематизированы все базовые темы 1-2 класса.</span>

        </p>
      </li>
      <li>
        <p class="course__subtitle" data-v-6e47603e="">
          <span data-v-6e47603e=""
          ><img
              src="../../assets/img/check.svg"
              data-v-6e47603e=""
              width="12" /></span
          >МАТЕМАТИКА НА ОТЛИЧНО
        </p>
        <p class="course__text" data-v-6e47603e="">
          <span class="course__text-bold">Результаты это курса:</span>
          <span class="course__text-li">учащиеся освоят все вычислительные приемы, которые возможны между круглыми, некруглыми двузначными числами</span>
          <span class="course__text-li">изучат матрицу сложения и все базовые алгоритмы сложения и вычитания</span>
          <span class="course__text-li">научатся решать уравнения</span>
          <span class="course__text-li">смогут писать математические диктанты</span>
          <span class="course__text-li">смогут считать 30-40 примеров в минуту</span>
        </p>
      </li>
      <li>
        <p class="course__subtitle" data-v-6e47603e="">
          <span data-v-6e47603e=""
          ><img
              src="../../assets/img/check.svg"
              data-v-6e47603e=""
              width="12" /></span
          >РЕШЕНИЕ ТЕКСТОВЫХ ЗАДАЧ
        </p>
        <p class="course__text" data-v-6e47603e="">
          <span class="course__text-bold">После завершения курса ученик сможет:</span>
          <span class="course__text-li">Самостоятельно делать краткую запись</span>
          <span class="course__text-li">Решать задачу в общем виде без опоры на цифры</span>
          <span class="course__text-li">Знать все типы задач и алгоритмы их решения</span>
          <span class="course__text-li">Составлять задачи по схемам и картинкам</span>
          <span class="course__text-li">Решать задачи олимпиадного уровня</span>
        </p>
      </li>
      <li>
        <p class="course__subtitle" data-v-6e47603e="">
          <span data-v-6e47603e=""
          ><img
              src="../../assets/img/check.svg"
              data-v-6e47603e=""
              width="12" /></span
          >Таблица умножения за 15 уроков
        </p>
        <p class="course__text" data-v-6e47603e="">
          Этот курс поможет ученикам 1-3 класса в короткие сроки выучить таблицу умножения. Он отлично сочетается со школьной программой.
          На экзамене в конце курса ребенок сможет рассказать всю таблицу умножения за 3 минуты!
          В курсе предложены занимательные тренировки в быстром счете на карточках. Родители, уже изучившие с детьми курс, отмечают, что с изучением таблицы умножения их вечера наполнились хорошим настроением, совместным общением и веселыми играми на скоростной счет для всей семьи.
          <span class="course__text-bold">Результаты:</span>
          <span class="course__text-li">ребенок выучит все случаи умножения и деления</span>
          <span class="course__text-li">сможет рассказать таблицу умножения за 3 минуты</span>
          <span class="course__text-li">научится решать все типы уравнений со скоростью от 4 до 7 штук в минуту</span>
        </p>
      </li>
    </ul>
    <br /><br />
    <p class="course__text-bold-f">Наши программы для школьников направлены на то, чтобы каждый ребенок мог учиться легко и с удовольствием!!!</p>
    <slot></slot>
  </div>
</template>
<style>
.course__text-bold{
  font-weight: bold;
  font-size: 16px;
  padding-bottom:15px;
  padding-top: 15px;
  display: block;
}

.course__text-li:before {
  content: '';
  display: block;
  width: 10px;
  height: 2px;
  background: #eb891a;
  margin-right: 10px;
}

.course__text-li{
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.course__text-bold-f{
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
}
</style>