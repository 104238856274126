<template>
  <div class="admin__box">
    <h1 v-if="routeParams === 'add'">Добавление учебного года</h1>
    <h1 v-else>Учебный год {{ yar }} - {{ `${Number(yar) + 1}` }}</h1>
    <div v-if="routeParams === 'add'" class="admin__form-box">
      <div>
        <p>
          <input
            type="text"
            class="form-control w1"
            placeholder="год"
            v-model="year"
          />
          - <span id="end-year">{{ `${Number(year) + 1}` }}</span>
        </p>
      </div>
      <div>
        <button type="button" class="btn btn-1" @click="onSubmit">
          Добавить
        </button>
      </div>
    </div>
    <div v-else>
      <app-table
        @saveItem="onSubmit"
        :yeare="yeare"
        :yar="yar"
        :i="i"
        v-for="(item, i) in data"
        :key="item"
        :title="item.title"
      ></app-table>
    </div>
  </div>
</template>
<script>
import AppTable from './AppTbody/AppTable.vue'
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
export default {
  components: { AppTable },
  setup() {
    const store = useStore()
    const route = useRoute()

    const routeParams = computed(() => {
      if (route.params.id !== undefined) {
        return route.params.id
      } else {
        return 'add'
      }
    })

    const tt = computed(() => store.getters.YearScheduleItem)

    const year = ref(new Date().getFullYear() + 1)

    const yar = computed(() => store.state.year)
    const r = computed(() => Object.keys(store.state.scheduleData))

    const sheld = computed(() => {
      if (r.value.length > 0 && yar.value !== 'add') {
        return Object.keys(store.state.scheduleData[store.state.year])
      } else {
        return []
      }
    })

    watch(sheld, (val) => {
      store.commit('idToken', val[0])
    })

    const yeare = computed(() => {
      if (sheld.value.length > 0 && yar.value !== 'add') {
        return store.state.scheduleData[store.state.year][sheld.value[0]]
      } else {
        return []
      }
    })

    watch(year, (val) => {
      if (val < new Date().getFullYear()) {
        year.value = new Date().getFullYear()
      }
    })

    const data = [
      { title: 'Понедельник', id: 1 },
      { title: 'Вторник', id: 2 },
      { title: 'Среда', id: 3 },
      { title: 'Четверг', id: 4 },
      { title: 'Пятница', id: 5 },
      { title: 'Суббота', id: 6 },
      { title: 'Воскресенье', id: 7 },
    ]

    function onSubmit() {
      if (routeParams.value === 'add') {
        store.dispatch('addYearSchedule', year.value)
      } else {
        store.commit('inputStatus5', true)
        store.dispatch('uptadeSchedule')
      }
    }
    return { onSubmit, data, routeParams, year, yeare, yar, tt }
  },
}
</script>
