<template>
  <div class="course__acardion">
    <div
      @click="toogle"
      :class="toog ? 'active' : null"
      class="course__acardion-title"
    >
      <span class="course__acardion-title-icon"></span
      ><span class="course__acardion-title-content"
        >Финансово-хозяйственная деятельность</span
      >
    </div>
    <div v-if="toog" class="course__acardion-text">
      <span class="course__acardion-text-small">
        Центр “РИНО” не ведёт образовательной деятельности, финансовое
        обеспечение которой осуществляется за счёт бюджетных ассигнований
        федерального бюджета, бюджетов субъектов Российской Федерации, местных
        бюджетов. Финансирование деятельности осуществляется по договорам об
        образовании за счёт средств физических лиц.</span
      >
      <span class="course__acardion-text-small"
        >ИП Кочерова И.В. применяет упрощённую систему налогообложения.</span
      >
      <span class="course__acardion-text-small"
        >Поступление финансовых средств отражается в бухгалтерском отчёте.</span
      >
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
export default {
  setup() {
    const toog = ref(false)

    function toogle() {
      toog.value = !toog.value
    }

    return { toog, toogle }
  },
}
</script>
