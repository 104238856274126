<template>
  <div class="course__acardion">
    <div
      @click="toogle"
      :class="toog ? 'active' : null"
      class="course__acardion-title"
    >
      <span class="course__acardion-title-icon"></span
      ><span class="course__acardion-title-content"
        >Руководство и педагогический состав</span
      >
    </div>
    <div v-if="toog" class="course__acardion-text">
      <span class="course__acardion-text-bold"
        >Директор Кочерова Ирина Владимировна</span
      >
      <span class="course__acardion-text-small"
        >Куйбышевский педагогический институт им. В.В.Куйбышева<br />
        Квалификация: учитель русского языка и литературы<br />
        Телефоны: +7 902 370 9173; +7 960 842 4210<br />
        Е-mail: contact@rino-tlt.ru</span
      >

      <span class="course__acardion-text-bold"
        >Исполнительный директор Илюхина Елена Викторовна</span
      >
      <span class="course__acardion-text-small"
        >Тольяттинский государственный университет<br />
        Квалификация: учитель математики и информатики<br />
        Телефоны: +7 902 370 9173; +7 960 842 4210<br />
        Е-mail: contact@rino-tlt.ru</span
      >

      <span class="course__acardion-text-bold"
        >Управляющий Земскова Елена Сергеевна</span
      >
      <span class="course__acardion-text-small"
        >Тольяттинский государственный институт сервиса<br />
        Квалификация: маркетолог<br />
        Телефоны: +7 902 370 9173; +7 960 842 4210<br />
        Е-mail: contact@rino-tlt.ru
      </span>

      <span class="course__acardion-text-small">Педагогический состав</span>

      <span class="course__acardion-text-small">ПОДГОТОВКА К ШКОЛЕ</span>
      <!--<span class="course__acardion-text-bold">Дорн Евгения Михайловна </span>
      <span class="course__acardion-text-small"
        >Восточный институт экономики, гуманитарных наук, управления и права
        <br />
        Квалификация: педагог-психолог</span>
      

      <span class="course__acardion-text-bold">Андреева Оксана Ильинична</span>
      <span class="course__acardion-text-small"
        >Тольяттинский государственный университет<br />
        Квалификация: учитель физики и информатики</span
      >-->
      <span class="course__acardion-text-bold">Самарская Елена Николаевна</span>
      <span class="course__acardion-text-small"
        >Московский заочный гуманитарный колледж<br />
        Квалификация: учитель</span
      >
      <span class="course__acardion-text-bold">Маник Маргарита Васильевна</span>
      <span class="course__acardion-text-small"
        >Тольяттинский государственный университет<br />
        Квалификация: педагог-психолог
      </span>
      <span class="course__acardion-text-bold"
        >Безбородова Олеся Анатольевна</span
      >
      <span class="course__acardion-text-small"
        >“Поволжский православный институт им. Святителя Алексия, митрополита
        Московского” <br />
        Квалификация: учитель начальных классов</span
      >
      <span class="course__acardion-text-small"
        >Миронова Татьяна Николаевна</span
      >
      <span class="course__acardion-text-small"
        >Абаканский государственный педагогический институт<br />
        Квалификация: учитель начальных классов</span
      >
      <span class="course__acardion-text-bold">Пирожкова Юлия Ивановна</span>
      <span class="course__acardion-text-small"
        >Тольяттинское педагогическое училище<br />
        Квалификация: учитель начальных классов, русского языка и
        литературы</span
      >
      <!--<span class="course__acardion-text-bold"
        >Таштамирова Анастасия Александровна</span
      >
      <span class="course__acardion-text-small"
        >Международный центр консалтинга и образования “Велес”<br />
        Квалификация: подготовка детей к обучению в школе</span
      >-->
      <span class="course__acardion-text-bold"
        >Стукалова Елена Владимировна</span
      >
      <span class="course__acardion-text-small">
        Тольяттинский государственный университет<br />
        Квалификация: педагог-психолог
      </span>
      <!--<span class="course__acardion-text-bold">Николаева Анна Николаевна</span>
      <span class="course__acardion-text-small"
        >ГБОУ "Колледж гуманитарных и социально-педагогических дисциплин имени
        Святителя Алексия, Митрополита Московского" г.Тольятти,<br />
        квалификация по диплому "Учитель начальных классов и начальных классов
        компенсирующего и коррекционно-развивающего образования"</span
      >-->

      <span class="course__acardion-text-small">АНГЛИЙСКИЙ ЯЗЫК</span>
      <!--<span class="course__acardion-text-bold">Шелепова Ольга Игоревна </span>
      <span class="course__acardion-text-small"
        >Тольяттинский государственный университет<br />
        Квалификация: учитель английского и немецкого языков</span
      >-->
      <!--<span class="course__acardion-text-bold">Маркина Юлия Мнировна</span>
      <span class="course__acardion-text-small"
        >Самарский государственный педагогический университет<br />
        Квалификация: учитель английского и немецкого языков</span
      >-->
      <span class="course__acardion-text-bold">Романюк Татьяна Гавриловна</span>
      <span class="course__acardion-text-small"
        >Комсомольский-на-Амуре государственный педагогический институт<br />
        Квалификация: преподаватель дошкольной педагогики и психологии, методист
        по дошкольному воспитанию<br />
        Тольяттинская школа бизнеса “Лада БИЗНЕС СЕРВИС”<br />
        Квалификация: английский язык, методика преподавания английского языка
        для детей дошкольного возраста</span
      >
      <span class="course__acardion-text-bold">Сивякова Полина Игоревна</span>
      <span class="course__acardion-text-small"
        >Тольяттинский государственный университет<br />
        Квалификация: учитель английского языка</span
      >

      <!--<span class="course__acardion-text-small">ЛОГОПЕДЫ</span>
      <span class="course__acardion-text-bold">Яркина Елена Владимировна </span>
      <span class="course__acardion-text-small"
        >Уральский государственный педагогический университет<br />
        Квалификация: учитель-логопед, олигофренопедагог</span
      >-->
      <span class="course__acardion-text-bold">Пасько Ольга Алексеевна</span>
      <span class="course__acardion-text-small"
        >Ленинградский государственный университет им. А.С. Пушкина<br />
        Квалификация: учитель-логопед</span
      >
      <!--<span class="course__acardion-text-bold">Астафьева Ольга Юрьевна </span>
      <span class="course__acardion-text-small"
        >Московский государственный открытый педагогический университет им.
        М.А.Шолохова<br />
        Квалификация: олигофренопедагог, учитель-логопед коррекционных
        учреждений для детей с задержкой психического развития.</span
      >
      <span class="course__acardion-text-bold">Керимова Ирина Мударисовна</span>
      <span class="course__acardion-text-small"
        >Московский социально-гуманитарный институт<br />
        Квалификация: учитель-логопед
      </span>-->
      <span class="course__acardion-text-bold">Шевченко Галия Миннуровна</span>
      <span class="course__acardion-text-small"
        >Московский социально-гуманитарный институт<br />
        Квалификация: учитель-логопед</span
      >
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
export default {
  setup() {
    const toog = ref(false)

    function toogle() {
      toog.value = !toog.value
    }

    return { toog, toogle }
  },
}
</script>
