export function ifDate(item1, item2, item3) {
  const date = new Date(Number(item1) * 1000)
  const date1 = new Date(item2)
  const date2 = new Date(item3)
  const daysLag1 = Math.ceil(date.getTime() - date1.getTime())
  const daysLag2 = Math.ceil(date2.getTime() - date.getTime())

  if (daysLag1 >= 0 && daysLag2 >= 0) {
    return true
  } else {
    return false
  }
}
