<template>
  <p>
    <span>
      <input type="text" v-model.lazy="title1" />
      {{ title }}
    </span>
    <span>
      <input type="text" v-model.lazy="title2" />
      <div class="course-add-btn">
        <a @click="plusCount" class="btn-blue">+</a>
        <a @click="minusCount" class="btn-red">-</a>
      </div>
    </span>
  </p>
</template>
<script>
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
export default {
  props: ['count', 'data'],
  emits: ['plusCount', 'minusCount'],
  setup(props, { emit }) {
    const store = useStore()
    const title1 = ref(props.data.yare)
    const title2 = ref(props.data.title)
    const title = computed(() => {
      if (title1.value == 3) {
        return 'года'
      } else if (title1.value == 4) {
        return 'года'
      } else if (title1.value == 1) {
        return 'год'
      } else if (title1.value == 2) {
        return 'года'
      } else {
        return 'лет'
      }
    })

    const id = computed(() => store.getters.idCourse)
    const sub = computed(() => store.getters.subCourse)
    watch([title1, title2], val => {
      store.commit('addCourses', {
        id: props.data.id,
        subid: props.data.subid,
        yare: val[0],
        title: val[1],
        status: props.data.status,
      })
    })

    function plusCount() {
      emit('plusCount', props.data.status)
    }
    function minusCount() {
      emit('minusCount', props.data)
    }

    return { title1, title2, title, id, sub, plusCount, minusCount }
  },
}
</script>
