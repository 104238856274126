<template>
  <ul class="admin__box-td th-6 grid table2">
    <li class="admin__box-td-6 td-1">{{ roomitem }}</li>
    <li class="admin__box-td-6 td-2">{{ n.start_time }} - {{ n.end_time }}</li>
    <li class="admin__box-td-6 td-3">{{ selectinput }}</li>
    <li class="admin__box-td-6 td-4">{{ capacity }}</li>
    <li class="admin__box-td-6 td-5">{{ titleschedule }}</li>
    <li class="admin__box-td-6 td-6">{{ classesid }}</li>
    <li class="admin__box-td-6 td-7">{{ addressid }}</li>
    <li class="admin__box-td-6 td-8">{{ teacherid }}</li>
    <li class="position-box">
      <a @click="editTd(n.id)"><img src="../../../assets/img/edit.svg"/></a
      ><a @click="deleteItem(n.id)"
        ><img src="../../../assets/img/delete.svg"
      /></a>
    </li>
  </ul>
  <div class="modal" v-if="toog">
    <div class="modal__admin-box">
      <div class="modal__admin-1 modal__admin-container">
        <div>
          <p class="modal__admin-title">Кабинет:</p>
          <p>
            <select id="room" class="modal__admin-select" v-model="selecttype">
              <option v-for="item in room" :key="item">{{
                item.title
              }}</option></select
            >
          </p>
        </div>
        <div>
          <p class="modal__admin-title">Мест:</p>
          <p>
            <input
              class="admin-inp"
              type="text"
              placeholder="Мест"
              v-model.lazy="capacity"
            />
          </p>
        </div>
      </div>
      <div class="modal__admin-2 modal__admin-container">
        <p class="modal__admin-title">Время:</p>
        <p>
          <span
            ><input
              class="admin-inp"
              type="text"
              placeholder="Начало"
              v-model.lazy="start"
          /></span>
          <span
            ><input
              class="admin-inp"
              type="text"
              placeholder="Конец"
              v-model.lazy="end"
          /></span>
        </p>
      </div>
      <div class="modal__admin-3 modal__admin-container">
        <p class="modal__admin-title">Курс:</p>
        <p>
          <select id="type" class="modal__admin-select" v-model="selectinput">
            <option v-for="item in type2" :key="item">
              {{ item }}
            </option></select
          >
        </p>
      </div>

      <div class="modal__admin-33 modal__admin-container">
        <p class="modal__admin-title">Название:</p>
        <p>
          <textarea
            class="admin-text"
            placeholder="Название"
            v-model.lazy="titleschedule"
          />
        </p>
      </div>
      <div class="modal__admin-3 modal__admin-container">
        <p class="modal__admin-title">Переодичность:</p>
        <p>
          <app-select-custom
            v-model="classesData"
            :data="classes"
            :titleSelect="classesid"
            styleSelect="bottom"
          ></app-select-custom>
        </p>
      </div>
      <div class="modal__admin-5 modal__admin-container">
        <p class="modal__admin-title">Адрес:</p>
        <p>
          <app-select-custom
            v-model="addressTitle"
            :data="address"
            :titleSelect="addressid"
            styleSelect="bottom"
          ></app-select-custom>
        </p>
      </div>
      <div class="modal__admin-6 modal__admin-container">
        <p class="modal__admin-title">Педагог:</p>
        <p>
          <app-select-custom
            v-model="teacherTitle"
            :data="teacher"
            :titleSelect="teacherid"
            styleSelect="top"
          ></app-select-custom>
        </p>
      </div>
      <div class="modal__admin-btn">
        <button @click="close" type="button" class="btn btn-1">
          Сохранить
        </button>
      </div>
    </div>
  </div>

  <div v-if="toog" @click="close" class="overflow"></div>
</template>
<script>
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import AppSelectCustom from '../../ui/AppSelectCustom.vue'
export default {
  components: { AppSelectCustom },
  props: ['n'],
  emits: ['delete'],
  setup(props, { emit }) {
    const store = useStore()
    const tog = computed(() => store.state.popup)
    const toog = computed(() => {
      if (tog.value == props.n.id) {
        return true
      } else {
        return false
      }
    })

    const room = computed(() =>
      Object.keys(store.state.room).map(r => store.state.room[r])
    )

    const courses = computed(() => {
      if (props.n.type == 5) {
        return 'Подготовка 5 лет'
      } else if (props.n.type == 6) {
        return 'Подготовка 6 лет'
      } else if (props.n.type == 2) {
        return "Английский KID'S BOX"
      } else if (props.n.type == 1) {
        return 'Английский PLAYWAY'
      } else {
        return type2.value.filter(r => r == props.n.type)[0]
      }
    })

    const type2 = computed(() =>
      Array.from(
        new Set(
          [].concat.apply(
            [],
            Object.keys(store.state.dataCourses).map(r =>
              store.state.dataCourses[r].data.map(i => i.title)
            )
          )
        )
      )
    )

    const classes = computed(() => {
      if (store.state.classes) {
        return Object.keys(store.state.classes).map(r => store.state.classes[r])
      } else {
        return []
      }
    })

    const type = computed(() => {
      if (store.state.office) {
        return [].concat
          .apply(
            [],
            Object.keys(store.state.office).map(
              r => store.state.office[r].subtitle
            )
          )
          .filter(p => p.title !== '')
      } else {
        return []
      }
    })

    const co = computed(() => {
      if (props.n.type) {
        if (type.value.filter(p => p.id == props.n.type).length !== 0) {
          return type.value.filter(p => p.id == props.n.type)[0].title
        } else {
          return '-'
        }
      } else {
        return '-'
      }
    })
    const roomitem = computed(() => {
      if (props.n.room !== '' && props.n.room !== undefined) {
        if (room.value.filter(n => n.id === props.n.room).length !== 0) {
          return room.value.filter(n => n.id === props.n.room)[0].title
        } else {
          return '-'
        }
      } else {
        return '-'
      }
    })
    const address = computed(() => store.state.address)
    const teacher = computed(() => store.state.teacher)

    const classesid = computed(() => {
      if (
        props.n.classes !== '' &&
        props.n.classes !== undefined &&
        props.n.classes !== null
      ) {
        if (
          Object.keys(classes.value)
            .map(r => classes.value[r])
            .filter(
              n =>
                n.title.split(' ').join('') ==
                props.n.classes.split(' ').join('')
            ).length > 0
        ) {
          return Object.keys(classes.value)
            .map(r => classes.value[r])
            .filter(
              n =>
                n.title.split(' ').join('') ==
                props.n.classes.split(' ').join('')
            )[0].title
        } else {
          return '-'
        }
      } else {
        return '-'
      }
    })

    const addressid = computed(() => {
      if (
        props.n.address !== '' &&
        props.n.address !== undefined &&
        props.n.address !== null
      ) {
        if (
          Object.keys(address.value)
            .map(r => address.value[r])
            .filter(
              n =>
                n.title.split(' ').join('') ==
                props.n.address.split(' ').join('')
            ).length > 0
        ) {
          return Object.keys(address.value)
            .map(r => address.value[r])
            .filter(
              n =>
                n.title.split(' ').join('') ==
                props.n.address.split(' ').join('')
            )[0].title
        } else {
          return '-'
        }
      } else {
        return '-'
      }
    })
    const teacherid = computed(() => {
      if (
        props.n.teacher !== '' &&
        props.n.teacher !== undefined &&
        props.n.teacher !== null
      ) {
        if (
          Object.keys(teacher.value)
            .map(r => teacher.value[r])
            .filter(
              n =>
                n.title.split(' ').join('') ==
                props.n.teacher.split(' ').join('')
            ).length > 0
        ) {
          return Object.keys(teacher.value)
            .map(r => teacher.value[r])
            .filter(
              n =>
                n.title.split(' ').join('') ==
                props.n.teacher.split(' ').join('')
            )[0].title
        } else {
          return '-'
        }
      } else {
        return '-'
      }
    })
    const classesData = ref(classesid.value)
    const addressTitle = ref(addressid.value)
    const teacherTitle = ref(teacherid.value)
    const selectinput = ref(courses.value)
    const selecttype = ref(roomitem.value)
    const titleschedule = ref(props.n.title !== '' ? props.n.title : '-')
    const capacity = ref(props.n.capacity !== '' ? props.n.capacity : '-')
    const end = ref(props.n.end_time)
    const start = ref(props.n.start_time)

    const roomid = computed(() => {
      if (selecttype.value !== '' && selecttype.value !== '-') {
        return room.value.filter(n => n.title === selecttype.value)[0].id
      } else {
        return ''
      }
    })

    const coid = computed(() => {
      if (
        type.value.length !== 0 &&
        selectinput.value !== '' &&
        selectinput.value !== '-'
      ) {
        return type.value.filter(n => n.title === selectinput.value)[0].id
      } else {
        return ''
      }
    })
    const is = computed(() => store.getters.idAdd)
    const idAdd = computed(() => {
      if (props.n.id === null || props.n.id === undefined) {
        return is.value
      } else {
        return props.n.id
      }
    })

    const dataform = computed(() => {
      return {
        room: roomid.value,
        start_time: start.value,
        end_time: end.value,
        type: selectinput.value,
        capacity: capacity.value,
        title: titleschedule.value,
        id: idAdd.value,
        year: props.n.year,
        dow: props.n.dow,
        teacher: teacherTitle.value,
        classes: classesData.value,
        address: addressTitle.value,
      }
    })

    watch(dataform, val => {
      store.commit('updateScheduleItem', val)
    })

    function deleteItem(item) {
      emit('delete', item)
    }

    function editTd(item) {
      store.commit('popUpToogle', item)
    }

    function close() {
      store.commit('popUpToogle', null)
    }

    return {
      classesData,
      courses,
      toog,
      close,
      deleteItem,
      room,
      type,
      selectinput,
      selecttype,
      titleschedule,
      capacity,
      end,
      start,
      dataform,
      co,
      roomitem,
      roomid,
      coid,
      idAdd,
      is,
      address,
      addressTitle,
      addressid,
      teacher,
      teacherTitle,
      teacherid,
      editTd,
      tog,
      type2,
      classes,
      classesid,
    }
  },
}
</script>
