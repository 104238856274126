import { createStore, createLogger } from 'vuex'
import axios from '../axios/index'
import { error } from '../utils/error'
import router from '../router/index'

const plugins = []

if (process.env.NODE_ENV === 'development') {
  plugins.push(createLogger())
}

export default createStore({
  plugins,
  state() {
    return {
      print: [],
      foto: {
        english: ['english/001.jpg', 'english/002.jpg', 'english/003.jpg'],
        mogusam: [
          'mogusam/001.jpg',
          'mogusam/002.jpg',
          'mogusam/003.jpg',
          'mogusam/004.jpg',
          'mogusam/005.jpg',
          'mogusam/006.jpg',
        ],
        podgotovkakschkole: [
          'podgotovka-k-schkole/001.jpg',
          'podgotovka-k-schkole/002.jpg',
          'podgotovka-k-schkole/003.jpg',
          'podgotovka-k-schkole/004.jpg',
          'podgotovka-k-schkole/005.jpg',
          'podgotovka-k-schkole/006.jpg',
        ],
        zanjatiyasshkolnikami: [
          'zanjatiya-s-shkolnikami/001.jpg',
          'zanjatiya-s-shkolnikami/002.jpg',
          'zanjatiya-s-shkolnikami/003.jpg',
          'zanjatiya-s-shkolnikami/004.jpg',
          'zanjatiya-s-shkolnikami/005.jpg',
        ],
      },
      classes: {},
      patchTeacher: '/administrado',
      preload: false,
      statusInput3: false,
      statusInput2: false,
      statusInput5: false,
      statusInput: true,
      status: {},
      titleChoosingSchedule: '',
      requestSchedule: null,
      warning: [],
      popup: null,
      rout: [],
      routes: {},
      token: localStorage.getItem('jwt-token'),
      message: {},
      scheduleData: {},
      scheduleData2: null,
      year: 'add',
      idToken: '',
      id: '',
      users: [],
      room: [],
      dataCourses: {},
      courses: [],
      office: {},
      dataOffice: {},
      keyOffice: [],
      teacher: [],
      address: [],
      request: null,
    }
  },
  actions: {
    async deleteRequestSchedule({ dispatch, commit }, payload) {
      try {
        await axios.delete(
          `requestschedule/${new Date().getFullYear()}/${payload.subid}/${
            payload.id
          }.json`
        )
        dispatch('getRequestSchedule', new Date().getFullYear())
        commit('inputStatus2', true)
        setTimeout(() => {
          commit('inputStatus2', false)
        }, 1000)
      } catch (e) {
        throw new Error()
      }
    },

    async addRequestSchedule({ dispatch, commit }, payload) {
      try {
        await axios.post(
          `requestschedule/${new Date().getFullYear()}/${payload.subid}.json`,
          payload
        )
        dispatch('getRequestSchedule', new Date().getFullYear())
        commit('inputStatus2', true)
        setTimeout(() => {
          commit('inputStatus2', false)
        }, 1000)
      } catch (e) {
        throw new Error()
      }
    },

    async getRequestSchedule({ commit }, payload) {
      try {
        const { data } = await axios.get(`requestschedule/${payload}.json`)
        commit('requestScheduleGet', data)
        commit('inputStatus3', false)
      } catch (e) {
        throw new Error()
      }
    },
    async setRoute({ getters }, payload) {
      try {
        const token = getters.token
        await axios.patch(
          `routes/-MqYhNTmm2olG62rrHde.json?auth=${token}`,
          payload
        )
      } catch (e) {
        throw new Error()
      }
    },
    async getRoute({ commit }) {
      try {
        const { data } = await axios.get(`routes.json`)
        commit('routesGet', data)
      } catch (e) {
        throw new Error()
      }
    },

    async getStatusDeletee({ getters }) {
      try {
        const token = getters.token
        axios.delete(`status/2022/-N3s5HZmZNgk2RQA4xQf.json?auth=${token}`)
      } catch (e) {
        throw new Error()
      }
    },

    async getStatus({ commit }, payload) {
      try {
        const { data } = await axios.get(`status/${payload}.json`)
        commit('StatusGet', data)
      } catch (e) {
        throw new Error()
      }
    },

    async deleteStatus({ dispatch, getters }, payload) {
      try {
        const token = getters.token
        await axios.delete(
          `status/${payload.date}/${payload.id}.json?auth=${token}`
        )
        dispatch('getStatus', payload.year)
      } catch (e) {
        throw new Error()
      }
    },

    async StatusAdd({ dispatch, getters }, payload) {
      try {
        const token = getters.token
        await axios.post(
          `status/${payload.year}.json?auth=${token}`,
          payload.status
        )
        dispatch('getStatus', payload.year)
      } catch (e) {
        throw new Error()
      }
    },
    async deleteRequest2(_, payload) {
      try {
        await axios.delete(`request/${payload.year}/${payload.id}.json`)
      } catch (e) {
        throw new Error()
      }
    },
    async deleteRequest({ dispatch, getters }, payload) {
      const token = getters.token

      try {
        await axios.delete(`request/${payload.year}/${payload.id}.json`)
        if (payload.choice) {
          await axios.delete(
            `requestschedule/${payload.year}/${payload.id}.json?auth=${token}`
          )
        }
        if (payload.status) {
          await axios.delete(
            `status/${payload.year}/${payload.id}.json?auth=${token}`
          )
        }
        dispatch('Request', payload.year)
      } catch (e) {
        throw new Error()
      }
    },

    async requestStatus({ dispatch, commit, getters }, payload) {
      try {
        const token = getters.token
        await axios.put(
          `request/${payload.date}/${payload.id}/status.json`,
          JSON.stringify(payload.status)
        )
        if (payload.status == 1) {
          if (payload.date == new Date().getFullYear()) {
            const { data } = await axios.put(
              `status/${payload.date}/${payload.id}.json?auth=${token}`,
              payload.data
            )
            await axios.put(
              `request/${payload.date}/${payload.id}/status2.json`,
              JSON.stringify(data.name)
            )
          }
        } else {
          if (payload.date == new Date().getFullYear()) {
            await dispatch('deleteStatus', payload)
            axios.delete(`request/${payload.date}/${payload.id}/status2.json`)
          }
        }
        commit('inputStatus', true)
        dispatch('Request', payload.date)
      } catch (e) {
        throw new Error()
      }
    },
    async requestPutchose(_, payload) {
      try {
        await axios.put(
          `request/${new Date().getFullYear()}/${payload.id}/choice.json`,
          JSON.stringify(payload.status)
        )
      } catch (e) {
        throw new Error()
      }
    },

    async requestAdd({ dispatch, getters }, payload) {
      const token = getters.token
      try {
        if (payload.name) {
          await axios.put(
            `request/${payload.data.year}/${payload.id}/name.json`,
            JSON.stringify(payload.data.name)
          )
        }
        if (payload.agent) {
          await axios.put(
            `request/${payload.data.year}/${payload.id}/agent.json`,
            JSON.stringify(payload.data.agent)
          )
        }
        if (payload.appldate) {
          await axios.put(
            `request/${payload.data.year}/${payload.id}/birthdate.json`,
            JSON.stringify(payload.data.birthdate)
          )
        }
        if (payload.type) {
          await axios.put(
            `request/${payload.data.year}/${payload.id}/type.json`,
            payload.data.type
          )
        }
        if (payload.phone) {
          await axios.put(
            `request/${payload.data.year}/${payload.id}/phone.json`,
            JSON.stringify(payload.data.phone)
          )
        }

        if (payload.data.status == 1) {
          if (payload.phone) {
            await axios.put(
              `status/${payload.data.year}/${payload.id}/key.json?auth=${token}`,
              JSON.stringify(payload.key)
            )
          }
          if (payload.name) {
            await axios.put(
              `status/${payload.data.year}/${payload.id}/name.json?auth=${token}`,
              JSON.stringify(payload.data.name)
            )
          }
          if (payload.type) {
            await axios.put(
              `status/${payload.data.year}/${payload.id}/type.json?auth=${token}`,
              JSON.stringify(payload.data.type)
            )
            if (payload.choice) {
              await axios.delete(
                `requestschedule/${payload.data.year}/${payload.id}.json`
              )
              await axios.put(
                `request/${payload.data.year}/${payload.id}/choice.json`,
                JSON.stringify(false)
              )
            }
          }
        }
        dispatch('Request', payload.data.year)
      } catch (e) {
        throw new Error()
      }
    },

    async Addrequest2({ dispatch, commit }, payload) {
      try {
        await axios.post(`request/${payload.year}.json`, payload)
        dispatch('Request', payload.year)

        commit('addWarning', [
          {
            title: '',
            style: 'warning-box-green',
            text: 'Заявка создана',
            text2: '',
          },
        ])
        setTimeout(() => {
          commit('addWarning', [])
        }, 500)
      } catch (e) {
        throw new Error()
      }
    },

    async Addrequest({ commit }, payload) {
      try {
        await axios.post(`request/${payload.year}.json`, payload)

        commit('addWarning', [
          {
            title: 'Заявка создана',
            style: 'warning-box-green',
            text:
              'Спасибо за предварительную запись и за то, что выбрали нашу школу.',
            text2:
              'Расписание занятий можно будет выбрать позже. C вами свяжется наш администратор и объяснит, как это сделать.',
          },
        ])
        setTimeout(() => {
          commit('addWarning', [])
        }, 7000)
      } catch (e) {
        throw new Error()
      }
    },

    async Request({ commit, getters }, payload) {
      try {
        const token = getters.token
        const { data } = await axios.get(
          `request/${payload}.json?auth=${token}`
        )
        commit('requestAdd', data)
      } catch (e) {
        throw new Error()
      }
    },

    async addTeacher({ dispatch, getters }, payload) {
      try {
        const token = getters.token
        await axios.post(`teacher.json?auth=${token}`, payload)
        dispatch('getTeacher')
      } catch (e) {
        throw new Error()
      }
    },

    async editTeacher({ dispatch, getters }, payload) {
      try {
        const token = getters.token
        await axios.put(
          `teacher/${payload.id}/title.json?auth=${token}`,
          JSON.stringify(payload.title)
        )
        dispatch('getTeacher')
      } catch (e) {
        throw new Error()
      }
    },

    async getTeacher({ commit, getters }) {
      try {
        const token = getters.token
        const { data } = await axios.get(`teacher.json?auth=${token}`)
        commit('AddTeacher', data)
      } catch (e) {
        throw new Error()
      }
    },

    async deleteTeacher({ dispatch, getters }, payload) {
      try {
        const token = getters.token
        await axios.delete(`teacher/${payload}.json?auth=${token}`)
        dispatch('getTeacher')
      } catch (e) {
        throw new Error()
      }
    },

    async addAddress({ dispatch, getters }, payload) {
      try {
        const token = getters.token
        await axios.post(`address.json?auth=${token}`, payload)
        dispatch('getAddress')
      } catch (e) {
        throw new Error()
      }
    },
    async editAddress({ dispatch, getters }, payload) {
      try {
        const token = getters.token
        await axios.put(
          `address/${payload.id}/title.json?auth=${token}`,
          JSON.stringify(payload.title)
        )
        dispatch('getAddress')
      } catch (e) {
        throw new Error()
      }
    },

    async getAddress({ commit, getters }) {
      try {
        const token = getters.token
        const { data } = await axios.get(`address.json?auth=${token}`)
        commit('AddAddress', data)
      } catch (e) {
        throw new Error()
      }
    },

    async deleteAddress({ dispatch, getters }, payload) {
      try {
        const token = getters.token
        await axios.delete(`address/${payload}.json?auth=${token}`)
        dispatch('getAddress')
      } catch (e) {
        throw new Error()
      }
    },

    async getoffice({ commit }) {
      try {
        const { data } = await axios.get(`office.json`)
        commit('officeAdd', data)
      } catch (e) {
        throw new Error()
      }
    },

    async addoffice({ dispatch, getters }, payload) {
      try {
        const token = getters.token
        await axios.post(`office.json?auth=${token}`, payload)
        dispatch('getoffice')
      } catch (e) {
        throw new Error()
      }
    },

    async editoffice({ dispatch, getters }, payload) {
      try {
        const token = getters.token
        await axios.put(`office/${payload.id}.json?auth=${token}`, payload.data)
        dispatch('getoffice')
      } catch (e) {
        throw new Error()
      }
    },

    async deleteOffice({ dispatch, getters }, payload) {
      try {
        const token = getters.token
        await axios.delete(`office/${payload}.json?auth=${token}`)
        dispatch('getoffice')
      } catch (e) {
        throw new Error()
      }
    },

    async addclasses({ dispatch, getters }, payload) {
      try {
        const token = getters.token
        await axios.post(`classes.json?auth=${token}`, payload)
        dispatch('getclasses')
      } catch (e) {
        throw new Error()
      }
    },

    async deleteclasses({ dispatch, getters }, payload) {
      try {
        const token = getters.token
        await axios.delete(`classes/${payload}.json?auth=${token}`)
        dispatch('getclasses')
      } catch (e) {
        throw new Error()
      }
    },
    async editclasses({ dispatch, getters }, payload) {
      try {
        const token = getters.token
        await axios.put(
          `classes/${payload.id}/title.json?auth=${token}`,
          JSON.stringify(payload.title)
        )
        dispatch('getclasses')
      } catch (e) {
        throw new Error()
      }
    },

    async getclasses({ commit }) {
      try {
        const { data } = await axios.get(`classes.json`)
        commit('classesAdd', data)
      } catch (e) {
        throw new Error()
      }
    },

    async getRoom({ commit }) {
      try {
        const { data } = await axios.get(`room.json`)
        commit('AddRoom', data)
      } catch (e) {
        throw new Error()
      }
    },

    async addRoom({ dispatch, getters }, payload) {
      try {
        const token = getters.token
        await axios.post(`room.json?auth=${token}`, payload)
        dispatch('getRoom')
      } catch (e) {
        throw new Error()
      }
    },

    async editRoom({ dispatch, getters }, payload) {
      try {
        const token = getters.token
        await axios.put(
          `room/${payload.id}/title.json?auth=${token}`,
          JSON.stringify(payload.title)
        )
        dispatch('getRoom')
      } catch (e) {
        throw new Error()
      }
    },

    async deleteRoom({ dispatch, getters }, payload) {
      try {
        const token = getters.token
        await axios.delete(`room/${payload}.json?auth=${token}`)
        dispatch('getRoom')
      } catch (e) {
        throw new Error()
      }
    },

    async deleteSchedule({ state, dispatch, getters }, payload) {
      try {
        const token = getters.token
        await axios.delete(`schedule/${payload}.json?auth=${token}`)
        dispatch('schedule')
        state.year = 'add'
        router.push('/add-school-year/add')
      } catch (e) {
        throw new Error()
      }
    },

    async uptadeSchedule({ state, commit, getters }) {
      try {
        const token = getters.token
        await axios.put(
          `schedule/${state.year}/${state.idToken}.json?auth=${token}`,
          getters.YearScheduleItem
        )
        commit('inputStatus5', false)
      } catch (e) {
        throw new Error()
      }
    },

    async addYearSchedule({ dispatch, getters }, payload) {
      try {
        const token = getters.token
        await axios.post(`schedule/${payload}.json?auth=${token}`, [
          {
            room: '',
            start_time: '',
            end_time: '',
            type: '',
            capacity: '',
            title: '',
            id: getters.idAdd,
            year: payload,
            dow: '1',
            address: '',
            teacher: '',
          },
          {
            room: '',
            start_time: '',
            end_time: '',
            type: '',
            capacity: '',
            title: '',
            id: getters.idAdd + 1,
            year: payload,
            dow: '2',
            address: '',
            teacher: '',
          },
          {
            room: '',
            start_time: '',
            end_time: '',
            type: '',
            capacity: '',
            title: '',
            id: getters.idAdd + 2,
            year: payload,
            dow: '3',
            address: '',
            teacher: '',
          },
          {
            room: '',
            start_time: '',
            end_time: '',
            type: '',
            capacity: '',
            title: '',
            id: getters.idAdd + 3,
            year: payload,
            dow: '4',
            address: '',
            teacher: '',
          },
          {
            room: '',
            start_time: '',
            end_time: '',
            type: '',
            capacity: '',
            title: '',
            id: getters.idAdd + 4,
            year: payload,
            dow: '5',
            address: '',
            teacher: '',
          },
          {
            room: '',
            start_time: '',
            end_time: '',
            type: '',
            capacity: '',
            title: '',
            id: getters.idAdd + 5,
            year: payload,
            dow: '6',
            address: '',
            teacher: '',
          },
          {
            room: '',
            start_time: '',
            end_time: '',
            type: '',
            capacity: '',
            title: '',
            id: getters.idAdd + 6,
            year: payload,
            dow: '7',
            address: '',
            teacher: '',
          },
        ])
        dispatch('schedule')
      } catch (e) {
        throw new Error()
      }
    },

    async schedule2({ commit }, payload) {
      try {
        const { data } = await axios.get(`schedule/${payload}.json`)
        commit('scheduleAdd2', data)
      } catch (e) {
        throw new Error()
      }
    },

    async schedule({ commit }) {
      try {
        const { data } = await axios.get(`schedule.json`)
        commit('scheduleAdd', data)
      } catch (e) {
        throw new Error()
      }
    },

    async getUser({ commit, getters }) {
      try {
        const token = getters.token
        const { data } = await axios.get(`users.json?auth=${token}`)
        commit('listuser', data)
      } catch (e) {
        throw new Error()
      }
    },

    async login({ commit }, payload) {
      let item = { email: payload.email, password: payload.password }
      if (item.password === '171717') {
        item.password = '1234554321'
      } else {
        item.password = 'dfg54d5f64gd'
      }
      try {
        const { data } = await axios.post(
          'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyArJVfs9Y2sqk64mkGPLWtN6LBQcIbZ-rg',
          {
            ...item,
            returnSecureToken: true,
          }
        )
        commit('settoken', {
          token: data.idToken,
          email: payload.email,
        })
      } catch (e) {
        commit(
          'setMessage',
          {
            value: error(e.response.data.error.message),
            type: 'danger',
          },
          { root: true }
        )
        setTimeout(() => {
          commit('clearMessage')
        }, 2000)

        throw new Error()
      }
    },
  },

  mutations: {
    printAdd(state, payload) {
      state.print = payload
    },
    classesAdd(state, payload) {
      state.classes = payload
    },

    patchAdd(state, payload) {
      state.patchTeacher = payload
    },

    preloadAdd(state, payload) {
      state.preload = payload
    },

    inputStatus5(state, payload) {
      state.statusInput5 = payload
    },
    inputStatus3(state, payload) {
      state.statusInput3 = payload
    },
    inputStatus2(state, payload) {
      state.statusInput2 = payload
    },

    inputStatus(state, payload) {
      state.statusInput = payload
    },

    StatusGet(state, payload) {
      state.status = payload
    },

    addChoosingTitle(state, payload) {
      state.titleChoosingSchedule = payload.title
    },

    requestScheduleGet(state, payload) {
      state.requestSchedule = payload
    },

    addWarning(state, payload) {
      state.warning = payload
    },

    popUpToogle(state, payload) {
      state.popup = payload
    },

    routGet(state, payload) {
      state.rout = Object.keys(payload).map(r => payload[r])
    },

    routesGet(state, payload) {
      const obj = payload;
      obj['dsf65sd4f56sd465fs465f65ds'] = {
        route: "city-camp",
        img: "Untitled.jpg",
        title: "Каникулы для детей",
        mini: "Каникулы для детей",
        icon: "photo2.jpg",
        faq: [
            {
              title: "Какие гарантии безопасности моего ребенка?",
              text2:[
                  {
                    text: "Педагоги, которые работают с детьми, несут ответственность за жизнь и здоровье каждого ребенка. Каждое утро дети проходят инструктаж по технике безопасности.",
                  }
              ]
            },
          {
            title: "Как организовано питание?",
            text2:[
              {
                text: "У нас только свежее полезное и очень вкусное питание!",
              },
              {
                text: "Еду для наших ребят готовит проверенное кафе и привозит в индивидуальных контейнерах каждому ребенку.",
              },
              {
                text: "Мы используем одноразовую посуду, чтобы все было максимально безопасно. ",
              },
              {
                text: "Наши ребята оценили вкус и качество приготовленных блюд на 100%.",
              },
              {
                title: "Дети питаются 3 раза в день: ланч, обед, полдник.",
                text: "1 раз в день – это полноценное горячее питание (салат, суп, второй, напиток).",
              },
              {
                text: "2 раза – полезный перекус.",
              },
              {
                text: "Также мы учитываем ситуации, когда у ребенка есть аллергия на те или иные продукты, и предоставляем специальное меню.",
              },
              {
                text: "Утром желательно приводить детей, накормив их завтраком.",
              },
            ]
          },
          {
            title: "А если моему ребенку не понравится?",
            text2:[
              {
                text: "Наши педагоги стараются найти индивидуальный подход к каждому ребенку. Мы всегда стараемся решить проблему или конфликт сразу на месте.",
              },
              {
                text: "У нас множество активностей и событий, поэтому каждый ребенок сможет найти для себя интересную деятельность. Педагоги оказывают внимание тем детям, которым труднее остальных адаптироваться в новом коллективе. Мы знаем, что некоторым детям нужно чуть больше времени привыкнуть к новому, поэтому помогаем им пройти этот период комфортнее и быстрее.",
              },
              {
                text: "Но иногда нужно просто дать время ребенку, чтобы он привык. Как правило такие дети потом не хотят уходить и возвращаются снова.",
              }
            ]
          },
          {
            title: "Какого возраста будут дети?",
            text2:[
              {
                text: "Обычно программы рассчитаны для  детей от 6-ти до 11 лет. Ребята делятся на подгруппы примерно по 12 человек.",
              },
            ]
          },
          {
            title: "Какой режим работы? ",
            text2:[
              {
                text: "Режим работы очень удобный: вы можете привести ребенка к 8.30. Так обычно начинаются уроки  в школе. И забрать его в 18.00.",
              },
            ]
          },
          {
            title: "Как дети передвигаются по городу?",
            text2:[
              {
                text: "Дети передвигаются организованным строем, в соответствии с правилами безопасности. У нас отличная дисциплина! Каждое утро с детьми повторяется техника безопасности. ",
              },
              {
                text: "На выездные экскурсии мы организуем специальный трансфер с разрешениями в  ГАИ.",
              },
            ]
          },
          {
            title: "Если я не уверен, что смогу приводить ребенка каждый день?",
            text2:[
              {
                text: "Вы можете приводить ребенка, когда есть возможность или необходимость, оплачивая стоимость разового посещения.",
              },
            ]
          },
          {
            title: "А если мой ребенок заболеет?",
            text2:[
              {
                text: "Если вы пропускаете 1-2 дня, то сумма не пересчитывается.",
              },
              {
                text: "При длительной болезни от 5 дней подряд и предоставлении справки о болезни эти дни можно перенести на следующие программы.",
              },
              {
                text: "Если это последняя программа, то перерасчет можно сделать исходя из суммы разового посещения.",
              },

            ]
          },
          {
            title: "Нам нужно предоставлять какие-либо справки или анализы от врачей?",
            text2:[
              {
                text: "Вам нужно взять справку от врача, что ребенок здоров и может посещать группу дневного пребывания. Никаких специальных анализов сдавать не нужно.",
              },
            ]
          },
        ],
      }
      state.routes = payload
    },
    requestAdd(state, payload) {
      state.request = payload
    },

    AddAddress(state, payload) {
      state.address = payload
    },
    AddTeacher(state, payload) {
      state.teacher = payload
    },
    deleteKeyOffice(state, payload) {
      state.keyOffice.unshift({ key: payload, data: state.office[payload] })
      delete state.office[payload]
    },
    addKeys(state, payload) {
      state.office[payload] = state.keyOffice.filter(
        r => r.key == payload
      )[0].data
    },
    officeAdd(state, payload) {
      state.dataCourses = payload
    },

    AddRoom(state, payload) {
      state.room = payload
    },

    listuser(state, payload) {
      state.users = payload
    },

    idToken(state, payload) {
      state.idToken = payload
    },

    deleteScheduleItem(state, payload) {
      if (
        state.idToken !== undefined &&
        state.year !== 'add' &&
        state.year !== undefined
      ) {
        const index = state.scheduleData[state.year][state.idToken].findIndex(
          element => element.id === payload.id
        )
        if (index >= 0) {
          state.scheduleData[state.year][state.idToken].splice(index, 1)
        }
      }
    },

    updateScheduleItem(state, payload) {
      if (
        state.idToken !== undefined &&
        state.year !== 'add' &&
        state.year !== undefined
      ) {
        const index = state.scheduleData[state.year][state.idToken].findIndex(
          element => element.id === payload.id
        )
        if (index >= 0) {
          state.scheduleData[state.year][state.idToken].splice(
            index,
            1,
            payload
          )
        }
      }
    },
    deleteCourse(state, payload) {
      const index = state.courses.findIndex(r => r.id == payload.id)
      state.courses.splice(index, 1)
    },

    deleteCourses(state, payload) {
      if (payload == 0 || payload == 1) {
        state.courses = state.courses.filter(r => r.status !== payload)
      } else {
        state.courses = []
      }
    },

    pushCourses(state, payload) {
      state.courses = payload
    },

    addCourses(state, payload) {
      if (state.courses.filter(r => r.id == payload.id).length > 0) {
        const index = state.courses.findIndex(r => r.id == payload.id)
        state.courses.splice(index, 1, payload)
      } else {
        state.courses.push(payload)
      }
    },

    addScheduleItem(state, payload) {
      if (
        state.idToken !== undefined &&
        state.year !== 'add' &&
        state.year !== undefined
      ) {
        state.scheduleData[state.year][state.idToken].push(payload.obj)
      }
    },

    addyear(state, item) {
      state.year = item
    },

    scheduleAdd2(state, item) {
      state.scheduleData2 = item
    },

    scheduleAdd(state, item) {
      state.scheduleData = item
      if (router.currentRoute.value.params.id !== undefined) {
        state.year = router.currentRoute.value.params.id
      }
    },

    logout(state) {
      state.token = null
      localStorage.removeItem('jwt-token')
    },
    settoken(state, token) {
      state.token = token.token
      localStorage.setItem('jwt-token', token.token)
      if (token.email !== 'teacher@rino-tlt.ru') {
        localStorage.setItem('patch', token.email)
        router.push('/admin')
      } else {
        localStorage.setItem('patch', token.email)
        router.push(state.patchTeacher)
      }
    },
    setMessage(state, message) {
      state.message = message
    },
    clearMessage(state) {
      state.message = {}
    },
  },
  getters: {
    requestScheduleItem: state => payload => {
      if (state.requestSchedule) {
        return (
          Object.keys(state.requestSchedule)
            .map(r => state.requestSchedule[r])
            .filter(r => r.rid == payload).length !== 0
        )
      } else {
        return false
      }
    },

    token(state) {
      return state.token
    },
    courseValid(state) {
      const id = state.courses.filter(r => r.title == '')
      const sub = state.courses.filter(r => r.yare == '')
      if (id.length == 0 && sub.length == 0) {
        return true
      } else {
        return false
      }
    },

    idCourse(state) {
      if (state.dataCourses) {
        if (Object.keys(state.dataCourses).length !== 0) {
          const b = Object.keys(state.dataCourses)
            .map(r => state.dataCourses[r].data)[0]
            .map(r => r.id)

          return Math.max.apply(null, b) + 1
        } else {
          return 1
        }
      } else {
        return 1
      }
    },

    subCourse(state) {
      if (state.dataCourses) {
        if (Object.keys(state.dataCourses).length !== 0) {
          const b = Object.keys(state.dataCourses)
            .map(r => state.dataCourses[r].data)[0]
            .map(r => r.subid)

          return Math.max.apply(null, b) + 1
        } else {
          return 1
        }
      } else {
        return 1
      }
    },

    requests(state) {
      if (state.request) {
        console.log(state.request)
        return Object.keys(state.request).map(r => {
          return { id: r, data: state.request[r] }
        })
      } else {
        return []
      }
    },

    isAuthenticated(state) {
      return !!state.token
    },

    YearScheduleItem(state) {
      if (
        state.idToken !== undefined &&
        state.year !== 'add' &&
        state.year !== undefined
      ) {
        return state.scheduleData[state.year][state.idToken]
      } else {
        return []
      }
    },

    idAdd(state) {
      let g = []
      if (state.scheduleData) {
        Object.keys(state.scheduleData)
          .map(r => state.scheduleData[r])
          .map(d => Object.keys(d).map(f => d[f]))
          .map(w => w[0].map(o => g.push(o.id)))
      }
      return Math.max.apply(null, g) + 1
    },
  },
})
