<template>
  <ul class="filter-request request-schedule">
    <li class="admin__box-td-9 td9-2">
      <span>Учебный год:</span>
      <input
        v-mask="'####'"
        type="text"
        class="filter-input"
        v-model.lazy="year"
        placeholder="Учебный год"
      />
    </li>
    <li class="admin__box-td-9 td9-3">
      <span>По ФИО ребенка:</span>
      <input
        type="text"
        class="filter-input"
        id="f-name"
        name="f[name]"
        v-model.lazy="name"
        placeholder="ФИО"
      />
    </li>
    <li class="admin__box-td-9 td9-5">
      <span>По ФИО представителя:</span>
      <input
        type="text"
        class="filter-input"
        v-model.lazy="agent"
        placeholder="ФИО"
      />
    </li>
    <li class="admin__box-td-9 td9-6">
      <span>По контактному телефону:</span>
      <input
        v-mask="['###########']"
        type="text"
        class="filter-input"
        v-model.lazy="phone"
        placeholder="89170000000"
      />
    </li>
    <li class="admin__box-td-9 td9-8">
      <span>По названию курса:</span>
      <select
        class="filter-input"
        placeholder="Статус заявки"
        v-model.lazy="type"
      >
        <option value="-1">Любой</option>
        <option v-for="item in type2" :key="item" :value="item">
          {{ item }}
        </option>
      </select>
    </li>
    <li class="admin__box-td-9 td9-8">
      <span>По номеру кабинета:</span>
      <select
        class="filter-input"
        placeholder="Статус заявки"
        v-model.lazy="cap"
      >
        <option value="-1">Любой</option>
        <option v-for="item in roomdata" :key="item" :value="item.id">
          {{ item.title }}
        </option>
      </select>
    </li>
    <li class="admin__box-td-9 td9-8">
      <span>Педагог:</span>
      <input
        type="text"
        class="filter-input"
        v-model.lazy="teacher"
        placeholder="ФИО"
      />
    </li>
    <li class="admin__box-td-9 td9-8">
      <span>Адрес:</span>
      <input
        type="text"
        class="filter-input"
        v-model.lazy="adress"
        placeholder="Адрес"
      />
    </li>
    <li class="print__block">
      <span><img src="../../../assets/img/print-svgrepo-com.svg" /></span
      ><a @click.prevent="routerrequestcheduler()">Печать</a>
    </li>
  </ul>
</template>
<script>
import { useStore } from 'vuex'
import { mask } from 'vue-the-mask'
import { ref, watch, computed } from 'vue'
export default {
  emits: ['update:modelValue', 'routerscheduler'],
  props: ['modelValue'],
  directives: { mask },
  setup(_, { emit }) {
    const store = useStore()
    const yearNow = new Date().getFullYear()
    const year = ref(yearNow)
    const agent = ref()
    const name = ref()
    const phone = ref()
    const type = ref()
    const cap = ref()
    const teacher = ref()
    const adress = ref()

    const type2 = computed(() =>
      Array.from(
        new Set(
          [].concat.apply(
            [],
            Object.keys(store.state.dataCourses).map((r) =>
              store.state.dataCourses[r].data.map((i) => i.title)
            )
          )
        )
      )
    )

    watch([agent, name, phone, type, year, cap, teacher, adress], (values) => {
      emit('update:modelValue', {
        agent: values[0],
        name: values[1],
        phone: values[2],
        type: values[3],
        year: values[4],
        cap: values[5],
        teacher: values[6],
        adress: values[7],
      })
    })

    const roomdata = computed(() =>
      Object.keys(store.state.room).map((r) => store.state.room[r])
    )

    function routerrequestcheduler() {
      emit('routerscheduler')
    }

    return {
      routerrequestcheduler,
      type2,
      year,
      yearNow,
      agent,
      name,
      phone,
      type,
      cap,
      adress,
      teacher,
      roomdata,
    }
  },
}
</script>
