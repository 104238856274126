import { createRouter, createWebHistory } from 'vue-router'
import AppIndex from '../components/index/AppIndex.vue'
import AppAdministrado from '../components/admin/AppAdministrado.vue'
import AppAdminPanel from '../components/admin/AppAdminPanel.vue'
import AddSchoolYear from '../components/admin/AddSchoolYear.vue'
import AppList from '../components/admin/AppList.vue'
import AppAdd from '../components/admin/AppAdd.vue'
import AppRoom from '../components/admin/AppRoom.vue'
import AppOffice from '../components/admin/AppOffice.vue'
import AppTeacher from '../components/admin/AppTeacher.vue'
import AppAddress from '../components/admin/AppAddress.vue'
import AppRequestTable from '../components/admin/Request-table/AppRequestTable.vue'
import AppCourses from '../components/courses/AppCourses.vue'
import AppRequestSchedule from '../components/admin/AppRequestSchedule.vue'
import AppChoosingSchedule from '../components/AppChoosingSchedule.vue'
import store from '../store/index'
import AppScheduleReq from '../components/ui/AppScheduleReq.vue'
import AppInformationAbout from '../components/index/AppInformationAbout.vue'
import AppScheduleTeacher from '../components/admin/AppScheduleTeacher.vue'
import AppDurationClasses from '../components/admin/AppDurationClasses.vue'
import AppPolitikakon from '../components/info/AppPolitikakon.vue'
import AppSoglasienaobrdan from '../components/info/AppSoglasienaobrdan.vue'
import AppPrintRequestSchedule from '../components/admin/Print/AppPrintRequestSchedule.vue'
import AppPrintRequest from '../components/admin/Print/AppPrintRequest.vue'
import AppIndexTest from '../components/index/AppIndexTest.vue'

const routes = [
  {
    path: '/',
    component: AppIndex,
    meta: {
      layout: 'home',
    },
    props: true,
  },
  {
    path: '/test',
    component: AppIndexTest,
    meta: {
      layout: 'test',
    },
    props: true,
  },

  {
    path: '/information-about',
    component: AppInformationAbout,
    meta: {
      layout: 'home',
    },
    props: true,
  },

  {
    path: '/courses/:id',
    component: AppCourses,
    meta: {
      layout: 'home',
    },
    props: true,
  },

  {
    path: '/schedule-teacher/:id',
    component: AppScheduleTeacher,
    meta: {
      layout: 'schedule',
      auth: true,
    },
    props: true,
  },

  {
    path: '/choosing-a-schedule',
    component: AppChoosingSchedule,
    meta: {
      layout: 'schedule',
    },
    props: true,
  },
  {
    path: '/politikakon',
    component: AppPolitikakon,
    meta: {
      layout: 'schedule',
    },
    props: true,
  },
  {
    path: '/soglasienaobrdan',
    component: AppSoglasienaobrdan,
    meta: {
      layout: 'schedule',
    },
    props: true,
  },

  {
    path: '/administrado',
    component: AppAdministrado,
    meta: {
      layout: 'auth',
    },
    props: true,
  },
  {
    path: '/admins',
    component: AppList,
    meta: {
      layout: 'panel',
      auth: true,
    },
    props: true,
  },
  {
    path: '/duration-classes',
    component: AppDurationClasses,
    meta: {
      layout: 'panel',
      auth: true,
    },
    props: true,
  },

  {
    path: '/printrequest',
    component: AppPrintRequest,
    meta: {
      layout: 'print',
      auth: true,
    },
    props: true,
  },

  {
    name: 'printrequest',
    path: '/printrequestshedule',
    component: AppPrintRequestSchedule,
    meta: {
      layout: 'print',
      auth: true,
    },
    props: true,
  },

  {
    path: '/add',
    component: AppAdd,
    meta: {
      layout: 'panel',
      auth: true,
    },
    props: true,
  },
  {
    path: '/add-school-year/:id',
    component: AddSchoolYear,
    meta: {
      layout: 'panel',
      auth: true,
    },
    props: true,
  },
  {
    path: '/admin',
    component: AppAdminPanel,
    children: [],
    meta: {
      layout: 'panel',
      auth: true,
    },
    props: true,
  },
  {
    path: '/room',
    component: AppRoom,
    meta: {
      layout: 'panel',
      auth: true,
    },
    props: true,
  },
  {
    path: '/courses',
    component: AppOffice,
    meta: {
      layout: 'panel',
      auth: true,
    },
    props: true,
  },
  {
    path: '/teacher',
    component: AppTeacher,
    meta: {
      layout: 'panel',
      auth: true,
    },
    props: true,
  },
  {
    path: '/address',
    component: AppAddress,
    meta: {
      layout: 'panel',
      auth: true,
    },
    props: true,
  },
  {
    path: '/requestschedule',
    component: AppRequestSchedule,
    meta: {
      layout: 'panel',
      auth: true,
    },
    props: true,
  },
  {
    path: '/request',
    component: AppRequestTable,
    meta: {
      layout: 'panel',
      auth: true,
    },
    props: true,
  },
  {
    path: '/request-schedule',
    component: AppScheduleReq,
    meta: {
      layout: 'panel',
      auth: true,
    },
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      const position = document.querySelector(to.hash).offsetTop
      return { selector: to.hash, top: position - 100, behavior: 'smooth' }
    }
    return { top: 0 }
  },
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
})

router.beforeEach((to, from, next) => {
  if (to.meta.layout == 'schedule') {
    store.commit('patchAdd', to.fullPath)
  }
  const requireAuth = to.meta.auth
  if (requireAuth && store.getters['isAuthenticated']) {
    next()
  } else if (requireAuth && !store.getters['isAuthenticated']) {
    next('/administrado/?message=auth')
  } else {
    next()
  }
})

export default router
