<template>
  <div></div>
</template>
<script>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
export default {
  setup() {
    const router = useRouter()
    onMounted(() => {
      router.push('/admins')
    })
  },
}
</script>
