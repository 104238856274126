<template>
  <div class="admin__box">
    <h1>Добавить/Исключить преподователя</h1>
    <div class="admin__box-table">
      <p class="admin__box-table-title title25">
        Список преподователей<a
          @click="addItem()"
          class="position__right-box one"
          >+</a
        >
      </p>
      <ul class="admin__box-th th-2 list2 teacher-schedule">
        <li class="admin__box-td-2 td-1">ID</li>
        <li class="admin__box-td-2 td-2">Имя/Фамилия/Отчество</li>
        <li class="admin__box-td-2 td-2">Ссылка на расписание</li>
      </ul>
      <ul
        v-for="(item, id, i) in teacher"
        :key="item"
        class="admin__box-td list2 teacher-schedule"
      >
        <li class="admin__box-td-2 td-1">{{ i + 1 }}</li>
        <li class="admin__box-td-2 td-2">{{ item.title }}</li>
        <li class="admin__box-td-2 td-2">
          https://rino-tlt.ru/schedule-teacher/{{ item.id }}
        </li>
        <li class="position-box">
          <a @click="deleteteacher(id)">
            <img src="../../assets/img/delete.svg"
          /></a>
          <a @click="edit(id, item.title)">
            <img width="15" src="../../assets/img/edit.svg" />
          </a>
        </li>
      </ul>
      <div class="modal" v-if="toog">
        <div class="modal__admin-box">
          <p class="admin__box-room">Имя/Фамилия/Отчество преподователя</p>
          <p class="admin__box-room2">
            <input
              class="modal__admin-select"
              type="text"
              v-model.lazy="title"
            />
            <small class="error" v-if="eError">{{ eError }}</small>
          </p>
          <div class="modal__admin-btn">
            <button @click="onSubmit" class="btn btn-1">
              {{ toogle ? 'Редактировать' : 'Добавить' }}
            </button>
          </div>
        </div>
      </div>
      <div v-if="toog" @click="close" class="overflow"></div>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex'
import { computed, ref, watch } from 'vue'
export default {
  setup() {
    const title = ref()
    const store = useStore()
    const toog = ref(false)
    const teacher = computed(() => store.state.teacher)
    const eError = ref()
    const toogle = ref(null)

    const r = computed(() => {
      if (teacher.value !== null) {
        return Object.keys(teacher.value).map((r) => teacher.value[r].id)
      } else {
        return false
      }
    })

    const maxId = computed(() => {
      if (r.value) {
        return Math.max.apply(null, r.value) + 1
      } else {
        return 0
      }
    })

    function deleteteacher(item) {
      store.dispatch('deleteTeacher', item)
    }

    function onSubmit() {
      if (!toogle.value) {
        if (title.value) {
          store.dispatch('addTeacher', {
            title: title.value,
            id: 'id' + Math.random().toString(16).slice(2),
          })
          toog.value = false
        } else {
          eError.value = 'Поле не должно быть пустым'
          setTimeout(() => {
            eError.value = ''
          }, 1000)
        }
      } else {
        if (title.value) {
          store.dispatch('editTeacher', {
            title: title.value,
            id: toogle.value,
          })
          toog.value = false
        } else {
          eError.value = 'Поле не должно быть пустым'
          setTimeout(() => {
            eError.value = ''
          }, 1000)
        }
      }
    }

    function addItem() {
      toogle.value = null
      toog.value = true
    }

    function close() {
      toog.value = false
    }

    function edit(id, item) {
      title.value = item
      toog.value = true
      toogle.value = id
    }

    watch(toog, (val) => {
      if (!val) {
        title.value = ''
        toogle.value = null
      }
    })

    return {
      toogle,
      edit,
      deleteteacher,
      onSubmit,
      teacher,
      title,
      maxId,
      r,
      addItem,
      close,
      toog,
      eError,
    }
  },
}
</script>
