export function parseDate(e) {
  const dd = e.slice(0, 2)
  const mm = e.slice(3, 5)
  const yyyy = e.slice(6, 10)

  const myDate = new Date(`${yyyy}-${mm}-${dd}`)
  const offset = myDate.getTimezoneOffset() * 60 * 1000
  const withOffset = myDate.getTime()
  const withoutOffset = withOffset - offset

  return withoutOffset / 1000
}
