<template>
  <div class="grid__schedule">
    <div class="head__schedule">
      <div class="head__schedule-box">
        <div class="head__schedule-title">
          <a class="head__schedule-logo"
            ><img src="../assets/img/logo2.png"
          /></a>
          <p>{{ title }}</p>
        </div>
        <div class="header-top-phone">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.837"
              height="17.869"
              viewBox="0 0 17.837 17.869"
            >
              <path
                d="M13.563,4.714a4.017,4.017,0,0,1,3.174,3.174M13.563,1.5a7.231,7.231,0,0,1,6.388,6.38m-.8,6.412V16.7A1.607,1.607,0,0,1,17.4,18.309a15.9,15.9,0,0,1-6.934-2.467,15.668,15.668,0,0,1-4.821-4.821A15.9,15.9,0,0,1,3.174,4.055,1.607,1.607,0,0,1,4.773,2.3h2.41A1.607,1.607,0,0,1,8.791,3.685a10.317,10.317,0,0,0,.562,2.258,1.607,1.607,0,0,1-.362,1.7l-1.02,1.02a12.856,12.856,0,0,0,4.821,4.821l1.02-1.02a1.607,1.607,0,0,1,1.7-.362,10.317,10.317,0,0,0,2.258.562A1.607,1.607,0,0,1,19.148,14.291Z"
                transform="translate(-2.667 -0.948)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              ></path>
            </svg>
          </div>
          <div>
            <p>
              <span>8 (902) 370-91-73</span>
            </p>
            <p>
              <span>8 (960) 842-42-10</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="content__schedule"><router-view></router-view></div>
    <footer class="ContactList footer__schedule" id="contact">
      <div class="content">
        <section class="copyright">
          ©2017–{{ new Date().getFullYear() }}, РИНО
        </section>
      </div>
    </footer>
  </div>
</template>
<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
export default {
  setup() {
    const store = useStore()
    const title = computed(() => store.state.titleChoosingSchedule)

    return { title }
  },
}
</script>
