<template>
  <div class="confirmation">
    <div class="title-confirmation">Хотите удалить заявку</div>
    <div class="confirmation-button">
      <button
        v-for="item in data"
        :key="item"
        class="btn"
        :class="item.color"
        @click="item.click"
      >
        {{ item.title }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  emits: ['deleteRequest', 'closeModal'],
  setup(_, { emit }) {
    const data = [
      {
        title: 'Отмена',
        color: 'green',
        click: close,
      },
      {
        title: 'Удалить',
        color: 'red',
        click: deleteSchedule,
      },
    ]

    function close() {
      emit('closeModal')
    }

    function deleteSchedule() {
      emit('deleteRequest')
    }

    return { data, close, deleteSchedule }
  },
}
</script>