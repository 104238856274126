<template>
  <div class="admin__box">
    <h1>Список администраторов</h1>

    <div class="admin__box-table">
      <ul class="admin__box-th th-2 list2">
        <li class="admin__box-td-2 td-1">ID</li>
        <li class="admin__box-td-2 td-2">Логин</li>
      </ul>
      <ul
        v-for="(user, id, p) in users"
        :key="user"
        class="admin__box-td list2"
      >
        <li class="admin__box-td-2 td-1">{{ p + 1 }}</li>
        <li class="admin__box-td-2 td-2">{{ user.email }}</li>
      </ul>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  setup() {
    const store = useStore()
    const users = computed(() => store.state.users)
    function deleteUser(item) {
      store.dispatch('deleteUser', item)
    }
    return { users, deleteUser }
  },
}
</script>
