<template>
  <div class="course__acardion">
    <div
      @click="toogle"
      :class="toog ? 'active' : null"
      class="course__acardion-title"
    >
      <span class="course__acardion-title-icon"></span
      ><span class="course__acardion-title-content"
        >Правила предоставления платных услуг</span
      >
    </div>
    <div v-if="toog" class="course__acardion-text">
      <span class="course__acardion-text-bold">
        Платные дополнительные образовательные услуги оказываются в следующем
        порядке:</span
      >
      <span class="course__acardion-text-small"
        >- изучение спроса на платные образовательные услуги, определение
        предполагаемого контингента и анализ материально-технической базы;</span
      >
      <span class="course__acardion-text-small"
        >- создание условий для оказания платных дополнительных образовательных
        услуг с учётом требований охраны труда и безопасности для жизни и
        здоровья;</span
      >
      <span class="course__acardion-text-small"
        >- доведение до законных представителей обучающихся информации об
        оказании платных дополнительных образовательных услугах и
        исполнителе.</span
      >
      <span class="course__acardion-text-bold"
        >Информация обеспечивает потребителям свободу и возможность собственного
        выбора и содержит следующие сведения:</span
      >
      <span class="course__acardion-text-small"
        >- наименование и местонахождение исполнителя, сведения о наличии
        лицензии на право ведения образовательной деятельности, перечень
        предлагаемых платных дополнительных образовательных услуг и порядок их
        предоставления;</span
      >
      <span class="course__acardion-text-small"
        >- направленность реализуемых дополнительных программ, формы и сроки их
        освоения;</span
      >
      <span class="course__acardion-text-small"
        >- порядок приёма (набора) и требования к потребителю (представителю
        потребителя) платных дополнительных образовательных услуг.</span
      >

      <span class="course__acardion-text-bold"
        >Договор на обучение по дополнительным образовательным программам
        заключается в простой письменной форме, которая содержит следующие
        сведения:</span
      >
      <span class="course__acardion-text-small"
        >- Ф.И.О. индивидуального предпринимателя;</span
      >
      <span class="course__acardion-text-small"
        >- местонахождение или место жительства исполнителя - индивидуального
        предпринимателя, номер телефона;</span
      >
      <span class="course__acardion-text-small"
        >- Ф.И.О. заказчика (его представителя), место жительства, номер
        телефона;</span
      >
      <span class="course__acardion-text-small"
        >- права, обязанности и ответственность сторон;</span
      >
      <span class="course__acardion-text-small"
        >- сведения о лицензии на осуществление образовательной
        деятельности;</span
      >
      <span class="course__acardion-text-small">- сведения об ОГРН;</span>
      <span class="course__acardion-text-small">- сведения ИНН;</span>
      <span class="course__acardion-text-small"
        >- направленность образовательной программы;</span
      >
      <span class="course__acardion-text-small">- форма обучения;</span>
      <span class="course__acardion-text-small"
        >- сроки освоения образовательной программы (продолжительность
        обучения);</span
      >
      <span class="course__acardion-text-small"
        >- порядок изменения и расторжения договора;</span
      >
      <span class="course__acardion-text-small"
        >- другие необходимые сведения, связанные со спецификой оказываемых
        платных образовательных услуг.</span
      >
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
export default {
  setup() {
    const toog = ref(false)

    function toogle() {
      toog.value = !toog.value
    }

    return { toog, toogle }
  },
}
</script>
