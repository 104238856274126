<template>
  <div class="course__acardion">
    <div
      @click="toogle"
      :class="toog ? 'active' : null"
      class="course__acardion-title"
    >
      <span class="course__acardion-title-icon"></span
      ><span class="course__acardion-title-content">Программы</span>
    </div>
    <div v-if="toog" class="course__acardion-text">
      <span class="course__acardion-text-bold">
        Дополнительная общеобразовательная общеразвивающая программа
        «Starters+»:
      </span>
      <div class="light-box">
        <p v-for="(item, i) in data" :key="item">
          <img width="70" @click="showMultiple(i)" :src="item.src" />
        </p>
      </div>
      <vue-easy-lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
      ></vue-easy-lightbox>
      <p>
        <a href="/programm3.pdf" download=""
          >Скачать - Дополнительная общеобразовательная общеразвивающая
          программа «Starters+»
        </a>
      </p>
      <!--
      <span class="course__acardion-text-small"
        >- подготовка детей к школе по авторской программе "Скоро в школу!"
        Кочеровой И.В и Илюхиной Е.В. с 5 лет;</span
      >
      <span class="course__acardion-text-small"
        >- подготовка детей к школе по авторской программе "Я готовлюсь к
        школе!" Кочеровой И.В и Илюхиной Е.В. с 6 лет;</span
      >
      <span class="course__acardion-text-small"
        >- английский язык с 5 лет “Kid,s Box 1”;</span
      >
      <span class="course__acardion-text-small"
        >- английский язык с 6 лет “Kid,s Box 2”;</span
      >
      <span class="course__acardion-text-small"
        >- английский язык с младшими школьниками “Kid,s Box Starter”
        (Издательство Кэмбридж);</span
      >
      <span class="course__acardion-text-small"
        >- логопедические занятия (в форме индивидуальных консультаций с
        ребенком).</span
      >-->
    </div>
  </div>
</template>
<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import { ref } from 'vue'
export default {
  components: {
    VueEasyLightbox,
  },
  setup() {
    const visible = ref(false)
    const imgs = ref('')
    const index = ref(0)

    const data = [
      {
        src: `${require('../../../assets/img/document/programm/programma3.jpg')}`,
      },
    ]
    const toog = ref(false)

    function showMultiple(e) {
      imgs.value = data

      index.value = e
      show()
    }

    function show() {
      visible.value = true
    }

    function handleHide() {
      visible.value = false
    }

    function toogle() {
      toog.value = !toog.value
    }

    return {
      toog,
      toogle,
      visible,
      imgs,
      index,
      data,
      showMultiple,
      handleHide,
      show,
    }
  },
}
</script>
