<template>
  <header
    class="PageTitle"
    :class="styleClass ? styleClass : null"
    :style="
      img
        ? {
            backgroundImage: `url(${require(`../../assets/img/${img}`)})`,
          }
        : null
    "
  >
    <p v-if="title" class="PageTitle__title">{{ title }}</p>
    <p v-if="subtitle" class="PageTitle__subtitle">{{ subtitle }}</p>
    <p v-if="text" class="PageTitle__subtitle">{{ text }}</p>
    <p v-if="subText" class="PageTitle__subtitle">{{ subText }}</p>
    <p v-if="subText2" class="PageTitle__subtitle">{{ subText2 }}</p>
    <p v-if="subText3" class="PageTitle__subtitle">{{ subText3 }}</p>
    <p v-if="btn" class="PageTitle__btn">
      <router-link :to="{ path: '/', hash: '#courses' }"
        >Наши курсы</router-link
      >
    </p>
  </header>
</template>
<script>
export default {
  props: [
    'title',
    'subtitle',
    'text',
    'btn',
    'img',
    'styleClass',
    'subText',
    'subText2',
    'subText3'
  ],
}
</script>
