<template>
  <div class="AdminIndex">
    <app-preloader v-if="preload"></app-preloader>
    <div class="AdminPanel">
      <div class="topbar header__topbar">
        <section class="AdminTitlebar header__admin">
          <div class="header__admin-logo">
            <img src="../assets/img/logo2.png" />
            <span>Панель администратора</span>
          </div>
          <div class="header__admin-user">
            <p>
              <span><img src="../assets/img/user.svg" /></span
              ><span>contact@rino-tlt.ru</span>
            </p>
            <ul>
              <li><button @click="logOut" class="PushBtn _ico">Q</button></li>
            </ul>
          </div>
        </section>
      </div>
      <div class="content">
        <div class="sidebar sidebar__admin" :class="slide ? '-hidden' : ''">
          <button @click="toogle" class="toggle" id="sidebar-toggle"></button>
          <nav class="AdminSidebar" role="navigation">
            <ul class="AdminMenu acardion__admin">
              <li class="item" :class="acc1 ? '' : '-collapse'">
                <a @click.stop="tog(1)" class="href -item-toggle" href="#"
                  >Администраторы</a
                >
                <ul class="AdminMenu">
                  <li class="item acardion__admin-item">
                    <router-link class="href" to="/admins"
                      >Показать список</router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="item" :class="acc2 ? '' : '-collapse'">
                <a @click="tog(2)" class="href -item-toggle" href="#"
                  >Расписание</a
                >
                <ul class="AdminMenu">
                  <li class="item acardion__admin-item">
                    <router-link class="href" to="/add-school-year/add"
                      >Добавить учебный год</router-link
                    >
                  </li>

                  <li
                    @click="addYear(item)"
                    v-for="item in dataSchedule"
                    :key="item"
                    class="item ancor acardion__admin-item"
                  >
                    <router-link
                      class="href"
                      :to="{ path: `/add-school-year/${item}` }"
                      >{{ item }}-{{ `${Number(item) + 1}` }}</router-link
                    ><span class="delete-course" @click="deleteCourse(item)"
                      >удалить</span
                    >
                  </li>
                </ul>
              </li>
              <li class="item" :class="acc3 ? '' : '-collapse'">
                <a @click="tog(3)" class="href -item-toggle"
                  >Электронная запись</a
                >
                <ul class="AdminMenu">
                  <li class="item acardion__admin-item">
                    <router-link class="href" to="/request"
                      >Список заявок</router-link
                    >
                  </li>
                  <li class="item acardion__admin-item">
                    <router-link class="href" to="/requestschedule"
                      >Сформированное расписание</router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="item" :class="acc4 ? '' : '-collapse'">
                <a @click="tog(4)" class="href -item-toggle" href="#"
                  >Настройки</a
                >
                <ul class="AdminMenu">
                  <li class="item acardion__admin-item">
                    <router-link class="href" to="/room"
                      >Добавить/Удалить кабинеты</router-link
                    >
                  </li>
                  <li class="item acardion__admin-item">
                    <router-link class="href" to="/courses"
                      >Добавить/Удалить курсы</router-link
                    >
                  </li>
                  <li class="item acardion__admin-item">
                    <router-link class="href" to="/teacher"
                      >Добавить/Исключить преподователя</router-link
                    >
                  </li>
                  <li class="item acardion__admin-item">
                    <router-link class="href" to="/address"
                      >Добавить/Исключить адрес</router-link
                    >
                  </li>
                  <li class="item acardion__admin-item">
                    <router-link class="href" to="/duration-classes"
                      >Добавить/Исключить продолжительность занятий</router-link
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
        <div class="main admin__main">
          <section class="AdminContent"><router-view></router-view></section>
        </div>
      </div>
    </div>
    <app-modal @close="closeModal" :year="yeardelete" v-if="toggle"></app-modal>
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import AppModal from '../components/ui/AppModal.vue'
import AppPreloader from '../components/ui/AppPreloader.vue'
export default {
  components: { AppModal, AppPreloader },
  setup() {
    const yeardelete = ref('')
    const toggle = ref(false)
    const slide = ref(false)
    const router = useRouter()
    const store = useStore()
    const acc1 = ref(false)
    const acc2 = ref(false)
    const acc3 = ref(false)
    const acc4 = ref(false)

    const preload = computed(() => store.state.preload)

    const patchEmail = localStorage.getItem('patch')

    onMounted(() => {
      store.dispatch('schedule')
      store.dispatch('getRoom')
      store.dispatch('getoffice')
      store.dispatch('getTeacher')
      store.dispatch('getAddress')
      store.dispatch('getUser')
      store.dispatch('getclasses')
      store.dispatch('getclasses')
      if (patchEmail == 'teacher@rino-tlt.ru') {
        router.push('/')
      }
    })

    const dataSchedule = computed(() => Object.keys(store.state.scheduleData))

    function addYear(item) {
      store.commit('addyear', item)
    }

    function logOut() {
      store.commit('logout')
      router.push('/')
    }

    function toogle() {
      slide.value = !slide.value
    }
    function tog(item) {
      if (item === 1) {
        acc1.value = !acc1.value
      }
      if (item === 2) {
        acc2.value = !acc2.value
      }
      if (item === 3) {
        acc3.value = !acc3.value
      }
      if (item === 4) {
        acc4.value = !acc4.value
      }
    }

    function deleteCourse(item) {
      yeardelete.value = item
      toggle.value = true
    }

    function closeModal() {
      toggle.value = false
    }

    return {
      closeModal,
      deleteCourse,
      logOut,
      slide,
      toogle,
      tog,
      acc1,
      acc2,
      acc3,
      dataSchedule,
      addYear,
      toggle,
      yeardelete,
      acc4,
      preload,
      patchEmail,
    }
  },
}
</script>
