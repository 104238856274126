<template>
  <div class="admin__box">
    <template v-if="idTeacher.length !== 0">
      <h1
        class="admin__box-teacher-title"
        v-for="item in idTeacher"
        :key="item"
      >
        {{ item.title }}
      </h1>
    </template>
    <template v-else>
      <h1 class="admin__box-teacher-title">Не найден</h1>
    </template>
    <div class="admin__box-table request">
      <ul class="admin__box-th grid th-9 r-s-filter">
        <li class="admin__box-td-9 td9-1">Учебный год</li>
        <li class="admin__box-td-9 td9-2">Каб.</li>
        <li class="admin__box-td-9 td9-3">Время</li>
        <li class="admin__box-td-9 td9-4">Название</li>
        <li class="admin__box-td-9 td9-5">ФИО ребенка</li>
        <li class="admin__box-td-9 td9-6">ФИО представителя</li>
        <li class="admin__box-td-9 td9-7">Контактный телефон</li>
        <li class="admin__box-td-9 td9-8">Педагог</li>
        <li class="admin__box-td-9 td9-9">Адрес</li>
      </ul>
      <template v-if="data1.length !== 0">
        <p class="admin__box-table-title style12">Понедельник</p>
        <vue-custom-scrollbar class="scroll-area" :settings="settings">
          <app-request-schedule-td
            :rid="item.rid"
            :sid="item.sid"
            v-for="item in data1"
            :key="item"
          ></app-request-schedule-td>
        </vue-custom-scrollbar>
      </template>

      <template v-if="data2.length !== 0">
        <p class="admin__box-table-title style12">Вторник</p>
        <vue-custom-scrollbar class="scroll-area" :settings="settings">
          <app-request-schedule-td
            :rid="item.rid"
            :sid="item.sid"
            v-for="item in data2"
            :key="item"
          ></app-request-schedule-td>
        </vue-custom-scrollbar>
      </template>

      <template v-if="data3.length !== 0">
        <p class="admin__box-table-title style12">Среда</p>
        <vue-custom-scrollbar class="scroll-area" :settings="settings">
          <app-request-schedule-td
            :rid="item.rid"
            :sid="item.sid"
            v-for="item in data3"
            :key="item"
          ></app-request-schedule-td>
        </vue-custom-scrollbar>
      </template>

      <template v-if="data4.length !== 0">
        <p class="admin__box-table-title style12">Четверг</p>
        <vue-custom-scrollbar class="scroll-area" :settings="settings">
          <app-request-schedule-td
            :rid="item.rid"
            :sid="item.sid"
            v-for="item in data4"
            :key="item"
          ></app-request-schedule-td>
        </vue-custom-scrollbar>
      </template>

      <template v-if="data5.length !== 0">
        <p class="admin__box-table-title style12">Пятница</p>
        <vue-custom-scrollbar class="scroll-area" :settings="settings">
          <app-request-schedule-td
            :rid="item.rid"
            :sid="item.sid"
            v-for="item in data5"
            :key="item"
          ></app-request-schedule-td>
        </vue-custom-scrollbar>
      </template>

      <template v-if="data6.length !== 0">
        <p class="admin__box-table-title style12">Суббота</p>
        <vue-custom-scrollbar class="scroll-area" :settings="settings">
          <app-request-schedule-td
            :rid="item.rid"
            :sid="item.sid"
            v-for="item in data6"
            :key="item"
          ></app-request-schedule-td>
        </vue-custom-scrollbar>
      </template>

      <template v-if="data7.length !== 0">
        <p class="admin__box-table-title style12">Воскресенье</p>
        <vue-custom-scrollbar class="scroll-area" :settings="settings">
          <app-request-schedule-td
            :rid="item.rid"
            :sid="item.sid"
            v-for="item in data7"
            :key="item"
          ></app-request-schedule-td>
        </vue-custom-scrollbar>
      </template>
    </div>
  </div>
</template>
<script>
import { useRouter } from 'vue-router'
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import vueCustomScrollbar from '../../../node_modules/vue-custom-scrollbar/src/vue-scrollbar'
import AppRequestScheduleTd from './requestSchedule/AppRequestScheduleTd.vue'
export default {
  components: { AppRequestScheduleTd, vueCustomScrollbar },
  setup() {
    const store = useStore()
    const settings = {
      suppressScrollY: false,
      suppressScrollX: true,
      wheelPropagation: false,
      maxScrollbarLength: 150,
      minScrollbarLength: 50,
    }
    onMounted(() => {
      store.dispatch('getTeacher')
      store.dispatch('getRoom')
      store.dispatch('getRequestSchedule', new Date().getFullYear())
      store.dispatch('Request', new Date().getFullYear())
      store.dispatch('schedule2', new Date().getFullYear())
    })
    const router = useRouter()
    const idTeacher = computed(() => {
      if (store.state.teacher) {
        return Object.keys(store.state.teacher)
          .map((r) => store.state.teacher[r])
          .filter((s) => s.id == router.currentRoute._rawValue.params.id)
      } else {
        return []
      }
    })

    const data = computed(() => {
      if (store.state.requestSchedule == null) {
        return []
      } else {
        return [].concat.apply(
          [],
          Object.keys(store.state.requestSchedule).map((r) => {
            if (r) {
              return Object.keys(store.state.requestSchedule[r]).map(
                (s) => store.state.requestSchedule[r][s]
              )
            }
          })
        )
      }
    })

    const dataS = computed(() =>
      data.value.reduce((a, b) => ((a[b.rid] = b), a), {})
    )

    const schedule = computed(() => {
      if (store.state.scheduleData2 == null) {
        return []
      } else {
        return Object.keys(store.state.scheduleData2)
          .map((r) => store.state.scheduleData2[r])[0]
          .reduce((a, b) => ((a[b.id] = b), a), {})
      }
    })
    const request = computed(() => {
      if (store.state.request == null) {
        return {}
      } else {
        return Object.keys(store.state.request)
          .map((r) => store.state.request[r])
          .reduce((a, b) => ((a[b.id] = b), a), {})
      }
    })

    const formedData = computed(() =>
      data.value
        .map((r) => {
          return {
            rid: request.value[r.rid],
            sid: schedule.value[r.sid],
          }
        })
        .filter((req) => req.rid !== undefined)
        .filter((request) => {
          if (idTeacher.value.length !== 0) {
            if (request.sid) {
              if (request.sid.teacher) {
                return request.sid.teacher.includes(idTeacher.value[0].title)
              } else {
                return request.sid.title.includes(idTeacher.value[0].title)
              }
            }
          }
          return request
        })
    )

    const data1 = computed(() =>
      formedData.value
        .filter((r) => (r.sid ? r.sid.dow == 1 : r))
        .filter((r) => r.sid !== undefined)
    )
    const data2 = computed(() =>
      formedData.value
        .filter((r) => (r.sid ? r.sid.dow == 2 : r))
        .filter((r) => r.sid !== undefined)
    )

    const data3 = computed(() =>
      formedData.value
        .filter((r) => (r.sid ? r.sid.dow == 3 : r))
        .filter((r) => r.sid !== undefined)
    )
    const data4 = computed(() =>
      formedData.value
        .filter((r) => (r.sid ? r.sid.dow == 4 : r))
        .filter((r) => r.sid !== undefined)
    )

    const data5 = computed(() =>
      formedData.value
        .filter((r) => (r.sid ? r.sid.dow == 5 : r))
        .filter((r) => r.sid !== undefined)
    )

    const data6 = computed(() =>
      formedData.value
        .filter((r) => (r.sid ? r.sid.dow == 6 : r))
        .filter((r) => r.sid !== undefined)
    )

    const data7 = computed(() =>
      formedData.value
        .filter((r) => (r.sid ? r.sid.dow == 7 : r))
        .filter((r) => r.sid !== undefined)
    )

    return {
      data,
      idTeacher,
      dataS,
      request,
      schedule,
      formedData,
      data1,
      data2,
      data3,
      data4,
      data5,
      data6,
      data7,
      settings,
    }
  },
}
</script>
