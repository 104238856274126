<template>
  <div class="course__acardion">
    <div
      @click="toogle"
      :class="toog ? 'active' : null"
      class="course__acardion-title"
    >
      <span class="course__acardion-title-icon"></span
      ><span class="course__acardion-title-content">Документы</span>
    </div>
    <div v-if="toog" class="course__acardion-text">
      <span class="course__acardion-text-small">1. ИНН 632101821500 </span>
      <div class="light-box">
        <p>
          <img
            @click="showMultiple(9)"
            src="../../../assets/img/document/ogrn/002s.jpg"
          />
        </p>
      </div>
      <span class="course__acardion-text-small"
        >2. ОГРНИП 308632024900074
      </span>
      <div class="light-box">
        <p>
          <img
            @click="showMultiple(8)"
            src="../../../assets/img/document/ogrn/001s.jpg"
          />
        </p>
      </div>
      <span class="course__acardion-text-small">3. Выписка из ЕГРИП</span>
      <div class="light-box">
        <p>
          <img
            @click="showMultiple(35)"
            src="../../../assets/img/document/egrip/001s.jpg"
          />
        </p>
        <p>
          <img
            @click="showMultiple(36)"
            src="../../../assets/img/document/egrip/002s.jpg"
          />
        </p>
        <p>
          <img
            @click="showMultiple(37)"
            src="../../../assets/img/document/egrip/003s.jpg"
          />
        </p>
        <p>
          <img
            @click="showMultiple(38)"
            src="../../../assets/img/document/egrip/004s.jpg"
          />
        </p>
      </div>

      <span class="course__acardion-text-small"
        >4. Лицензия на право осуществления образовательной деятельности рег. №
        7403 от 02 сентября 2019 года, выданной Министерством образования и
        науки Самарской области на основании приказа №189-л от 02 сентября 2019
        года.</span
      >
      <div class="light-box">
        <p>
          <img
            @click="showMultiple(0)"
            src="../../../assets/img/document/licensi/001s.jpg"
          />
        </p>
        <p>
          <img
            @click="showMultiple(1)"
            src="../../../assets/img/document/licensi/002s.jpg"
          />
        </p>
        <p>
          <img
            @click="showMultiple(2)"
            src="../../../assets/img/document/licensi/003s.jpg"
          />
        </p>
      </div>


      <vue-easy-lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
      ></vue-easy-lightbox>
      <span class="course__acardion-text-small"
        >5. Свидетельство на Товарный знак (знак обслуживания №794771,
        зарегистрированный в Государственном реестре товарных знаков и знаков
        обслуживания Российской Федерации 27 января 2021 года).</span
      >
      <div class="light-box">
        <p>
          <img
            @click="showMultiple(3)"
            src="../../../assets/img/document/svidetelstvo/001s.jpg"
          />
        </p>
      </div>
      <span class="course__acardion-text-small"
        >6. Свидетельства № 30, 31 от 16.03.2020 г. о регистрации произведений -
        объектов интеллектуальной собственности, зарегистрированные Патентным
        поверенным Российской Федерации (рег. № 188), Евразийским патентным
        поверенным (рег. № 109) Голиковым А.П.</span
      >
      <div class="light-box">
        <p>
          <img
            @click="showMultiple(4)"
            src="../../../assets/img/document/svidetelstvo/002s.jpg"
          />
        </p>
        <p>
          <img
            @click="showMultiple(5)"
            src="../../../assets/img/document/svidetelstvo/003s.jpg"
          />
        </p>
        <p>
          <img
            @click="showMultiple(6)"
            src="../../../assets/img/document/svidetelstvo/004s.jpg"
          />
        </p>
        <p>
          <img
            @click="showMultiple(7)"
            src="../../../assets/img/document/svidetelstvo/005s.jpg"
          />
        </p>
      </div>
      <span class="course__acardion-text-small"
        >7. Санитарно-эпидемиологическое заключение № 63.СЦ.000.М.000377.03.19
        от 22 марта 2019 года.</span
      >
      <div class="light-box">
        <p>
          <img
            @click="showMultiple(11)"
            src="../../../assets/img/document/ses/002s.jpg"
          />
        </p>
      </div>
      <span class="course__acardion-text-small"
        >8. Заключение о соответствии объекта защиты обязательным требованиям
        пожарной безопасности № 003195 от 16 мая 2019 года.</span
      >
      <div class="light-box">
        <p>
          <img
            @click="showMultiple(10)"
            src="../../../assets/img/document/ses/001s.jpg"
          />
        </p>
      </div>
      <span class="course__acardion-text-small"
        >9. Отчёт о проведении специальной оценки условий труда.
        Идентификационный номер СОУТ: 368065 от 02.07.2021 г.</span
      >

      <AppSout></AppSout>

      <span class="course__acardion-text-small"
        >10. Правила внутреннего трудового распорядка.</span
      >
      <div class="light-box">
        <p>
          <img
            @click="showMultiple(12)"
            src="../../../assets/img/document/pravilo/001s.jpg"
          />
        </p>
        <p>
          <img
            @click="showMultiple(13)"
            src="../../../assets/img/document/pravilo/002s.jpg"
          />
        </p>
        <p>
          <img
            @click="showMultiple(14)"
            src="../../../assets/img/document/pravilo/003s.jpg"
          />
        </p>
        <p>
          <img
            @click="showMultiple(15)"
            src="../../../assets/img/document/pravilo/004s.jpg"
          />
        </p>
      </div>
      <span class="course__acardion-text-small"
        >11. Инструкция по охране труда
      </span>
      <div class="light-box">
        <p>
          <img
            @click="showMultiple(22)"
            src="../../../assets/img/document/instrukcija/001s.jpg"
          />
        </p>
        <p>
          <img
            @click="showMultiple(23)"
            src="../../../assets/img/document/instrukcija/002s.jpg"
          />
        </p>
        <p>
          <img
            @click="showMultiple(24)"
            src="../../../assets/img/document/instrukcija/003s.jpg"
          />
        </p>
      </div>
      <span class="course__acardion-text-small"
        >12. Приказ об утверждении Политики в отношении обработки и защиты
        персональных данных.</span
      >
      <div class="light-box">
        <p>
          <img
            @click="showMultiple(20)"
            src="../../../assets/img/document/persdanie/001s.jpg"
          />
        </p>
      </div>
      <span class="course__acardion-text-small"
        >13. Положение в отношении обработки и защиты персональных данных.</span
      >

      <div class="light-box">
        <p>
          <img
            @click="showMultiple(25)"
            src="../../../assets/img/document/personalnie-danie/001s.jpg"
          />
        </p>
        <p>
          <img
            @click="showMultiple(26)"
            src="../../../assets/img/document/personalnie-danie/002s.jpg"
          />
        </p>
        <p>
          <img
            @click="showMultiple(27)"
            src="../../../assets/img/document/personalnie-danie/003s.jpg"
          />
        </p>
        <p>
          <img
            @click="showMultiple(28)"
            src="../../../assets/img/document/personalnie-danie/004s.jpg"
          />
        </p>
        <p>
          <img
            @click="showMultiple(29)"
            src="../../../assets/img/document/personalnie-danie/005s.jpg"
          />
        </p>
      </div>

      <span class="course__acardion-text-small"
        >14. Образец договора об оказании платных образовательных услуг .</span
      >
      <p>
        <a :href="`${publicPath}dogovor.docx`" download
          >Скачать - Договор – оферта об оказании образовательных услуг
        </a>
      </p>
      <div class="light-box">
        <p>
          <img
            @click="showMultiple(18)"
            src="../../../assets/img/document/dogovor/001s.jpg"
          />
        </p>
        <p>
          <img
            @click="showMultiple(19)"
            src="../../../assets/img/document/dogovor/002s.jpg"
          />
        </p>
      </div>
      <span class="course__acardion-text-small"
        >15. Соглашение о конфиденциальности информации.</span
      >
      <div class="light-box">
        <p>
          <img
            @click="showMultiple(30)"
            src="../../../assets/img/document/soglashenie/001s.jpg"
          />
        </p>
        <p>
          <img
            @click="showMultiple(31)"
            src="../../../assets/img/document/soglashenie/002s.jpg"
          />
        </p>
        <p>
          <img
            @click="showMultiple(32)"
            src="../../../assets/img/document/soglashenie/003s.jpg"
          />
        </p>
        <p>
          <img
            @click="showMultiple(33)"
            src="../../../assets/img/document/soglashenie/004s.jpg"
          />
        </p>
        <p>
          <img
            @click="showMultiple(34)"
            src="../../../assets/img/document/soglashenie/005s.jpg"
          />
        </p>
      </div>
      <span class="course__acardion-text-small"
        >16. Прайс-лист стоимости обучения по каждой образовательной программе.
        В данном прайсе представлена справочная информация наиболее популярных
        позиций.</span
      >
      <p>
        <a :href="`${publicPath}price.pdf`" download
          >Скачать - Прейскурант цен РИНО 2024
        </a>
      </p>
      <div class="light-box">
        <p>
          <img
            @click="showMultiple(21)"
            src="../../../assets/img/document/prais/001s.jpg"
          />
        </p>
      </div>
      <span class="course__acardion-text-small"
        >17. Правила приема и посещения занятий.</span
      >
      <p>
        <a :href="`${publicPath}pravilo.docx`" download
          >Скачать - Правила посещения в Центре
        </a>
      </p>
      <div class="light-box">
        <p>
          <img
            @click="showMultiple(16)"
            src="../../../assets/img/document/pravilo2/001s.jpg"
          />
        </p>
        <p>
          <img
            @click="showMultiple(17)"
            src="../../../assets/img/document/pravilo2/002s.jpg"
          />
        </p>
      </div>
      <p><a href="/rospotrebnadzor.pdf" download="">Скачать - Санитарно-эпидемиологическое заключение </a></p>
      <p><a href="/additionalagreement.docx" download="">Скачать - ДОПОЛНИТЕЛЬНОЕ СОГЛАШЕНИЕ </a></p>
      <p><a href="/extractfromtheregister.pdf" download="">Скачать - Выписка из реестра лицензий по состоянию на: 09:34 «25» сентября 2023г. </a></p>
    </div>
  </div>
</template>
<script>
import AppSout from './AppSout.vue'
import VueEasyLightbox from 'vue-easy-lightbox'
import { ref } from 'vue'
export default {
  data: () => ({ publicPath: process.env.BASE_URL }),
  components: {
    VueEasyLightbox,
    AppSout,
  },
  setup() {
    const visible = ref(false)
    const imgs = ref('')
    const index = ref(0)

    function showMultiple(e) {
      imgs.value = [
        { src: `${require('../../../assets/img/document/licensi/001.jpg')}` },
        { src: `${require('../../../assets/img/document/licensi/002.jpg')}` },
        { src: `${require('../../../assets/img/document/licensi/003.jpg')}` },
        {
          src: `${require('../../../assets/img/document/svidetelstvo/001.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/svidetelstvo/002.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/svidetelstvo/003.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/svidetelstvo/004.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/svidetelstvo/005.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/ogrn/001.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/ogrn/002.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/ses/001.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/ses/002.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/pravilo/001.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/pravilo/002.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/pravilo/003.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/pravilo/004.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/pravilo2/001.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/pravilo2/002.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/dogovor/001.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/dogovor/002.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/persdanie/001.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/prais/001.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/instrukcija/001.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/instrukcija/002.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/instrukcija/003.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/personalnie-danie/001.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/personalnie-danie/002.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/personalnie-danie/003.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/personalnie-danie/004.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/personalnie-danie/005.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/soglashenie/001.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/soglashenie/002.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/soglashenie/003.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/soglashenie/004.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/soglashenie/005.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/egrip/001.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/egrip/002.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/egrip/003.jpg')}`,
        },
        {
          src: `${require('../../../assets/img/document/egrip/004.jpg')}`,
        },
      ]

      index.value = e
      show()
    }

    function show() {
      visible.value = true
    }

    function handleHide() {
      visible.value = false
    }

    const toog = ref(false)
    function toogle() {
      toog.value = !toog.value
    }

    return {
      toog,
      toogle,
      visible,
      imgs,
      index,
      handleHide,
      show,
      showMultiple,
    }
  },
}
</script>
