<template>
  <ul :id="'r' + i" class="admin__box-td grid td-9">
    <li class="admin__box-td-9 td9-1">{{ i + 1 }}</li>
    <li class="admin__box-td-9 td9-2">{{ item.year }}</li>
    <li class="admin__box-td-9 td9-3">{{ item.name }}</li>
    <li class="admin__box-td-9 td9-4">
      {{ dataFormat(item.birthdate) }}
    </li>
    <li class="admin__box-td-9 td9-5">{{ item.agent }}</li>
    <li class="admin__box-td-9 td9-6">{{ item.phone }}</li>
    <li class="admin__box-td-9 td9-7">{{ dataFormat(item.appldate) }}</li>
    <li class="admin__box-td-9 td9-8">
      <template v-for="(i, n) in requestType(item.type)" :key="i">
        {{ i }}
        <template v-if="requestType(item.type).length !== n + 1"> + </template>
      </template>
    </li>
    <li class="admin__box-td-9 td9-9">
      <p class="admin__box-flex">
        <span
          ><input
            :disabled="!togg"
            type="checkbox"
            :checked="statusNow"
            v-model="req"
          />
        </span>
        <span
          @click="
            GoSchedule({
              subid: id,
              id: item.id,
              key: item.phone,
              name: item.name,
              type: item.type,
              time: item.year,
            })
          "
          v-if="item.choice"
        >
          <img width="18" src="../../../assets/img/edit_icon.svg" />
        </span>
      </p>
    </li>
    <li class="position-box">
      <a @click="editTd"><img src="../../../assets/img/edit.svg" /></a
      ><a @click="deleteItem()"><img src="../../../assets/img/delete.svg" /></a>
    </li>
  </ul>
</template>
<script>
import { dataFormat } from '../../../utils/dateformat'
import { requestType } from '../../../utils/requestType'
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { inject } from 'vue'
export default {
  emits: ['EditTd', 'deleterequestitem'],
  props: ['item', 'i', 'id'],
  setup(props, { emit }) {
    const router = useRouter()
    const store = useStore()
    const req = ref()
    const cryoptojs = inject('cryptojs')
    const key = cryoptojs.AES.encrypt(props.item.phone, 'Secret Passphrase')
    const key2 = cryoptojs.AES.decrypt(
      key.toString(),
      'Secret Passphrase'
    ).toString(cryoptojs.enc.Utf8)

    function editTd() {
      emit('EditTd', { id: props.id, data: props.item })
    }

    function deleteItem() {
      emit('deleterequestitem', {
        year: props.item.year,
        id: props.id,
        choice: props.item.choice,
        status: props.item.status,
      })
    }

    const statusNow = computed(() => {
      if (props.item.status == 0) {
        return false
      } else {
        return true
      }
    })

    const togg = computed(() => store.state.statusInput)

    watch(req, (val) => {
      if (val) {
        store.commit('inputStatus', false)
        store.dispatch('requestStatus', {
          date: props.item.year,
          id: props.id,
          status: 1,
          data: {
            subid: props.id,
            id: props.item.id,
            key: key.toString(),
            type: props.item.type,
            name: props.item.name,
          },
        })
      } else {
        store.commit('inputStatus', false)
        store.dispatch('requestStatus', {
          date: props.item.year,
          id: props.id,
          status: 0,
          data: {
            year: props.item.year,
            id: props.item.status2,
          },
        })
      }
    })

    function GoSchedule(item) {
      localStorage.setItem('listschedule', JSON.stringify(item))
      router.push('/request-schedule')
    }

    return {
      req,
      dataFormat,
      requestType,
      editTd,
      deleteItem,
      cryoptojs,
      key,
      key2,
      togg,
      statusNow,
      GoSchedule,
    }
  },
}
</script>
