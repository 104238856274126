<template>
  <div class="choosing__title-box">
    <div class="choosing__schedule">
      <h1>{{ data.name }}</h1>
      <router-link :to="ancorData.link">
        <button type="button" class="btn btn-1">
          {{ ancorData.title }}
        </button>
      </router-link>
    </div>
    <app-info-block v-if="toog" :text="textSchedule"></app-info-block>
    <app-choosing-filter v-model="filter"></app-choosing-filter>
  </div>
  <div class="choosing__overflofx">
    <app-choosing-td
      :room="roomdata"
      :title="item"
      v-for="item in 7"
      :key="item"
      :data="scheduleFilter"
      :id="data.id"
      :requestSchedule="requestSchedule"
      :subid="data.subid"
    ></app-choosing-td>
  </div>
</template>
<script>
import AppChoosingFilter from '../components/choosingSchedule/AppChoosingFilter.vue'
import AppChoosingTd from '../components/choosingSchedule/AppChoosingTd.vue'
import { onMounted, computed, watch, ref } from 'vue'
import { useStore } from 'vuex'
import { requestType } from '../utils/requestType'
import AppInfoBlock from './ui/AppInfoBlock.vue'
import { useRouter } from 'vue-router'
import { arrLenght } from '../utils/arrlenght'
export default {
  props: ['ancor'],
  components: {
    AppChoosingTd,
    AppInfoBlock,
    AppChoosingFilter,
  },
  setup(props) {
    const data = JSON.parse(localStorage.getItem('listschedule'))
    const store = useStore()

    const filter = ref([])

    const itemDate = computed(() => {
      if (data.time) {
        return data.time
      } else {
        return new Date().getFullYear()
      }
    })

    onMounted(() => {
      store.commit('addChoosingTitle', { title: 'Выбор расписания' })
      store.dispatch('getRoom')
      store.dispatch('schedule2', itemDate.value)
      store.dispatch('getRequestSchedule', itemDate.value)
      store.dispatch('getStatus', new Date().getFullYear())
    })

    const roomdata = computed(() =>
      Object.keys(store.state.room).map((r) => store.state.room[r])
    )

    const dataIf = computed(() => store.state.status)

    const router = useRouter()
    const routerIF = computed(() => {
      if (router.currentRoute._rawValue.fullPath == '/choosing-a-schedule') {
        return true
      } else {
        return false
      }
    })

    watch(dataIf, (val) => {
      if (!val && routerIF) {
        router.push('/')
      }
    })

    const requestScheduleAll = computed(() => store.state.requestSchedule)

    const re = computed(() => {
      if (itemDate.value == new Date().getFullYear()) {
        if (store.state.requestSchedule) {
          return store.state.requestSchedule[data.subid]
        } else {
          return {}
        }
      } else {
        return store.state.requestSchedule
      }
    })

    const requestSchedule = computed(() => {
      if (re.value) {
        return Object.keys(re.value)
          .map((r) => {
            return {
              id: r,
              rid: re.value[r].rid,
              sid: re.value[r].sid,
              type: re.value[r].type,
            }
          })
          .filter((r) => r.rid == data.id)
      } else {
        return []
      }
    })

    const ancorData = computed(() => {
      if (props.ancor) {
        return props.ancor
      } else {
        return { link: '/', title: 'На главную' }
      }
    })

    watch(requestSchedule, (val) => {
      if (itemDate.value == new Date().getFullYear())
        if (val.length == 0) {
          store.dispatch('requestPutchose', {
            id: data.subid,
            status: false,
          })
        }
    })

    const textSchedule = {
      title: '',
      style: 'warning-box-green pd',
      text: 'Сохранено',
      text2: '',
    }

    const toog = computed(() => store.state.statusInput2)

    const schedule = computed(() => {
      if (store.state.scheduleData2) {
        return Object.keys(store.state.scheduleData2).map(
          (r) => store.state.scheduleData2[r]
        )[0]
      } else {
        return []
      }
    })

    const scheduleFilter = computed(() => {
      if (schedule.value.length !== 0) {
        return [].concat
          .apply(
            [],
            requestType(data.type).map((r) =>
              schedule.value.filter((s) => requestType(s.type) == r)
            )
          )
          .filter((request) => {
            if (requestScheduleAll.value) {
              if (requestSchedule.value.length !== 0) {
                if (
                  requestSchedule.value.filter((req) => req.sid == request.id)
                    .length > 0
                ) {
                  return request
                } else {
                  return (
                    request.capacity >
                    arrLenght(requestScheduleAll.value, request)
                  )
                }
              } else {
                return (
                  request.capacity >
                  arrLenght(requestScheduleAll.value, request)
                )
              }
            } else {
              return request
            }
          })
          .filter((request) => {
            if (filter.value.title) {
              return request.title.includes(filter.value.title)
            }
            return request
          })
          .filter((request) => {
            if (filter.value.adress) {
              return request.address.includes(filter.value.adress)
            }
            return request
          })
      } else {
        return []
      }
    })

    return {
      requestScheduleAll,
      filter,
      data,
      schedule,
      scheduleFilter,
      textSchedule,
      toog,
      ancorData,
      itemDate,
      requestType,
      requestSchedule,
      dataIf,
      routerIF,
      roomdata,
      re,
      arrLenght,
    }
  },
}
</script>
