<template>
  <div class="_grid -groove">
    <div class="_grid-cell">
      <form id="admin-add-admin" class="TileBox" @submit.prevent="onSubmit">
        <h4 class="title -c">Добавление администратора</h4>
        <div class="content">
          <div class="_grid -groove">
            <div class="_grid-cell">
              <input
                @blur="eBlur"
                type="text"
                class="TextInput"
                id="admin-add-admin-login"
                name="admin-add-admin[login]"
                placeholder="Логин"
                v-model="email"
              />
              <small class="error" v-if="eError">{{ eError }}</small>
            </div>
            <div class="_grid-cell">
              <input
                @blur="pBlur"
                type="text"
                class="TextInput"
                id="admin-add-admin-password"
                name="admin-add-admin[password]"
                placeholder="Пароль"
                v-model="password"
              />
              <small class="error" v-if="pError">{{ pError }}</small>
            </div>
          </div>
          <div class="_grid -groove">
            <div class="_grid-cell">
              <button
                type="submit"
                class="PushBtn -normal -l"
                id="admin-add-admin-create"
                name="admin-add-admin[create]"
              >
                Создать
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  setup() {
    const store = useStore()
    const router = useRouter()
    const { handleSubmit } = useForm()

    const { value: email, errorMessage: eError, handleBlur: eBlur } = useField(
      'email',
      yup
        .string()
        .required('Поле не должно быть пустым')
        .trim()
        .email('Необходимо ввести корректный email')
    )
    const {
      value: password,
      errorMessage: pError,
      handleBlur: pBlur,
    } = useField(
      'password',
      yup
        .string()
        .required('Поле не должно быть пустым')
        .trim()
        .min(6, 'Необходимо ввести мин 6 символов')
    )

    const onSubmit = handleSubmit(async values => {
      try {
        await store.dispatch('registration', values)
        router.push('/admins')
      } catch (e) {
        e
      }
    })

    return { email, password, eError, eBlur, pError, pBlur, onSubmit }
  },
}
</script>
