export function requestType(val) {
  if (val == 8) {
    return ['Подготовка 6 лет']
  } else if (val == 9) {
    return ['Подготовка 6 лет', 'Английский PLAYWAY']
  } else if (val == 4) {
    return ['Подготовка 5 лет']
  } else if (val == 5) {
    return ['Подготовка 5 лет', 'Английский PLAYWAY']
  } else if (val == 1) {
    return ['Английский PLAYWAY']
  } else if (val == 2) {
    return ["Английский KID'S BOX"]
  } else {
    return val
  }
}
