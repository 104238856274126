<template>
  <component :is="layout + '-layout'"></component>
</template>

<script>
import HomeLayout from './layout/HomeLayout.vue'
import TestLayout from './layout/TestLayout.vue'
import AuthLayout from './layout/AuthLayout.vue'
import PanelLayout from './layout/PanelLayout.vue'
import ScheduleLayout from './layout/ScheduleLayout.vue'
import PrintLayout from './layout/PrintLayout.vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
export default {
  setup() {
    const route = useRoute()
    return {
      layout: computed(() => {
        if (route.meta.layout === undefined) {
          return 'auth'
        } else {
          return route.meta.layout
        }
      }),
    }
  },
  components: {
    TestLayout,
    HomeLayout,
    AuthLayout,
    PanelLayout,
    ScheduleLayout,
    PrintLayout,
  },
}
</script>
