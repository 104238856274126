<template>
  <div class="course__acardion">
    <div
      @click="toogle"
      :class="toog ? 'active' : null"
      class="course__acardion-title"
    >
      <span class="course__acardion-title-icon"></span
      ><span class="course__acardion-title-content">Образование</span>
    </div>
    <div v-if="toog" class="course__acardion-text">
      <span class="course__acardion-text-small">
        В соответствии с лицензией на право ведения образовательной деятельности
        рег. № 7403 от 02 сентября 2019 года, выданной Министерством образования
        и науки Самарской области на основании приказа №189-л от 02 сентября
        2019 года.<br />
        ОГРН 308632024900074<br />
        ИНН 632101821500<br />
        Центр “РИНО” осуществляет деятельность по следующим кодам ОКВЭД:<br />
        85.11 - образование дошкольное;<br />
        85.41 - дополнительное образование для детей и взрослых.<br />
        Обучение ведется круглогодично по очной форме обучения. Форма проведения
        занятий - групповая.<br />
        Прием осуществляется на основании личного заявления граждан.
      </span>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
export default {
  setup() {
    const toog = ref(false)

    function toogle() {
      toog.value = !toog.value
    }

    return { toog, toogle }
  },
}
</script>
