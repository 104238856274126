<template>
  <div class="modal request" v-if="toogs">
    <div class="modal__admin-box">
      <app-info-block
        :text="item"
        v-for="item in warning"
        :key="item"
      ></app-info-block>
      <form
        @submit.prevent="onSubmit"
        class="modal__admin-3 modal__admin-container"
      >
        <div class="modal__admin-3 modal__admin-container">
          <p class="modal__admin-title">Выбирете тип обучения</p>
          <p>
            <app-select :reset="reset" v-model="type" :data="data"></app-select>
            <span v-if="eError" class="Error">{{ eError }}</span>
          </p>
        </div>
        <div class="modal__admin-3 modal__admin-container">
          <p class="modal__admin-title">Дата рождения ребенка (дд.мм.гггг)</p>
          <p>
            <input
              v-mask="['##.##.####']"
              type="text"
              class="admin-inp"
              v-model="birthdate"
              placeholder="Дата рождения ребенка (дд.мм.гггг)"
            />
            <span v-if="bError" class="Error">{{ bError }}</span>
          </p>
        </div>
        <div class="modal__admin-3 modal__admin-container">
          <p class="modal__admin-title">ФИО ребенка</p>
          <p>
            <input
              type="text"
              class="admin-inp"
              v-model="fio"
              placeholder="ФИО ребенка"
            />
            <span v-if="fError" class="Error">{{ fError }}</span>
          </p>
        </div>
        <div class="modal__admin-3 modal__admin-container">
          <p class="modal__admin-title">ФИО представителя</p>
          <p>
            <input
              type="text"
              class="admin-inp"
              v-model="agent"
              placeholder="ФИО представителя"
            />
            <span v-if="aError" class="Error">{{ aError }}</span>
          </p>
        </div>
        <div class="modal__admin-3 modal__admin-container">
          <p class="modal__admin-title">Контактный телефон</p>
          <p>
            <input
              v-mask="['###########']"
              type="text"
              class="admin-inp"
              v-model="phone"
              placeholder="Контактный телефон"
            />
            <span v-if="pError" class="Error">{{ pError }}</span>
          </p>
        </div>
        <div class="modal__admin-3 modal__admin-container">
          <p>
            <span
              ><input type="checkbox" v-model="checkIf" />Проходит обучение
              повторно</span
            >
          </p>
        </div>
        <div class="modal__admin-btn">
          <button type="submit" class="btn btn-1">
            Записаться
          </button>
        </div>
      </form>
    </div>
  </div>
  <div v-if="toogs" @click="close" class="overflow"></div>
</template>
<script>
import { formatDate } from '../../../utils/formatdate'
import { dataFormat } from '../../../utils/dateformat'
import { yearKids } from '../../../utils/yarekids'
import { parseDate } from '../../../utils/parsedata'
import { useStore } from 'vuex'
import { mask } from 'vue-the-mask'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'
import { ref, computed, onMounted } from 'vue'
import AppSelect from '../../ui/AppSelect.vue'
import AppInfoBlock from '../../ui/AppInfoBlock.vue'
export default {
  emits: ['closeModal'],
  props: ['toogs'],
  directives: { mask },
  components: { AppSelect, AppInfoBlock },
  setup(_, { emit }) {
    const checkIf = ref(true)
    const store = useStore()
    onMounted(() => {
      store.dispatch('getoffice')
    })
    const toggles = ref(false)
    const data = computed(() =>
      Object.keys(store.state.dataCourses).map(r => {
        return {
          id: r,
          data: store.state.dataCourses[r],
        }
      })
    )

    const reset = ref(null)

    const warning = computed(() => {
      if (toggles.value) {
        return [
          {
            title: '',
            style: 'warning-box',
            text: 'Проверте и заполните все данные правильно',
            text2: '',
          },
        ]
      } else {
        return store.state.warning
      }
    })

    const togg = ref(true)
    const { handleSubmit, resetForm } = useForm()
    const { value: type, errorMessage: eError } = useField('type')

    const { value: fio, errorMessage: fError } = useField(
      'fio',
      yup.string().required('Поле не должно быть пустым')
    )
    const { value: birthdate, errorMessage: bError } = useField(
      'birthdate',
      yup
        .string()
        .required('Поле не должно быть пустым')
        .min(10, `Введите дату рождения`)
    )
    const { value: agent, errorMessage: aError } = useField(
      'agent',
      yup.string().required('Поле не должно быть пустым')
    )
    const { value: phone, errorMessage: pError } = useField(
      'phone',
      yup
        .string()
        .required('Поле не должно быть пустым')
        .min(11, `Введите номер телефона`)
    )

    const nameCourse = computed(() => {
      if (childYare.value) {
        if (type.value) {
          return Array.from(
            new Set(
              [].concat
                .apply(
                  [],
                  type.value
                    .map(r => r.data)
                    .map(r => {
                      if (checkIf.value) {
                        return r.data.filter(g => g.status == 1)
                      } else {
                        return r.data.filter(g => g.status == 0)
                      }
                    })
                    .map(r => {
                      if (
                        r.filter(s => s.yare == childYare.value).length == 0
                      ) {
                        return r
                      } else {
                        return r.filter(s => s.yare == childYare.value)
                      }
                    })
                )
                .map(r => r.title)
            )
          )
        } else {
          return []
        }
      } else {
        return []
      }
    })

    const childYare = computed(() => {
      if (birthdate.value) {
        if (birthdate.value.length == 10) {
          return yearKids(parseDate(birthdate.value))
        } else {
          return ''
        }
      } else {
        return ''
      }
    })

    const onSubmit = handleSubmit(async values => {
      if (nameCourse.value.length > 0) {
        store.dispatch('Addrequest2', {
          agent: values.agent,
          appldate: formatDate(),
          birthdate: parseDate(values.birthdate),
          id: Math.random()
            .toString(16)
            .slice(2),
          name: values.fio,
          phone: values.phone,
          phoneraw: values.phone,
          status: 0,
          type: nameCourse.value,
          year: new Date().getFullYear(),
        })
        reset.value = []
        resetForm()
        emit('closeModal')
      } else {
        toggles.value = true
        setTimeout(() => {
          toggles.value = false
        }, 1000)
      }
    })

    function close() {
      emit('closeModal')
    }

    return {
      checkIf,
      close,
      resetForm,
      toggles,
      parseDate,
      data,
      onSubmit,
      pError,
      aError,
      type,
      fio,
      birthdate,
      agent,
      phone,
      togg,
      eError,
      fError,
      bError,
      childYare,
      yearKids,
      nameCourse,
      dataFormat,
      formatDate,
      warning,
      reset,
    }
  },
}
</script>
