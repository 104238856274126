<template>
  <div class="course__acardion">
    <div
      @click="toogle"
      :class="toog ? 'active' : null"
      class="course__acardion-title"
    >
      <span class="course__acardion-title-icon"></span
      ><span class="course__acardion-title-content"
        >Нормативные сроки обучения</span
      >
    </div>
    <div v-if="toog" class="course__acardion-text">
      <span class="course__acardion-text-small"
        >Программы по подготовке к школе рассчитаны на 9 учебных месяцев (с
        сентября по май). Занятия проходят 1 раз в неделю по 1 часу для детей 5
        лет и по 2 часа для детей 6 лет. Итого - 36 недель обучения. </span
      ><span class="course__acardion-text-small"
        >Программы по английскому языку рассчитаны на 9 учебных месяцев (с
        сентября по май). Занятия проходят один раз в неделю по 45 минут. Итого
        - 36 недель обучения.</span
      >
      <span class="course__acardion-text-small"
        >Логопедические занятия проходят по индивидуальной программе по 30 минут
        2 раза в неделю.</span
      >

      <span class="course__acardion-text-bold">Дополнительные программы: </span>
      <span class="course__acardion-text-small"
        >Программа "Читайка" Пчелинцевой Ю. рассчитана на 36 недель обучения по
        1 часу 1 раз в неделю.</span
      >
      <span class="course__acardion-text-small"
        >Программа "Школа внимания" Тимофеевой С. рассчитана на 36 недель
        обучения по 1 часу 1 раз в неделю.</span
      >
      <span class="course__acardion-text-small"
        >Программа "Могусам" Тимофеевой С. <br />
        -для детей 4-5 лет рассчитана на 36 недель обучения по 50 минут 2 раза в
        неделю.<br />
        -для детей 3-6 лет"рассчитана на 36 недель обучения по 50 минут 2 раза в
        неделю.
      </span>

      <span class="course__acardion-text-small"
        >Обучающихся лиц по реализуемым образовательным программам за счет
        бюджетных ассигнований и по договорам об образовании за счет средств
        физических лиц не имеется.</span
      >
      <span class="course__acardion-text-small"
        >Численность обучающихся по договорам об образовании за счет средств
        физических лиц составляет на 2021 год 250 человек.</span
      >
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
export default {
  setup() {
    const toog = ref(false)

    function toogle() {
      toog.value = !toog.value
    }

    return { toog, toogle }
  },
}
</script>
