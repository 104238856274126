<template>
  <div class="course__acardion">
    <div
      @click="toogle"
      :class="toog ? 'active' : null"
      class="course__acardion-title"
    >
      <span class="course__acardion-title-icon"></span
      ><span class="course__acardion-title-content"
        >Информация о вакантных местах для приёма учащихся
      </span>
    </div>
    <div v-if="toog" class="course__acardion-text">
      <span class="course__acardion-text-small">
        Наличие свободных мест в группах уточняйте у администраторов Центра по
        телефонам: +7 902 370 9173, +7 960 842 4210
      </span>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
export default {
  setup() {
    const toog = ref(false)

    function toogle() {
      toog.value = !toog.value
    }

    return { toog, toogle }
  },
}
</script>
