<template>
  <div class="course__acardion">
    <div
      @click="toogle"
      :class="toog ? 'active' : null"
      class="course__acardion-title"
      data-v-6e47603e=""
    >
      <span class="course__acardion-title-icon" data-v-6e47603e=""></span
      ><span class="course__acardion-title-content" data-v-6e47603e=""
        >О нашем центре</span
      >
    </div>
    <div v-if="toog" class="course__acardion-text" data-v-6e47603e="">
      <span class="course__acardion-text-bold"
        >Центр подготовки к школе “РИНО” - это:</span
      >
      <span class="course__acardion-text-small"
        >- 22 года успешной работы;</span
      >
      <span class="course__acardion-text-small"
        >- авторские программы обучения;</span
      >
      <span class="course__acardion-text-small"
        >- высокопрофессиональные педагоги;</span
      >
      <span class="course__acardion-text-small">- малочисленные группы.</span>

      <span class="course__acardion-text-small"
        >Миссия нашего Центра - прививать детям интерес к учебе с раннего
        возраста и давать успешный старт школьным годам.</span
      >

      <span class="course__acardion-text-bold">Наши направления:</span>
      <span class="course__acardion-text-small"
        >- Подготовка к школе с 5 и 6 лет
      </span>
      <span class="course__acardion-text-small"
        >- Английский язык (Кембриджская программа)</span
      >
      <span class="course__acardion-text-small"
        >- Школа скоростного интеллекта “Могусам”(3-5 лет)
      </span>
      <span class="course__acardion-text-small"
        >- Занятия для младших школьников “Школа внимания”</span
      >
      <span class="course__acardion-text-small"
        >- Обучение чтению “Читайка”</span
      >
      <span class="course__acardion-text-small">- Логопедические занятия</span>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
export default {
  setup() {
    const toog = ref(false)

    function toogle() {
      toog.value = !toog.value
    }

    return { toog, toogle }
  },
}
</script>
