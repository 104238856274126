<template>
  <div class="course__acardion">
    <div
      @click="toogle"
      :class="toog ? 'active' : null"
      class="course__acardion-title"
    >
      <span class="course__acardion-title-icon"></span
      ><span class="course__acardion-title-content"
        >Структура и органы управления</span
      >
    </div>
    <div v-if="toog" class="course__acardion-text">
      <span class="course__acardion-text-bold"
        >Директор Кочерова Ирина Владимировна</span
      >
      <span class="course__acardion-text-small"
        >445039 Самарская область, г. Тольятти, ул. Дзержинского, 19.<br />
        Телефоны: +7 902 370 9173; +7 960 842 4210<br />
        E-mail: contact@rino-tlt.ru
      </span>
      <span class="course__acardion-text-bold"
        >Исполнительный директор Илюхина Елена Викторовна
      </span>
      <span class="course__acardion-text-small"
        >445039 Самарская область, г. Тольятти, ул. Дзержинского, 19<br />
        Телефоны: +7 902 370 9173; +7 960 842 4210<br />
        E-mail: contact@rino-tlt.ru
      </span>
      <span class="course__acardion-text-bold"
        >Управляющий Земскова Елена Сергеевна
      </span>
      <span class="course__acardion-text-small"
        >445039 Самарская область, г. Тольятти, ул. Дзержинского, 19<br />
        Телефоны: +7 902 370 9173; +7 960 842 4210<br />
        E-mail: contact@rino-tlt.ru
      </span>
      <span class="course__acardion-text-bold"
        >Администраторы: Веселова Елена Николаевна</span
      >
      <span class="course__acardion-text-small"
        >Телефоны: +7 902 370 9173; +7 960 842 4210<br />
        E-mail: contact@rino-tlt.ru
      </span>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
export default {
  setup() {
    const toog = ref(false)

    function toogle() {
      toog.value = !toog.value
    }

    return { toog, toogle }
  },
}
</script>
