import axios from 'axios'
import router from '../router'

const requestAxios = axios.create({
  baseURL:
    'https://rinotltbase-default-rtdb.europe-west1.firebasedatabase.app/',
})

requestAxios.interceptors.response.use(null, error => {
  if (error.response.status === 401) {
    router.push('/administrado')
  }

  return Promise.reject(error)
})

export default requestAxios
