<template>
  <tr>
    <td>День недели</td>
    <td>Учебный год</td>
    <td>Каб.</td>
    <td>Время</td>
    <td>Название</td>
    <td>ФИО ребенка</td>
    <td>ФИО представителя</td>
    <td>Контактный телефон</td>
    <td>Педагог</td>
    <td>Адрес</td>
  </tr>
  <tr v-for="item in data" :key="item">
    <td>{{ dayFormat(item.sid.dow) }}</td>
    <td>{{ item.sid.year }}</td>
    <td>
      {{
        roomdata.filter((r) => r.id == item.sid.room).length > 0
          ? roomdata.filter((r) => r.id == item.sid.room)[0].title
          : '-'
      }}
    </td>
    <td>{{ item.sid.start_time }}-{{ item.sid.end_time }}</td>
    <td>{{ item.sid.title }}</td>
    <td>{{ item.rid.name }}</td>
    <td>{{ item.rid.agent }}</td>
    <td>{{ item.rid.phone }}</td>
    <td>{{ item.sid.teacher ? item.sid.teacher : '-' }}</td>
    <td>{{ item.sid.address ? item.sid.address : '-' }}</td>
  </tr>
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { dayFormat } from '../../../utils/dayformat'
export default {
  setup() {
    const store = useStore()
    const data = computed(() =>
      store.state.print
        .filter((r) => r.sid !== undefined)
        .sort(function (a, b) {
          return a.sid.dow - b.sid.dow
        })
    )

    const roomdata = computed(() =>
      Object.keys(store.state.room).map((r) => store.state.room[r])
    )

    return { data, roomdata, dayFormat }
  },
}
</script>