<template>
  <div class="course__acardion">
    <div
      @click="toogle"
      :class="toog ? 'active' : null"
      class="course__acardion-title"
    >
      <span class="course__acardion-title-icon"></span
      ><span class="course__acardion-title-content"
        >Платные образовательные услуги</span
      >
    </div>
    <div v-if="toog" class="course__acardion-text">
      <span class="course__acardion-text-small">
        Платные образовательные услуги оказываются Центром “РИНО”, в лице ИП
        Кочеровой И.В. в соответствии с Федеральным законом от 29 декабря 2012
        г. № 273 - ФЗ “Об образовании в Российской Федерации”, законом РФ от
        07.02.1992 № 2300-1 “О защите прав потребителей”, Постановлением
        Правительства РФ от 15 августа 2013 г. № 705 “Об утверждении правил
        оказания платных образовательных услуг”.</span
      >
      <span class="course__acardion-text-small"
        >Стоимость обучения по каждой программе устанавливается ежегодно
        (возможно повышение цен в начале учебного года) и утверждается
        директором Центра.
      </span>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
export default {
  setup() {
    const toog = ref(false)

    function toogle() {
      toog.value = !toog.value
    }

    return { toog, toogle }
  },
}
</script>
