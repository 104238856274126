<template>
  <ul class="admin__box-td th-6 grid сhoosing">
    <li class="admin__box-td-6 td-1">
      <input
        :disabled="statusIF"
        type="checkbox"
        :checked="requestScheduleID ? true : false"
        v-model="type"
      />
    </li>
    <li class="admin__box-td-6 td-2">
      {{ data.start_time }} - {{ data.end_time }}
    </li>
    <li class="admin__box-td-6 td-2">
      {{ roomTitle }}
    </li>
    <li class="admin__box-td-6 td-3">
      {{ data.title ? data.title : '-' }}
    </li>
    <li class="admin__box-td-6 td-4">
      {{ data.classes ? data.classes : '-' }}
    </li>
    <li class="admin__box-td-6 td-5">
      {{ data.address ? data.address : '-' }}
    </li>

    <li class="admin__box-td-6 td-6">
      {{ data.teacher ? data.teacher : '-' }}
    </li>
  </ul>
</template>
<script>
import { ref, watch, computed } from 'vue'
import { useStore } from 'vuex'
export default {
  props: ['data', 'id', 'subid', 'requestSchedule', 'room'],
  setup(props) {
    const store = useStore()
    const type = ref()

    const statusIF = computed(() => {
      if (requestScheduleID.value) {
        return false
      } else {
        return (
          props.requestSchedule.filter(r => r.type == props.data.type).length >
          0
        )
      }
    })

    const roomTitle = computed(() => {
      if (props.room) {
        if (props.room.length !== 0) {
          return props.room.filter(r => r.id == props.data.room)[0].title
        } else {
          return ''
        }
      } else {
        return ''
      }
    })

    const toggs = computed(() => store.state.statusInput3)

    const requestScheduleID = computed(() => {
      if (
        props.requestSchedule.filter(r => r.sid == props.data.id).length !== 0
      ) {
        return props.requestSchedule.filter(r => r.sid == props.data.id)[0].id
      } else {
        return null
      }
    })

    watch(type, val => {
      if (val) {
        if (props.data.year == new Date().getFullYear()) {
          store.commit('inputStatus3', true)
          store.dispatch('addRequestSchedule', {
            rid: props.id,
            sid: props.data.id,
            subid: props.subid,
            type: props.data.type,
          })
          store.dispatch('requestPutchose', {
            id: props.subid,
            status: true,
          })
        }
      } else {
        if (props.data.year == new Date().getFullYear()) {
          store.dispatch('deleteRequestSchedule', {
            subid: props.subid,
            id: requestScheduleID.value,
          })
        }
      }
    })

    return { type, requestScheduleID, toggs, roomTitle, statusIF }
  },
}
</script>
