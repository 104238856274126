<template>
  <app-info-block v-if="toog" :text="textSchedule"></app-info-block>
  <div class="admin__box-table">
    <p class="admin__box-table-title">
      {{ title }}<a @click="addItem()" class="position__right-box">+</a>
      <a @click="saveItem()" class="position__right-box2"
        ><img width="15" src="../../../assets/img/save.svg"
      /></a>
    </p>
    <ul class="admin__box-th th-6 grid table2">
      <li class="admin__box-td-6 td-1">Каб.</li>
      <li class="admin__box-td-6 td-2">Время</li>
      <li class="admin__box-td-6 td-3">Курс</li>
      <li class="admin__box-td-6 td-4">Мест</li>
      <li class="admin__box-td-6 td-5">Название</li>
      <li class="admin__box-td-6 td-5">Переодичность</li>
      <li class="admin__box-td-6 td-6">Адрес</li>
      <li class="admin__box-td-6 td-7">Педагог</li>
    </ul>
    <app-tbody-flex
      @delete="deleteItem($event)"
      :n="n"
      v-for="n in data"
      :key="n"
    >
    </app-tbody-flex>
  </div>
</template>
<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import AppTbodyFlex from './AppTbodyFlex.vue'
import AppInfoBlock from '../../ui/AppInfoBlock.vue'
export default {
  emits: ['saveItem'],
  props: ['title', 'yar', 'r', 'yeare', 'i'],
  components: { AppTbodyFlex, AppInfoBlock },
  setup(props, { emit }) {
    const count = ref(0)
    const store = useStore()

    const dowItem = computed(() => {
      if (props.title === 'Понедельник') {
        return '1'
      }
      if (props.title === 'Вторник') {
        return '2'
      }
      if (props.title === 'Среда') {
        return '3'
      }
      if (props.title === 'Четверг') {
        return '4'
      }
      if (props.title === 'Пятница') {
        return '5'
      }
      if (props.title === 'Суббота') {
        return '6'
      }
      if (props.title === 'Воскресенье') {
        return '7'
      } else {
        return '0'
      }
    })

    const data = computed(() => {
      if (props.yeare) {
        if (props.title === 'Понедельник') {
          return props.yeare.filter(item => item.dow === '1')
        }
        if (props.title === 'Вторник') {
          return props.yeare.filter(item => item.dow === '2')
        }
        if (props.title === 'Среда') {
          return props.yeare.filter(item => item.dow === '3')
        }
        if (props.title === 'Четверг') {
          return props.yeare.filter(item => item.dow === '4')
        }
        if (props.title === 'Пятница') {
          return props.yeare.filter(item => item.dow === '5')
        }
        if (props.title === 'Суббота') {
          return props.yeare.filter(item => item.dow === '6')
        }
        if (props.title === 'Воскресенье') {
          return props.yeare.filter(item => item.dow === '7')
        } else {
          return props.yeare
        }
      } else {
        return []
      }
    })

    const is = computed(() => store.getters.idAdd)
    function addItem() {
      store.commit('addScheduleItem', {
        year: props.yar,
        obj: {
          room: '',
          start_time: '',
          end_time: '',
          type: '',
          capacity: '',
          title: '',
          id: is.value,
          year: props.yar,
          dow: dowItem.value,
        },
      })
    }

    function deleteItem(item) {
      store.commit('deleteScheduleItem', {
        year: props.value,
        id: item,
      })
    }

    function saveItem() {
      emit('saveItem')
    }

    const textSchedule = {
      title: '',
      style: 'warning-box-green pd2',
      text: 'Сохранено',
      text2: '',
    }

    const toog = computed(() => store.state.statusInput5)

    return {
      toog,
      textSchedule,
      saveItem,
      deleteItem,
      addItem,
      count,
      data,
      dowItem,
      is,
    }
  },
}
</script>
