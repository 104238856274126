<template>
  <div class="course__acardion">
    <div
      @click="toogle"
      :class="toog ? 'active' : null"
      class="course__acardion-title"
    >
      <span class="course__acardion-title-icon"></span
      ><span class="course__acardion-title-content">Филиалы</span>
    </div>
    <div v-if="toog" class="course__acardion-text">
      <span class="course__acardion-text-small">
        Дзержинского, 19<br />
        Ворошилова, 17, БЦ “Европа”<br />
        40 лет Победы, 26, ТОЦ “Велит”<br />
        <br />
        “РИНО” - Развитие Интеллекта и Навыков Общения
      </span>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
export default {
  setup() {
    const toog = ref(false)

    function toogle() {
      toog.value = !toog.value
    }

    return { toog, toogle }
  },
}
</script>
