<template>
  <div class="select-box">
    <div @click.stop="toogle">
      <p class="active" v-for="(i, n) in title" :key="i">
        {{ i.data.title }}<template v-if="title.length !== n + 1">, </template>
      </p>
      <p v-if="title.length == 0">Выберите тип обучения</p>
    </div>
    <span
      ><svg
        width="15"
        height="15"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 30L25 18L37 30"
          stroke="#fff"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        /></svg
    ></span>
    <ul @click.stop v-if="togg">
      <li v-for="item in data" :key="item">
        <input type="checkbox" :value="item" v-model="selectedUsers" />{{
          item.data.title
        }}
      </li>
    </ul>
  </div>
</template>
<script>
import { ref, watch, computed } from 'vue'
export default {
  emits: ['update:modelValue'],
  props: ['data', 'modelValue', 'reset'],
  setup(props, { emit }) {
    const selectedUsers = ref([])

    const title = ref([])

    const togg = ref(false)

    function toogle() {
      togg.value = !togg.value
    }

    const resetSelect = computed(() => props.reset)

    watch(resetSelect, val => {
      selectedUsers.value = val
    })

    document.onclick = function() {
      togg.value = false
    }

    watch(selectedUsers, val => {
      title.value = val
      emit('update:modelValue', val)
    })

    return { selectedUsers, togg, toogle, title, resetSelect }
  },
}
</script>
