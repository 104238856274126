export function typeCourses(val) {
  if (val == 5) {
    return 'Подготовка 5 лет'
  } else if (val == 6) {
    return 'Подготовка 6 лет'
  } else if (val == 2) {
    return "Английский KID'S BOX"
  } else if (val == 1) {
    return 'Английский PLAYWAY'
  } else {
    return val
  }
}
