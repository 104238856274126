<template>
  <ul class="admin__box-td grid td-9 r-s-filter">
    <li class="admin__box-td-9 td9-1">
      {{ sidData.year }}
    </li>
    <li class="admin__box-td-9 td9-2">
      {{ room }}
    </li>
    <li class="admin__box-td-9 td9-4">
      {{ sidData.start_time }} - {{ sidData.end_time }}
    </li>
    <li class="admin__box-td-9 td9-5">
      {{ sidData.title }}
    </li>
    <li class="admin__box-td-9 td9-6">
      {{ ridData.name }}
    </li>
    <li class="admin__box-td-9 td9-7">
      {{ ridData.agent }}
    </li>
    <li class="admin__box-td-9 td9-8">
      {{ ridData.phone }}
    </li>
    <li class="admin__box-td-9 td9-9">
      {{ sidData.teacher ? sidData.teacher : '-' }}
    </li>
    <li class="admin__box-td-9 td9-10">
      {{ sidData.address ? sidData.address : '-' }}
    </li>
  </ul>
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  props: ['rid', 'sid'],
  setup(props) {
    const store = useStore()
    const ridData = computed(() => {
      if (props.rid) {
        return props.rid
      } else {
        return ''
      }
    })

    const sidData = computed(() => {
      if (props.sid) {
        return props.sid
      } else {
        return ''
      }
    })

    const room = computed(() => {
      if (props.sid) {
        if (roomdata.value.filter(s => s.id == props.sid.room).length !== 0) {
          return roomdata.value.filter(s => s.id == props.sid.room)[0].title
        } else {
          return []
        }
      } else {
        return '-'
      }
    })

    const roomdata = computed(() =>
      Object.keys(store.state.room).map(r => store.state.room[r])
    )

    return { ridData, sidData, room, roomdata }
  },
}
</script>
