const MESSADGE_ERROR = {
  INVALID_PASSWORD: 'Введен не верный пароль',
  EMAIL_NOT_FOUND: 'Введен неверный email',
  auth: 'Необходимо ввести данные',
}

export function error(code) {
  return MESSADGE_ERROR[code]
    ? MESSADGE_ERROR[code]
    : 'Неправильный логин или пароль'
}
