<template>
  <div class="course__acardion">
    <div
      @click="toogle"
      :class="toog ? 'active' : null"
      class="course__acardion-title"
    >
      <span class="course__acardion-title-icon"></span
      ><span class="course__acardion-title-content"
        >Нормативные документы по организации антитеррористической работы в
        Центре «РИНО»</span
      >
    </div>
    <div v-if="toog" class="course__acardion-text">
      <span class="course__acardion-text-small">
        1. Приказ «Об утверждении локальных актов по антитеррористической
        безопасности в 2020 году»</span
      >
      <span class="course__acardion-text-small"
        >2. Приказ «О назначении ответственных за обеспечение условий
        антитеррористической защищенности»</span
      >
      <span class="course__acardion-text-small"
        >3. Функциональные обязанности ответственного лица на выполнение
        мероприятий по антитеррористической защите объекта</span
      >
      <span class="course__acardion-text-small"
        >4. Алгоритм действий должностному лицу по предотвращению
        террористических актов</span
      >
      <span class="course__acardion-text-small"
        >5. Алгоритм действий должностному лицу при получении угрозы о
        взрыве</span
      >
      <span class="course__acardion-text-small"
        >6. Алгоритм действий должностному лицу при обнаружении предмета,
        похожего на взрывоопасный</span
      >
      <span class="course__acardion-text-small"
        >7. Инструкция руководителю по обеспечению безопасности,
        антитеррористической защищенности сотрудников и обучающихся в условиях
        повседневной жизнедеятельности</span
      >
      <span class="course__acardion-text-small"
        >8. Памятка руководителю о первоочередных действиях при угрозе
        террористического акта или возникновении иных нештатных ситуаций</span
      >
      <span class="course__acardion-text-small"
        >9. Рекомендации руководителю и постоянно действующему составу по
        принятию мер обеспечения безопасности и защиты от нештатных ситуаций
        террористического характера</span
      >
      <span class="course__acardion-text-small"
        >10. План профилактической работы по предотвращению террористических
        актов</span
      >
      <span class="course__acardion-text-small"
        >11. Инструкция персоналу при обнаружении предмета, похожего на взрывное
        устройство
      </span>
      <span class="course__acardion-text-small"
        >12. Демаскирующие признаки взрывных устройств в почтовых
        отправлениях</span
      >
      <span class="course__acardion-text-small"
        >13. Рекомендуемые зоны эвакуации и оцепления при обнаружении взрывного
        устройства или подозрительного предмета, который может оказаться
        взрывным устройством</span
      >
      <span class="course__acardion-text-small"
        >14. Инструкция персоналу при поступлении угрозы террористического акта
        по телефону</span
      >
      <span class="course__acardion-text-small"
        >15. Контрольный лист наблюдений при угрозе по телефону</span
      >
      <span class="course__acardion-text-small"
        >16. Алгоритм по ведению телефонного разговора при угрозе взрыва</span
      >
      <span class="course__acardion-text-small"
        >17. Инструкция персоналу при поступлении угрозы террористического акта
        в письменном виде</span
      >
      <span class="course__acardion-text-small"
        >18. Инструкция персоналу при захвате террористами заложников
      </span>
      <span class="course__acardion-text-small"
        >19. Инструкция по действиям постоянного состава в условиях возможного
        биологического заражения
      </span>
      <span class="course__acardion-text-small"
        >20. Схема оповещения и сбора сотрудников Центра «РИНО» при угрозе
        террористического акта или возникновении иных нештатных ситуаций</span
      >
      <span class="course__acardion-text-small"
        >21. План проведения учебных тренировок с персоналом по
        антитеррористической безопасности</span
      >
      <span class="course__acardion-text-small"
        >22. Журнал инструктажей с персоналом по антитеррористической
        защищённости</span
      >
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
export default {
  setup() {
    const toog = ref(false)

    function toogle() {
      toog.value = !toog.value
    }

    return { toog, toogle }
  },
}
</script>
