export function yearKids(item) {
  var dob = new Date(Number(item) * 1000)
  var now = new Date(`${new Date().getFullYear()}-09-01`)
  var today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  var dobnow = new Date(today.getFullYear(), dob.getMonth(), dob.getDate())
  var age
  age = today.getFullYear() - dob.getFullYear()
  if (today < dobnow) {
    return age - 1
  } else {
    return age
  }
}
