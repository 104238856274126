<template>
  <div class="light-box">
    <p v-for="(item, i) in data" :key="item">
      <img width="70" @click="showMultiple(i)" :src="item.src" />
    </p>
  </div>
  <vue-easy-lightbox
    escDisabled
    moveDisabled
    :visible="visible"
    :imgs="imgs"
    :index="index"
    @hide="handleHide"
  ></vue-easy-lightbox>
</template>
<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import { ref } from 'vue'
export default {
  components: {
    VueEasyLightbox,
  },
  setup() {
    const visible = ref(false)
    const imgs = ref('')
    const index = ref(0)

    const data = [
      {
        src: `${require('../../../assets/img/document/sout/1014677432/1014677432-1.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/1014677432/1014677432-2.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/108354218/108354218-1.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/108354218/108354218-2.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/108354218/108354218-3.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/1200024755/1200024755-1.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/1297044335/1297044335-1.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/1297044335/1297044335-2.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/133347535/133347535-1.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/133347535/133347535-2.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/1434573348/1434573348-1.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/221399002/221399002-1.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/226406956/226406956-1.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/226406956/226406956-2.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/230876226/230876226-1.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/230876226/230876226-2.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/232433446/232433446-1.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/232433446/232433446-2.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/319466377/319466377-1.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/319466377/319466377-2.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/439408395/439408395-1.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/439408395/439408395-2.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/530230808/530230808-1.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/530230808/530230808-2.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/530230808/530230808-3.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/530230808/530230808-4.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/550287482/550287482-1.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/611127833/611127833-1.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/611127833/611127833-2.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/612327096/612327096-1.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/705386517/705386517-1.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/760200378/760200378-1.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/760200378/760200378-2.jpg')}`,
      },

      {
        src: `${require('../../../assets/img/document/sout/766226868/766226868-1.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/766226868/766226868-2.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/77334411/77334411-1.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/77334411/77334411-2.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/77334411/77334411-3.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/853259799/853259799-1.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/853259799/853259799-2.jpg')}`,
      },

      {
        src: `${require('../../../assets/img/document/sout/1449861885/1449861885-1.jpg')}`,
      },

      {
        src: `${require('../../../assets/img/document/sout/1542921306/1542921306-1.jpg')}`,
      },

      {
        src: `${require('../../../assets/img/document/sout/1635980727/1635980727-1.jpg')}`,
      },

      {
        src: `${require('../../../assets/img/document/sout/1698020341/1698020341-1.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/kartasout/1.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/kartasout/2.jpg')}`,
      },

      {
        src: `${require('../../../assets/img/document/sout/1706115839/001.jpg')}`,
      },
      {
        src: `${require('../../../assets/img/document/sout/1706115839/002.jpg')}`,
      },
    ]

    function showMultiple(e) {
      imgs.value = data

      index.value = e
      show()
    }

    function show() {
      visible.value = true
    }

    function handleHide() {
      visible.value = false
    }

    return { imgs, visible, index, show, handleHide, showMultiple, data }
  },
}
</script>
