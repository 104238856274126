<template>
  <div class="information-about">
    <h3 id="about" class="title _title">
      Сведения об образовательной организации
    </h3>
    <div class="information-about-box">
      <AppFilial></AppFilial>
      <AppOsnovnieSvedinija></AppOsnovnieSvedinija>
      <AppStruktera></AppStruktera>
      <AppDocument></AppDocument>
      <AppObrasovanie></AppObrasovanie>
      <AppProgrami></AppProgrami>
      <AppNormativniesroki></AppNormativniesroki>
      <AppRukovodstvo></AppRukovodstvo>
      <AppMto></AppMto>
      <AppNormDoc></AppNormDoc>
      <AppPlatnieUslugi></AppPlatnieUslugi>
      <AppPravilaPlatnihUslug></AppPravilaPlatnihUslug>
      <AppFinanseHosjaistvenajaDejatelnost></AppFinanseHosjaistvenajaDejatelnost>
      <AppInformVakansi></AppInformVakansi>
      <AppVakansii></AppVakansii>
    </div>
  </div>
</template>
<script>
import AppFilial from './information/AppFilial.vue'
import AppObrasovanie from './information/AppObrasovanie.vue'
import AppOsnovnieSvedinija from './information/AppOsnovnieSvedinija.vue'
import AppStruktera from './information/AppStruktera.vue'
import AppDocument from './information/AppDocument.vue'
import AppProgrami from './information/AppProgrami.vue'
import AppNormativniesroki from './information/AppNormativniesroki.vue'
import AppRukovodstvo from './information/AppRukovodstvo.vue'
import AppMto from './information/AppMto.vue'
import AppPlatnieUslugi from './information/AppPlatnieUslugi.vue'
import AppPravilaPlatnihUslug from './information/AppPravilaPlatnihUslug.vue'
import AppFinanseHosjaistvenajaDejatelnost from './information/AppFinanseHosjaistvenajaDejatelnost.vue'
import AppInformVakansi from './information/AppInformVakansi.vue'
import AppVakansii from './information/AppVakansii.vue'
import AppNormDoc from './information/AppNormDoc.vue'
export default {
  components: {
    AppNormDoc,
    AppOsnovnieSvedinija,
    AppStruktera,
    AppDocument,
    AppObrasovanie,
    AppFilial,
    AppProgrami,
    AppNormativniesroki,
    AppRukovodstvo,
    AppMto,
    AppPlatnieUslugi,
    AppPravilaPlatnihUslug,
    AppFinanseHosjaistvenajaDejatelnost,
    AppInformVakansi,
    AppVakansii,
  },
  setup() {},
}
</script>
