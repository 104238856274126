<template>
  <div class="confirmation">
    <div class="title-confirmation">Хотите удалить учебный год</div>
    <div class="title-confirmation" v-if="title">
      {{ title }}-{{ Number(title) + 1 }}
    </div>
    <div class="confirmation-button">
      <button
        v-for="item in data"
        :key="item"
        class="btn"
        :class="item.color"
        @click="item.click"
      >
        {{ item.title }}
      </button>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex'
export default {
  props: ['title'],
  emits: ['closeSchedule'],
  setup(props, { emit }) {
    const store = useStore()
    const data = [
      {
        title: 'Отмена',
        color: 'green',
        click: close,
      },
      {
        title: 'Удалить',
        color: 'red',
        click: deleteSchedule,
      },
    ]

    function close() {
      emit('closeSchedule')
    }

    function deleteSchedule() {
      emit('closeSchedule')
      store.dispatch('deleteSchedule', props.title)
    }

    return { data, close, deleteSchedule }
  },
}
</script>
