<template>
  <div class="course__acardion">
    <div
      @click="toogle"
      :class="toog ? 'active' : null"
      class="course__acardion-title"
    >
      <span class="course__acardion-title-icon"></span
      ><span class="course__acardion-title-content"
        >Материально-техническое обеспечение и оснащенность образовательного
        процесса
      </span>
    </div>
    <div v-if="toog" class="course__acardion-text">
      <span class="course__acardion-text-small">
        Общая площадь помещения Центра “РИНО” составляет 165,1 м2. <br />На
        правах собственности Центр “РИНО” имеет 5 учебных классов. <br />Классы
        оборудованы компьютерами, ноутбуками, телевизором, учебными досками,
        учебной мебелью.<br />
        Есть большой выбор дополнительных учебных материалов: плакаты, таблицы и
        др.<br />
        Учебный процесс обеспечен копировально-множительной техникой.
      </span>
      <span class="course__acardion-text-small">
        Центр “РИНО” имеет санитарно-эпидемиологическое заключение №
        63.СЦ.05.000.М.000377.03.19 от 22 марта 2019 года, удостоверяющее, что
        образовательная деятельность в помещениях Центра соответствует
        государственным санитарно-эпидемиологическим правилам и нормам.
      </span>
      <span class="course__acardion-text-small"
        >В Центре разработаны и используются в практической деятельности<br />
        Инструкция по пожарной безопасности,<br />
        Инструкция по охране труда для работающих.<br />
        Заключение о соответствии объекта защиты обязательным требованиям
        пожарной безопасности № 003195 от 16 мая 2019 года.
      </span>

      <span class="course__acardion-text-bold"
        >В Центре “РИНО” разработаны:</span
      >
      <span class="course__acardion-text-small"
        >- Положение об организации работы по обеспечению пожарной
        безопасности;</span
      >
      <span class="course__acardion-text-small"
        >- Положение об организации обучения и проверки знаний по пожарной
        безопасности работающих.</span
      >

      <span class="course__acardion-text-bold">Имеются документы:</span>
      <span class="course__acardion-text-small"
        >- Инструкция о назначении ответственных лиц за пожарную
        безопасность;</span
      >
      <span class="course__acardion-text-small"
        >- Инструкция об организации по обеспечению пожарной безопасности;</span
      >
      <span class="course__acardion-text-small"
        >- Приказ об установлении противопожарного режима;</span
      >
      <span class="course__acardion-text-small">- План (схема) эвакуации;</span>
      <span class="course__acardion-text-small"
        >- Журнал учёта первичных средств пожаротушения.</span
      >

      <span class="course__acardion-text-small"
        >Центр “РИНО” оборудован кнопкой тревожной сигнализации, договор №
        240/20 от 24.04.2020 с ООО ЧАО ЦСМ "ГРОМ" и автоматической сигнализацией
        “Стрелец-Мониторинг”, имеющий выход на централизованный пункт МЧС.</span
      >
      <span class="course__acardion-text-small"
        >В целях безопасности оборудован видеонаблюдением, договор № М10-3/2020
        от 16 марта 2020 г. с ИП Буйлов Александр Владимирович (Системы
        безопасности Recon), действующим на основании Свидетельства серия 63 №
        005359710 от 17.05.2010 г."</span
      >

      <span class="course__acardion-text-small"
        >Для обеспечения питьевого режима в Центре используется бутилированная
        вода с кулерами и одноразовые стаканы.</span
      >
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
export default {
  setup() {
    const toog = ref(false)

    function toogle() {
      toog.value = !toog.value
    }

    return { toog, toogle }
  },
}
</script>
