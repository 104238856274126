<template>
  <div class="PageContent">
    <div class="header-top">
      <div class="header-top-center">
        <div class="header-top-logo">
          <a @click="onClick" class="mobMenu"
            ><img width="35" src="../assets/img/burger.svg"
          /></a>
          <router-link :to="'/'"
            ><img src="../assets/img/logo2.png"
          /></router-link>
        </div>
        <div class="header-top-menu">
          <ul>
            <li>
              <router-link
                class="link -about -smooth"
                :to="{ path: '/', hash: '#about' }"
                >О центре</router-link
              >
            </li>
            <li>
              <router-link
                class="link -signup -smooth"
                :to="{ path: '/', hash: '#signup' }"
                >Записаться</router-link
              >
            </li>
            <li>
              <a
                class="link -schedule -smooth"
                href="/programm3.pdf"
                target="_blank"
                >Образовательная программа</a
              >
            </li>
            <li>
              <router-link
                class="link -map -smooth"
                :to="{ path: '/', hash: '#courses' }"
                >Наши курсы</router-link
              >
            </li>
            <li>
              <router-link
                class="link -map -smooth"
                :to="{ path: '/', hash: '#map' }"
                >Контакты</router-link
              >
            </li>
          </ul>
        </div>
        <div class="price__blocks">
          <div class="header-top-phone">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.837"
                height="17.869"
                viewBox="0 0 17.837 17.869"
              >
                <path
                  d="M13.563,4.714a4.017,4.017,0,0,1,3.174,3.174M13.563,1.5a7.231,7.231,0,0,1,6.388,6.38m-.8,6.412V16.7A1.607,1.607,0,0,1,17.4,18.309a15.9,15.9,0,0,1-6.934-2.467,15.668,15.668,0,0,1-4.821-4.821A15.9,15.9,0,0,1,3.174,4.055,1.607,1.607,0,0,1,4.773,2.3h2.41A1.607,1.607,0,0,1,8.791,3.685a10.317,10.317,0,0,0,.562,2.258,1.607,1.607,0,0,1-.362,1.7l-1.02,1.02a12.856,12.856,0,0,0,4.821,4.821l1.02-1.02a1.607,1.607,0,0,1,1.7-.362,10.317,10.317,0,0,0,2.258.562A1.607,1.607,0,0,1,19.148,14.291Z"
                  transform="translate(-2.667 -0.948)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                ></path>
              </svg>
            </div>
            <div>
              <p><span>8 (902) 370-91-73</span></p>
              <p><span>8 (960) 842-42-10</span></p>
            </div>
          </div>
          <a class="btn__price btn__price--mobile  link -signup -smooth" aria-current="page" href="/#signup">Записаться</a>

          <a class="btn__price" target="_blank" href="/price.pdf">
            <span>
              <img width="22" src="../assets/img/iconjendela.png" />
            </span>
            Прайс
          </a>
        </div>
      </div>
    </div>

    <router-view></router-view>
    <!--<section class="SignupForm">
      <div class="content">
        <h3 class="title _title -light" id="signup">Записаться</h3>
        <app-request></app-request>
      </div>
    </section>
    <section class="ScheduleForm">
      <app-go-to-schedule></app-go-to-schedule>
    </section>
    -->
    <section class="SignupFormNew" id="signup">
      <div class="content-form">
        <!--form-->
        <iframe
          src="https://rino.s20.online/common/1/form/draw?id=1&amp;baseColor=205EDC&amp;borderRadius=8&amp;css=%2F%2Fcdn.alfacrm.pro%2Flead-form%2Fform.css"
          width="100%"
          height="100%"
          frameborder="0"
        ></iframe>

        <!---->
      </div>
    </section>
    <section class="contact">
      <h3 class="title _title" id="map">Контакты</h3>

      <div class="content-box">
        <div class="content-box-1">
          <p class="content-box-title">
            <span><img width="10" src="../assets/img/map.svg" /></span
            ><span>Наши филиалы:</span>
          </p>
          <p class="content-box-text">Дзержинского, 19</p>
          <p class="content-box-text">Ворошилова, 17, "БЦ Европа"</p>
          <p class="content-box-text">40 лет Победы, 26, "БЦ Велит"</p>
        </div>
        <div class="content-box-2">
          <p class="content-box-title">
            <span><img width="10" src="../assets/img/phone.svg" /></span
            ><span>Телефоны:</span>
          </p>
          <p class="content-box-text">8(902) 370-91-73</p>
          <p class="content-box-text">8(960) 842-42-10</p>
        </div>
        <div class="content-box-3">
          <p class="content-box-title">
            <span><img width="12" src="../assets/img/email.svg" /></span
            ><span>E-mail:</span>
          </p>
          <p class="content-box-text">contact@rino-tlt.ru</p>
        </div>
        <div class="content-box-4">
          <p class="content-box-title">
            <span><img width="10" src="../assets/img/social.svg" /></span
            ><span>Социальные медиа</span>
          </p>
          <p class="content-box-text social">
            <a target="_blank" href="https://vk.com/club59059609"
              ><img height="35" src="../assets/img/vk.svg" /></a
            ><a target="_blank" href="https://www.instagram.com/rino.tlt/"
              ><img height="28" src="../assets/img/ins.svg"
            /></a>
          </p>
        </div>
      </div>

      <div class="content-box content-box--mobile">
        <div class="content-box-1">
          <p class="content-box-title" style="padding-top: 20px">
            Наш прайс:
          </p>
          <p class="content-box-text social">
            <a style="width: 100%" class="btn__price" target="_blank" href="/price.pdf"><span><img width="22" src="../assets/img/iconjendela.png"></span> Прайс </a>
          </p>
        </div>
      </div>

      <h3 class="title _title" id="map">Где проходят занятия</h3>
    </section>
    <section class="YandexMap">
      <yandex-map
        :scrollZoom="false"
        ymap-class="yaMap"
        :settings="settings"
        :coords="coords"
        :zoom="14"
        @click="onClick"
      >
        <ymap-marker
          :coords="[53.533693, 49.321291]"
          marker-id="123"
          hint-content="Дзержинского 19"
          :icon="icon"
        />

        <ymap-marker
          :coords="[53.537263, 49.316449]"
          marker-id="124"
          hint-content="Ворошилова 17, БЦ Европа"
          :icon="icon"
        />

        <ymap-marker
          :coords="[53.54064, 49.361122]"
          marker-id="125"
          hint-content="40 лет Победы 26, БЦ Велит"
          :icon="icon"
        />
      </yandex-map>
    </section>

    <footer class="ContactList" id="contact">
      <div class="content">
        <section class="copyright">
          <p>©2017– {{ new Date().getFullYear() }}, РИНО</p>

          <p>
            <router-link to="/information-about"
              >Сведения об образовательной организации</router-link
            >
            <router-link to="/politikakon"
              >Политика конфиденциальности</router-link
            >
            <router-link to="/soglasienaobrdan"
              >Согласие на обработку персональных данных</router-link
            >
          </p>
        </section>
      </div>
    </footer>
    <div id="popups" style="display: none">
      <div id="popups1">
        <div class="ThanksPopup">
          <h4 class="title">Заявка создана</h4>
          <div class="content">
            <p>
              Спасибо за предварительную запись и за то, что выбрали нашу школу.
            </p>
            <p>
              Расписание занятий можно будет выбрать позже. В срок до 26 августа
              с вами свяжется наш администратор и объяснит, как это сделать.
            </p>
          </div>
          <div class="close"><button class="dismiss">Понятно</button></div>
        </div>
      </div>
      <div id="popups2">
        <div class="ThanksPopup">
          <h4 class="title">Заявка уже создана</h4>
          <div class="content">
            <p>Ваша заявка уже создана.</p>
            <p>
              Расписание занятий можно будет выбрать позже. В срок до 26 августа
              с вами свяжется наш администратор и объяснит, как это сделать.
            </p>
          </div>
          <div class="close"><button class="dismiss">Понятно</button></div>
        </div>
      </div>
    </div>
    <div class="messageArea"></div>
    <div class="screenDisabler">
      <div class="circle">
        <div class="circle1 -child"></div>
        <div class="circle2 -child"></div>
        <div class="circle3 -child"></div>
        <div class="сircle4 -child"></div>
        <div class="circle5 -child"></div>
        <div class="circle6 -child"></div>
        <div class="circle7 -child"></div>
        <div class="circle8 -child"></div>
        <div class="circle9 -child"></div>
        <div class="circle10 -child"></div>
        <div class="circle11 -child"></div>
        <div class="circle12 -child"></div>
      </div>
    </div>
  </div>
  <app-pop-up v-if="popup" :list="list" @CloseForm="onClose"></app-pop-up>
  <div v-if="togll" class="pop-up-warning">
    <div class="pop-up-warning-box">
      <span @click="togll = false" class="pop-up-warning-box-close"
        ><img src="../assets/img/close.svg"
      /></span>

      <div class="pop-up-warning-title">
        ГОРОДСКОЙ «ЛЕТНИЙ НАУЧНЫЙ ЛАГЕРЬ» В ЦЕНТРЕ ДЕТСКОГО РАЗВИТИЯ «РИНО»
      </div>
      <a target="_blank" href="https://summer-camps-rino-tlt.web.app/"
        >Подробнее</a
      >
    </div>
  </div>
  <a ref="whatsapp" target="_blank" :href="link" class="whatsapp-block">
    <div class="whatsapp-block__icon">
      <svg style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" version="1.1" viewBox="0 0 187 187" width="1.04167in" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Layer_x0020_1"><g id="_521245888"><rect class="fil0" height="187" rx="18" ry="18" width="187"/><g><path class="fil1" d="M95 146c-11,0 -22,-3 -31,-9l-21 7 7 -20c-6,-9 -10,-20 -10,-32 0,-2 0,-4 0,-5 3,-28 26,-50 55,-50 29,0 52,22 55,51 0,1 0,2 0,4 0,30 -25,54 -55,54zm65 -56c-1,-35 -30,-63 -65,-63 -35,0 -64,27 -66,62 0,1 0,2 0,3 0,12 4,24 10,33l-12 35 36 -11c9,5 20,8 32,8 36,0 65,-29 65,-65 0,-1 0,-1 0,-2z"/><path class="fil1" d="M125 105c-2,-1 -10,-5 -11,-5 -2,-1 -3,-1 -4,1 -1,1 -4,5 -5,6 -1,1 -2,1 -3,0 -2,-1 -7,-2 -13,-8 -5,-4 -8,-9 -9,-11 -1,-1 0,-2 0,-3 1,-1 2,-2 3,-3 0,0 0,0 0,-1 1,0 1,-1 1,-1 1,-2 1,-2 0,-3 0,-1 -3,-9 -5,-12 -1,-3 -2,-3 -3,-3 -1,0 -2,0 -3,0 -1,0 -3,1 -5,2 -1,2 -5,6 -5,13 0,2 0,4 1,6 1,5 5,10 5,11 1,1 11,18 28,24 16,6 16,4 19,4 3,0 10,-4 11,-8 1,-3 1,-7 1,-7 -1,-1 -2,-1 -3,-2z"/></g></g></g>
      </svg>
    </div>
    <!--<div class="whatsapp-block__text">
      Получить презентацию летнего лагеря
    </div>-->
  </a>
</template>
<script>
//import AppGoToSchedule from '../components/ui/AppGoToSchedule.vue'
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import {  ref } from 'vue'
//import AppRequest from '../components/ui/AppRequest.vue'
import AppPopUp from '../components/ui/AppPopUp.vue'
export default {
  components: { yandexMap, ymapMarker, AppPopUp },
  data(){
    return{
      link: null
    }
  },
  methods:{
    setLink(){
        this.link = 'https://l.clck.bar/215265';
    }
  },
  mounted() {
    this.setLink()
    window.onscroll = () => {
      if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - 100) {
        this.$refs.whatsapp.classList.add('hidden')
      } else {
        this.$refs.whatsapp.classList.remove('hidden')
      }
    };

  },
  setup() {
    const popup = ref(false)
    const togll = ref(false)

    const list = [
      { title: 'О центре', rout2: '/#about' },
      { title: 'Записаться', rout2: '/#signup' },
      {
        title: 'Образовательная программа',
        rout2: '/programm3.pdf',
        isTarget: true,
      },
      { title: 'Наши курсы', rout2: '/#courses' },
      { title: 'Контакты', rout2: '/#map' },
    ]

    const settings = {
      apiKey: 'cd38a715-a2ba-4c42-9103-83b76b5dc0b0',
      lang: 'ru_RU',
      coordorder: 'latlong',
      version: '2.1',
    }

    const icon = {
      layout: 'default#image',
      imageHref:
        'https://firebasestorage.googleapis.com/v0/b/rino-tlt.appspot.com/o/logo.png?alt=media&token=8c5b0e85-d3b3-442a-b306-b101b3d6aee9', // адрес изображения или data:image/svg+xml;base64
      imageSize: [40, 40], // размер иконки в px
      imageOffset: [-20, -20], // смещение иконки в px,
    }

    const coords = [53.533693, 49.321291]

    function onClick() {
      popup.value = true
    }

    function onClose() {
      popup.value = false
    }
    return { coords, onClick, settings, icon, popup, onClose, list, togll }
  },
}
</script>
