<template>
  <div class="info-box" :class="text.style">
    <p v-if="text.title" class="info-box-title">{{ text.title }}</p>
    <p v-if="text.text" class="info-box-text">{{ text.text }}</p>
    <p v-if="text.text2" class="info-box-text2">{{ text.text2 }}</p>
  </div>
</template>
<script>
export default {
  props: ['text'],
}
</script>
