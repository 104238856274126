<template>
  <ul class="filter-request request-schedule">
    <li class="admin__box-td-9 td9-8">
      <span>По названию курса:</span>
      <input
        type="text"
        class="filter-input"
        v-model.lazy="title"
        placeholder="Название курса"
      />
    </li>
    <li class="admin__box-td-9 td9-8">
      <span>Адрес:</span>
      <input
        type="text"
        class="filter-input"
        v-model.lazy="adress"
        placeholder="Адрес"
      />
    </li>
  </ul>
</template>
<script>
import { ref, watch } from 'vue'
export default {
  emits: ['update:modelValue'],
  props: ['modelValue'],
  setup(_, { emit }) {
    const title = ref()
    const adress = ref()

    watch([title, adress], values => {
      emit('update:modelValue', {
        title: values[0],
        adress: values[1],
      })
    })

    return { title, adress }
  },
}
</script>
