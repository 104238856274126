<template>
  <div class="admin__box">
    <h1>Добавить/Удалить курсы</h1>
    <div class="admin__box-table">
      <p class="admin__box-table-title title25">
        Список курсов<a @click="addItem()" class="position__right-box one">+</a>
      </p>
      <ul class="admin__box-th th-2 list2">
        <li class="admin__box-td-2 td-1">ID</li>
        <li class="admin__box-td-2 td-2">Название курса</li>
      </ul>
      <ul
        v-for="(item, id, i) in dataItem"
        :key="item"
        class="admin__box-td list2"
      >
        <li class="admin__box-td-2 td-1">{{ i + 1 }}</li>
        <li class="admin__box-td-2 td-2">{{ item.title }}</li>
        <li class="position-box">
          <a @click="edit(id, item)">
            <img width="15" src="../../assets/img/edit.svg" />
          </a>
          <a @click="deleteOffice(id)"
            ><img src="../../assets/img/delete.svg"
          /></a>
        </li>
      </ul>
      <div class="modal" v-if="toog">
        <div class="modal__admin-box">
          <div>
            <p class="admin__box-room">Название курса</p>
            <p class="admin__box-room2">
              <input
                class="modal__admin-select"
                type="text"
                placeholder="Название курса"
                v-model="title"
                @blur="eBlur"
              />
              <small class="error" v-if="eError">{{ eError }}</small>
            </p>
          </div>

          <div class="course-text">
            <span class="course-checbox"
              ><input id="course" type="checkbox" v-model="toogle"
            /></span>
            <span class="course-title">С привязкой по возрастам</span>
          </div>

          <div v-if="toogle" class="course-select">
            <div>
              <app-course-input
                :count="i"
                :data="int"
                v-for="(int, i) in courses.filter(r => r.status == 0)"
                :key="int"
                @plusCount="plusCount($event)"
                @minusCount="minusCount($event)"
              ></app-course-input>
            </div>
          </div>

          <div class="course-text">
            <span class="course-checbox"
              ><input id="course" type="checkbox" v-model="toogle2"
            /></span>
            <span class="course-title">Курсы для продолжающих обучение</span>
          </div>

          <div v-if="toogle2" class="course-select">
            <div>
              <app-course-input
                :count="i"
                :data="int"
                v-for="(int, i) in courses.filter(r => r.status == 1)"
                :key="int"
                @plusCount="plusCount($event)"
                @minusCount="minusCount($event)"
              ></app-course-input>
            </div>
          </div>
          <div v-if="cError" class="course-error">
            Все поля должны быть заполнены
          </div>

          <div class="modal__admin-btn">
            <button v-if="btnToogle == 1" @click="onSubmit" class="btn btn-1">
              Добавить
            </button>
            <button v-if="btnToogle == 2" @click="onEdit" class="btn btn-1">
              Редактировать
            </button>
          </div>
        </div>
      </div>
      <div v-if="toog" @click="close" class="overflow"></div>
    </div>
  </div>
</template>
<script>
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import AppCourseInput from '../ui/AppCourseInput.vue'

export default {
  components: { AppCourseInput },
  setup() {
    const store = useStore()
    const toog = ref(false)
    const toogle = ref()
    const title = ref()
    const count = ref(1)
    const eError = ref()
    const cError = ref()
    const courses = computed(() => store.state.courses)
    const toogle2 = ref()

    const validCourse = computed(() => store.getters.courseValid)
    const id = computed(() => store.getters.idCourse)
    const sub = computed(() => store.getters.subCourse)

    const counter1 = computed(() => {
      if (courses.value.filter(r => r.status == 0).length !== 0) {
        return true
      } else {
        return false
      }
    })
    const counter2 = computed(() => {
      if (courses.value.filter(r => r.status == 1).length !== 0) {
        return true
      } else {
        return false
      }
    })

    watch([counter1, counter2], val => {
      if (!val[0]) {
        toogle.value = false
      }
      if (!val[1]) {
        toogle2.value = false
      }
    })

    watch(toogle, val => {
      if (val) {
        store.commit('addCourses', {
          id:
            'c' +
            Math.random()
              .toString(16)
              .slice(2),
          subid:
            'c' +
            Math.random()
              .toString(16)
              .slice(2),
          yare: '',
          title: '',
          status: 0,
        })
      } else {
        store.commit('deleteCourses', 0)
      }
    })

    watch(toogle2, val => {
      if (val) {
        store.commit('addCourses', {
          id:
            'c' +
            Math.random()
              .toString(16)
              .slice(2),
          subid:
            'c' +
            Math.random()
              .toString(16)
              .slice(2),
          yare: '',
          title: '',
          status: 1,
        })
      } else {
        store.commit('deleteCourses', 1)
      }
    })

    function deleteOffice(id) {
      store.dispatch('deleteOffice', id)
    }

    const dataItem = computed(() => store.state.dataCourses)

    const btnToogle = ref()

    function addItem() {
      toog.value = true
      btnToogle.value = 1
    }

    function close() {
      btnToogle.value = null
      toog.value = false
      store.commit('deleteCourses')
      toogle.value = false
      title.value = ''
    }

    function onSubmit() {
      if (!toogle.value) {
        if (title.value) {
          store.dispatch('addoffice', {
            title: title.value,
            data: [
              {
                id:
                  'c' +
                  Math.random()
                    .toString(16)
                    .slice(2),
                subid:
                  'c' +
                  Math.random()
                    .toString(16)
                    .slice(2),
                title: title.value,
                yare: '',
                status: 0,
              },
            ],
          })
          toog.value = false
          title.value = ''
          toogle.value = false
        } else {
          eError.value = 'Поле не должно быть пустым'
          setTimeout(() => {
            eError.value = ''
          }, 1000)
        }
      } else {
        if (title.value) {
          if (!validCourse.value) {
            cError.value = true
            setTimeout(() => {
              cError.value = ''
            }, 1000)
          } else {
            store.dispatch('addoffice', {
              title: title.value,
              data: courses.value,
            })
            toog.value = false
            title.value = ''
            toogle.value = false
          }
        } else {
          eError.value = 'Поле не должно быть пустым'
          setTimeout(() => {
            eError.value = ''
          }, 1000)
        }
      }
    }

    function onEdit() {
      if (!toogle.value && !toogle2.value) {
        if (title.value) {
          store.dispatch('editoffice', {
            id: idCourses.value,
            data: {
              title: title.value,
              data: [
                {
                  id:
                    'c' +
                    Math.random()
                      .toString(16)
                      .slice(2),
                  subid:
                    'c' +
                    Math.random()
                      .toString(16)
                      .slice(2),
                  title: title.value,
                  yare: '',
                  status: 0,
                },
              ],
            },
          })
          toog.value = false
          title.value = ''
          toogle.value = false
        } else {
          eError.value = 'Поле не должно быть пустым'
          setTimeout(() => {
            eError.value = ''
          }, 1000)
        }
      } else {
        if (title.value) {
          if (!validCourse.value) {
            cError.value = true
            setTimeout(() => {
              cError.value = ''
            }, 1000)
          } else {
            store.dispatch('editoffice', {
              id: idCourses.value,
              data: {
                title: title.value,
                data: courses.value,
              },
            })
            toog.value = false
            title.value = ''
            toogle.value = false
          }
        } else {
          eError.value = 'Поле не должно быть пустым'
          setTimeout(() => {
            eError.value = ''
          }, 1000)
        }
      }
    }

    function plusCount(event) {
      count.value = count.value + 1
      if (event == 0) {
        store.commit('addCourses', {
          id:
            'c' +
            Math.random()
              .toString(16)
              .slice(2),
          subid:
            'c' +
            Math.random()
              .toString(16)
              .slice(2),
          yare: '',
          title: '',
          status: 0,
        })
      } else {
        store.commit('addCourses', {
          id:
            'c' +
            Math.random()
              .toString(16)
              .slice(2),
          subid:
            'c' +
            Math.random()
              .toString(16)
              .slice(2),
          yare: '',
          title: '',
          status: 1,
        })
      }
    }

    function minusCount(e) {
      store.commit('deleteCourse', e)
    }

    const idCourses = ref()

    function edit(item1, item2) {
      idCourses.value = item1
      btnToogle.value = 2
      toog.value = true
      title.value = item2.title
      if (item2.data) {
        if (item2.data.filter(r => r.status == 0).length > 0) {
          toogle.value = true
        }
        if (item2.data.filter(r => r.status == 1).length > 0) {
          toogle2.value = true
        }
        if (item2.data.length > 0) {
          setTimeout(() => {
            store.commit('pushCourses', item2.data)
          }, 100)
        }
      }
    }
    return {
      idCourses,
      onEdit,
      edit,
      plusCount,
      minusCount,
      addItem,
      close,
      title,
      toog,
      toogle,
      onSubmit,
      deleteOffice,
      dataItem,
      count,
      eError,
      courses,
      id,
      sub,
      validCourse,
      cError,
      toogle2,
      counter1,
      counter2,
      btnToogle,
    }
  },
}
</script>
