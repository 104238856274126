<template>
  <form
    id="f-admin-login"
    class="TileBox InputForm"
    role="form"
    @submit.prevent="submit"
  >
    <h4 class="title -c">Вход в панель администратора</h4>
    <div class="AlertList" v-if="massage.value">
      <p class="alert -w">{{ massage.value }}</p>
    </div>
    <div class="_grid -groove">
      <div class="_grid-cell">
        <input
          type="text"
          class="TextInput"
          id="f-admin-login-login"
          name="f-admin-login"
          v-model="login"
          placeholder="Логин"
          autofocus=""
        />
      </div>
    </div>
    <div class="_grid -groove">
      <div class="_grid-cell">
        <input
          type="password"
          class="TextInput"
          id="f-admin-login-password"
          name="f-admin-login-password"
          v-model="password"
          placeholder="Пароль"
        />
      </div>
    </div>
    <div class="_grid -groove">
      <div class="_grid-cell -c">
        <button type="submit" class="PushBtn -normal -raised -l">Войти</button>
      </div>
    </div>
  </form>
</template>
<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
export default {
  setup() {
    const login = ref()
    const password = ref()
    const store = useStore()

    const massage = computed(() => store.state.message)

    async function submit() {
      await store.dispatch('login', {
        email: login.value,
        password: password.value,
      })
    }

    return { login, password, submit, massage }
  },
}
</script>
