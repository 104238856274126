<template>
  <tr>
    <td>№</td>
    <td>Учебный год</td>
    <td>ФИО ребенка</td>
    <td>Дата рождения</td>
    <td>ФИО представителя</td>
    <td>Контактный телефон</td>
    <td>Дата заявки</td>
    <td>Тип</td>
    <td>Выбор расписания</td>
  </tr>
  <tr
    v-for="(item, i) in request.sort(function (a, b) {
      return a.data.appldate - b.data.appldate
    })"
    :key="item.id"
  >
    <td>{{ i + 1 }}</td>
    <td>{{ item.data.year }}</td>
    <td>{{ item.data.name }}</td>
    <td>{{ dataFormat(item.data.birthdate) }}</td>
    <td>{{ item.data.agent }}</td>
    <td>{{ item.data.phone }}</td>
    <td>{{ dataFormat(item.data.appldate) }}</td>
    <td>
      <template v-for="(i, n) in requestType(item.data.type)" :key="i">
        {{ i }}
        <template v-if="requestType(item.data.type).length !== n + 1">
          +
        </template>
      </template>
    </td>
    <td><span v-if="item.data.status == 1">✓</span><span v-else>✖</span></td>
  </tr>
</template>
<script>
import { dataFormat } from '../../../utils/dateformat'
import { requestType } from '../../../utils/requestType'
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  setup() {
    const store = useStore()
    const request = computed(() => store.getters['requests'])

    return { request, dataFormat, requestType }
  },
}
</script>