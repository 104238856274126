<template>
  <ul class="filter-request">
    <li class="admin__box-td-9 td9-2">
      <span>Учебный год:</span>
      <input
        v-mask="'####'"
        type="text"
        class="filter-input"
        v-model.lazy="year"
        placeholder="Учебный год"
      />
    </li>
    <li class="admin__box-td-9 td9-3">
      <span>По ФИО ребенка:</span>
      <input
        type="text"
        class="filter-input"
        id="f-name"
        name="f[name]"
        v-model.lazy="name"
        placeholder="ФИО"
      />
    </li>
    <li class="admin__box-td-9 td9-4">
      <span>По году рождения:</span>
      <input
        v-mask="['##.##.####']"
        class="filter-input"
        v-model.lazy="yareFilter"
        placeholder="01.01.2013"
      />
    </li>
    <li class="admin__box-td-9 td9-5">
      <span>По ФИО представителя:</span>
      <input
        type="text"
        class="filter-input"
        v-model.lazy="agent"
        placeholder="ФИО"
      />
    </li>
    <li class="admin__box-td-9 td9-6">
      <span>По контактному телефону:</span>
      <input
        v-mask="['###########']"
        type="text"
        class="filter-input"
        v-model.lazy="phone"
        placeholder="89170000000"
      />
    </li>
    <li class="admin__box-td-9 td9-7">
      <span>По дате заявки:</span>
      <input
        type="date"
        class="filter-input"
        v-model.lazy="applicationmin"
        placeholder="C даты"
      />
      <input
        type="date"
        class="filter-input"
        v-model.lazy="applicationmax"
        placeholder="По дату"
      />
    </li>
    <li class="admin__box-td-9 td9-8">
      <span>По названию курса:</span>
      <select
        class="filter-input"
        placeholder="Статус заявки"
        v-model.lazy="type"
      >
        <option value="-1">Любой</option>
        <option v-for="item in type2" :key="item" :value="item">
          {{ item }}
        </option>
      </select>
    </li>
    <li class="admin__box-td-9 td9-9">
      <span>По статусу заявки:</span>
      <select
        class="filter-input"
        placeholder="Статус заявки"
        v-model.lazy="state"
      >
        <option value="-1">Любой</option>
        <option value="0">Выбор расписания НЕ доступен</option>
        <option value="1">Выбор расписания доступен</option>
      </select>
    </li>
    <li class="print__block">
      <span><img src="../../../assets/img/print-svgrepo-com.svg" /></span
      ><router-link to="/printrequest">Печать</router-link>
    </li>
  </ul>
</template>
<script>
import { useStore } from 'vuex'
import { mask } from 'vue-the-mask'
import { ref, watch, computed } from 'vue'
export default {
  emits: ['update:modelValue'],
  props: ['modelValue'],
  directives: { mask },
  setup(_, { emit }) {
    const store = useStore()
    const yearNow = new Date().getFullYear()
    const year = ref(yearNow)
    const agent = ref()
    const name = ref()
    const phone = ref()
    const yareFilter = ref()
    const applicationmin = ref()
    const applicationmax = ref()
    const state = ref()
    const type = ref()

    const type2 = computed(() =>
      Array.from(
        new Set(
          [].concat.apply(
            [],
            Object.keys(store.state.dataCourses).map((r) =>
              store.state.dataCourses[r].data.map((i) => i.title)
            )
          )
        )
      )
    )

    watch(year, (value) => {
      store.dispatch('Request', value)
      store.dispatch('getStatus', value)
    })

    watch(
      [
        agent,
        name,
        phone,
        yareFilter,
        applicationmin,
        applicationmax,
        state,
        type,
      ],
      (values) => {
        emit('update:modelValue', {
          agent: values[0],
          name: values[1],
          phone: values[2],
          yareFilter: values[3],
          applicationmin: values[4],
          applicationmax: values[5],
          state: values[6],
          type: values[7],
        })
      }
    )

    return {
      type2,
      year,
      yearNow,
      agent,
      name,
      phone,
      yareFilter,
      applicationmin,
      applicationmax,
      state,
      type,
    }
  },
}
</script>
