<template>
  <app-header-img
    :title="textEvent(item.mini)"
    :btn="false"
    :img="item.img"
    styleClass="sub"
  ></app-header-img>
  <div class="fixed__menu-courses">
    <p @click="popup = !popup" class="fixed__menu-courses-text">
      <span><img width="35" src="../../assets/img/menusvg.svg" /></span
      ><span>Наши направления</span>
    </p>
    <p class="fixed__menu-courses-text">
      <router-link
        class="ancor__active"
        :to="{ path: `/courses/${item.route}`, hash: '#signup' }"
        >Записатся на курс</router-link
      >
    </p>
  </div>

  <div class="course__box">
    <template v-if="patch === 'mini-sad'">
      <MiniSad />
    </template>
    <template v-else-if="patch ==='city-camp'">
      <CityCamp>
        <template v-if="item.faq">
          <p class="course__h3">Частые вопросы заботливых родителей.</p>
          <div
              class="course__acardion"
              v-for="(item, i) in item.faq"
              :key="item"
          >
            <template v-if="arr.length > 0">
              <div
                  :class="arr[i].isOpen ? 'active' : null"
                  @click="toggle(i)"
                  class="course__acardion-title"
              >
                <span class="course__acardion-title-icon"></span
                ><span class="course__acardion-title-content">{{
                  item.title
                }}</span>
              </div>

              <p v-if="arr[i].isOpen" class="course__acardion-text">
                <template v-if="item.text">{{ item.text }}</template>
                <template v-if="item.text2">
                  <template v-for="item in item.text2" :key="item">
                    <span class="course__acardion-text-bold">{{
                        item.title
                      }}</span>
                    <span class="course__acardion-text-small">{{
                        item.text
                      }}</span>
                  </template>
                </template>
              </p>
            </template>
          </div>
        </template>
      </CityCamp>
    </template>
    <template v-else-if="patch ==='zanjatiya-s-shkolnikami'">
      <ZanjatiyaShkolnikami>
        <div class="galereja" v-if="fotoZSS.length !== 0">
          <template v-for="item in fotoZSS">
            <p v-if="imgEvent(item)" @click="showMultiple(item, 2)" :key="item">
              <img :src="require(`../../assets/img/foto/${item}`)" />
            </p>
          </template>
        </div>
        <h2 v-if="durationClassesZSS" class="_title">Продолжительность занятий:</h2>
        <ul v-if="durationClassesZSS" class="course__table">
          <li
              :class="durationClassesZSS.length == 1 ? 'one' : null"
              v-for="i in durationClassesZSS"
              :key="i"
          >
            <p>{{ i.text }}</p>
          </li>
        </ul>
      </ZanjatiyaShkolnikami>
    </template>
    <template v-else>
      <div class="course">
        <template v-if="item.subtitle">
          <p v-for="i in item.subtitle" :key="i" class="course__title">
            {{ i }}
          </p>
        </template>

        <ul class="course__list" v-if="item.list">
          <li v-for="i in item.list" :key="i">
            {{ i }}
          </li>
        </ul>
        <template v-if="item.list2">
          <p class="course__title">{{ item.list2.title }}</p>
          <ul class="course__list">
            <li v-for="n in item.list2.text" :key="n">
              {{ n }}
            </li>
          </ul>
        </template>

        <ul>
          <li v-for="i in item.skills" :key="i">
            <p class="course__subtitle">
              <span><img width="12" src="../../assets/img/check.svg" /></span
              >{{ i.title }}
            </p>
            <p class="course__text">
              {{ i.text }}
            </p>
          </li>
        </ul>

        <template v-if="item.subtext">
          <p class="course__title">{{ item.subtext }}</p>
        </template>

        <div class="galereja" v-if="foto.length !== 0">
          <template v-for="item in foto">
            <p v-if="imgEvent(item)" @click="showMultiple(item, 2)" :key="item">
              <img :src="require(`../../assets/img/foto/${item}`)" />
            </p>
          </template>
        </div>

        <template v-if="patch === 'english'">
          <div class="video-box">
            <video style="max-width: 300px; width: 100%" controls>
              <source src="../../assets/img/foto/video/video1.mp4" type="video/mp4" />
            </video>
          </div>
        </template>

        <template v-if="item.faq">
          <p class="course__h3">Часто задаваемые вопросы</p>
          <div
            class="course__acardion"
            v-for="(item, i) in item.faq"
            :key="item"
          >
            <template v-if="arr.length > 0">
              <div
                :class="arr[i].isOpen ? 'active' : null"
                @click="toggle(i)"
                class="course__acardion-title"
              >
                <span class="course__acardion-title-icon"></span
                ><span class="course__acardion-title-content">{{
                  item.title
                }}</span>
              </div>

              <p v-if="arr[i].isOpen" class="course__acardion-text">
                <template v-if="item.text">{{ item.text }}</template>
                <template v-if="item.text2">
                  <template v-for="item in item.text2" :key="item">
                    <span class="course__acardion-text-bold">{{
                      item.title
                    }}</span>
                    <span class="course__acardion-text-small">{{
                      item.text
                    }}</span>
                  </template>
                </template>
              </p>
            </template>
          </div>
        </template>

        <template v-if="item.list5">
          <p class="course__title">{{ item.list5.title }}</p>
          <ul class="course__list">
            <li v-for="title in item.list5.list" :key="title">{{ title }}</li>
          </ul>
          <p class="course__title" v-for="text in item.list5.text" :key="text">
            {{ text }}
          </p>
        </template>

        <h2 v-if="item.duration" class="_title">Продолжительность занятий:</h2>
        <ul v-if="item.duration" class="course__table">
          <li
            :class="item.duration.length == 1 ? 'one' : null"
            v-for="i in item.duration"
            :key="i"
          >
            <p>{{ i.title }}</p>
            <p>{{ i.text }}</p>
          </li>
        </ul>
        <h3 v-if="item.results" class="_title">Наши результаты.</h3>
        <ul v-if="item.results" class="course__table-3">
          <li v-for="i in item.results" :key="i">
            <p>{{ i.title }}</p>
            <p>{{ i.text }}</p>
          </li>
        </ul>

        <h3 v-if="item.advantages" class="_title">Наши программы</h3>
        <ul v-if="item.advantages" class="AboutRino__table circlebox">
          <li v-for="i in item.advantages" :key="i">
            <p class="AboutRino__table-img circle">
              <img :src="require(`../../assets/img/${i.images}`)" />
            </p>
            <p class="AboutRino__table-text item">
              {{ i.title }}
            </p>
          </li>
        </ul>
        <!--<h3 class="_title">Прейскурант</h3>
        <div class="price__block">
          <a class="btn__price" @click="showMultiple(item.price, 1)"
            ><span
              ><img width="25" src="../../assets/img/iconjendela.png" /></span
            >Прайс на {{ yare }} год</a
          >
        </div>-->
      </div>
    </template>
    <div
      :id="r.scrol > r.heightBox ? 'stop' : null"
      :class="r.scrol > 325 && r.scrol < r.heightBox ? 'active' : null"
      class="course__menu"
    >
      <ul>
        <li
          :class="patch == item.rout ? 'active' : null"
          v-for="item in menu"
          :key="item"
        >
          <router-link :to="`/courses/${item.rout}`">
            {{ item.mini ? textEvent(item.mini) : item.title }}</router-link
          >
        </li>
      </ul>
      <router-link
        class="ancor__active"
        :to="{ path: `/courses/${item.route}`, hash: '#signup' }"
        >Записаться на курс</router-link
      >
    </div>
  </div>
  <app-pop-up v-if="popup" :list="menu" @CloseForm="onClose"></app-pop-up>
  <vue-easy-lightbox
    escDisabled
    moveDisabled
    :visible="visible"
    :imgs="imgs"
    :index="index"
    @hide="handleHide"
  ></vue-easy-lightbox>
</template>
<script>
import MiniSad from './MiniSad.vue'
import ZanjatiyaShkolnikami from './ZanjatiyaShkolnikami.vue'
import VueEasyLightbox from 'vue-easy-lightbox'
import AppPopUp from '../ui/AppPopUp.vue'
import AppHeaderImg from '../ui/AppHeaderImg.vue'
import CityCamp from './CityCamp.vue'
import { computed, watch, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
export default {
  setup() {
    const store = useStore()
    onMounted(() => {
      store.dispatch('getRoute')
    })

    const fotoZSS = ref([
      'zanjatiya-s-shkolnikami/001.jpg',
      'zanjatiya-s-shkolnikami/002.jpg',
      'zanjatiya-s-shkolnikami/003.jpg',
      'zanjatiya-s-shkolnikami/004.jpg',
      'zanjatiya-s-shkolnikami/005.jpg',
      'zanjatiya-s-shkolnikami/006.jpg',
      'zanjatiya-s-shkolnikami/007.jpg',
      'zanjatiya-s-shkolnikami/008.jpg',
    ])

    function imgEvent(img) {
      if (img === 'mogusam/005.jpg') {
        return false
      } else if (img === 'mogusam/003.jpg') {
        return false
      } else if (img === 'podgotovka-k-schkole/003.jpg') {
        return false
      } else {
        return true
      }
    }

    function textEvent(event) {
      if (event === '"Школа внимания" для младших школьников') {
        return 'Занятия для учеников 1-4 класс'
      } else {
        return event
      }
    }

    const durationClassesZSS = computed(()=> {
      if(patch.value == 'zanjatiya-s-shkolnikami'){
         return [
           {
             "text": "90 минут 1 раз в неделю",
             "title": ""
           },
           {
             "text": "60 минут 2 раза в неделю",
             "title": ""
           }
         ]
      } else {
        return []
      }
    })

    const visible = ref(false)
    const imgs = ref('')
    const index = ref(0)

    function showMultiple(e, count) {
      if (count == 1) {
        imgs.value = [{ src: require(`../../assets/img/price/${e}`) }]
      } else {
        imgs.value = [{ src: require(`../../assets/img/foto/${e}`) }]
      }

      index.value = 0
      show()
    }
    function show() {
      visible.value = true
    }

    function handleHide() {
      visible.value = false
    }

    const popup = ref(false)
    const yare = new Date().getFullYear()

    const route = useRoute()

    const patch = computed(() => route.params.id)

    const data = computed(() =>
      Object.keys(store.state.routes).map((r) => store.state.routes[r])
    )

    const menu = computed(() => {
      return data.value.map((r) => {
        return { title: r.title, rout: r.route, mini: r.mini }
      })
    })
    const r = ref(0)
    const SignupForm = document.getElementById('signup')
    if(SignupForm){
      window.addEventListener(
          'scroll',
          () =>
              (r.value = {
                scrol: window.scrollY,
                heightBox: SignupForm.offsetTop - 450,
              })
      )
    }


    const dataa = computed(() => store.state.routes)

    const item = computed(() => {
      if (data.value.filter((r) => r.route == patch.value).length > 0) {
        return data.value.filter((r) => r.route == patch.value)[0]
      } else {
        return []
      }
    })

    const keytransform = computed(() => {
      if (patch.value == 'podgotovka-k-schkole') {
        return 'podgotovkakschkole'
      } else if (patch.value == 'zanjatiya-s-shkolnikami') {
        return 'zanjatiyasshkolnikami'
      } else {
        return patch.value
      }
    })

    const foto = computed(() => {
      if (store.state.foto[`${keytransform.value}`] !== undefined) {
        return store.state.foto[`${keytransform.value}`]
      } else {
        return []
      }
    })

    const arr = ref([])

    watch(item, (val) => {
      if (Object.keys(val).length > 0) {
        if (item.value.faq) {
          let b = item.value.faq.map((_, i) => {
            return { id: i, isOpen: false }
          })
          arr.value = b
        }
      } else {
        return (arr.value = [])
      }
    })

    function toggle(id) {
      arr.value[id].isOpen = !arr.value[id].isOpen
    }

    function onClose() {
      popup.value = false
    }

    return {
      dataa,
      foto,
      handleHide,
      show,
      showMultiple,
      visible,
      imgs,
      index,
      popup,
      yare,
      patch,
      data,
      item,
      arr,
      toggle,
      menu,
      r,
      SignupForm,
      onClose,
      keytransform,
      textEvent,
      imgEvent,
      durationClassesZSS,
      fotoZSS
    }
  },
  components: { AppHeaderImg, AppPopUp, VueEasyLightbox, MiniSad, ZanjatiyaShkolnikami, CityCamp },
}
</script>
<style scoped>
.video-box{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #e4e4e4;
}
@media screen and (max-width: 880px) {
  .PageTitle.sub {
    padding-top: 70px;
  }
}
</style>
